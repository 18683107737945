@charset "UTF-8";

/* Vars ================================================================================= */
$wh: #fff;
$bl: #1d1d1b;
$gold: #c39d62;
$grey: #929292;
$bgGrey: #f6f6f6;
$midGrey : #545454;
$darkGrey: #313131;
$blackGrey: #292929;
$red: #ec0027;
$lightTxt: #8a8a8a;
$buttonGrey: #4b4b4d;

@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);

@import "helpers/normalize"; // Reset-CSS
@import "helpers/variables"; // Bootstrap Vars
@import "helpers/mixins"; // All mixins

//@import "components/fonts"; //  Base Fonts

@import "components/sprite";

@import "components/theme/buttons";
@import "components/theme/hover";
@import "components/theme/text-animation";
@import "components/theme/animate";

@import "components/theme/nav";
@import "components/theme/sliders";
@import "components/theme/footer";
@import "components/theme/table";
@import "components/theme/form";
@import "components/theme/main";
@import "components/theme/scroll";

@import "components/blocs/interventions";
@import "components/blocs/team";
@import "components/blocs/cross";
@import "components/blocs/contact";
@import "components/blocs/glossary";
@import "components/blocs/faq";
@import "components/blocs/news";
@import "components/blocs/sitemap";
@import "components/blocs/rdv";




//@import "helpers/ie"; // Internet explorer