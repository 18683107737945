/*!
MAIN STYLE
*/

html {
	//background: #fff;
	font-family: 'Open Sans',arial;
	font-size: 14px;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	font-weight: 300;
}

body {
	background: #fff;
	font-family: 'Open Sans',arial;
	font-size: 14px;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	font-weight: 300;
	box-shadow: none;
	color:$grey;
	&::after {
		position: fixed;
		content: '';
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		visibility: v;
		opacity: 0;
		-webkit-transition: opacity .3s 0s, visibility 0s .3s;
		-moz-transition: opacity .3s 0s, visibility 0s .3s;
		transition: opacity .3s 0s, visibility 0s .3s;
		background: rgba(71, 55, 78, 0.8);
	}
}

a {
	color: $gold;
	text-decoration: none;
	-moz-transition: all .2s;
	-webkit-transition: all .2s;
	transition: all .2s;
	&:hover, &:focus {
		color: $gold;
		text-decoration: none;
		-moz-transition: all .2s;
		-webkit-transition: all .2s;
		transition: all .2s;
	}
	&:hover {
		color: $gold;
	}
	&.cta-button {
		
		background:$buttonGrey;
		color:$wh;
		text-transform: uppercase;
		font-family: 'Open Sans',arial !important;
		font-size:14px;
		line-height:18px;
		font-weight:700 !important;
		clear:both;
		float:none;
		display:inline-block;
		border-radius: 50vh;
		&:before {
			content:"" !important;
		}
	}
}

.bg-primary {
	background: $bgGrey !important;
}

img {
	max-width: 100%;
	height: auto;
	transition: opacity .3s ease-in;
}

p {
	line-height: 21px;
	font-size: 15px;
	color: $lightTxt;
	font-weight:300;
}

i {
	font-style: normal;
}

.text-light .title {
	color: #fff;
}

.section-dark p, .text-light p {
	color: rgba(255, 255, 255, 0.6);
}

:focus, a:focus {
	border-color: transparent;
	outline: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.animated {
	visibility: hidden;
	opacity: 0;
	&.visible {
		visibility: visible;
		opacity: 1;
	}
}

pre {
	padding: 0;
	background: none;
	border: none;
	border-radius: 0;
}

figure {
	position: relative;
}

h1, h2, h3, h4, h5 {
	font-weight: 700;
}

section {
	position: relative;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	color: $midGrey;
	&.wh {
		color:$wh;
	}
}

.bg-primary {
	h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
		color: #fff;
	}
}

h1 {
	font-size: 3.1rem;
}

h2 {
	font-size: 2.2rem;
}

h3 {
	font-size: 30px;
	line-height:36px;
}

h4 {
	font-size: 1.3rem;
	line-height: 1.6;
}

h5 {
	font-size: 0.9rem;
}

h6 {
	font-size: 1rem;
}

h1.big-title {
	font-size: 4.5rem;
}

/****  Margin Top  ****/
.m-t-0 {margin-top:0px !important;}
.m-t-5 {margin-top:5px !important; }
.m-t-10 {margin-top:10px !important;}
.m-t-15 {margin-top:15px !important;}
.m-t-20 {margin-top:20px !important;}
.m-t-30 {margin-top:30px !important;}
.m-t-40 {margin-top:40px !important;}
.m-t-50 {margin-top:40px !important;}
.m-t-60 {margin-top:60px !important;}
.m-t-70 {margin-top:70px !important;}
.m-t-80 {margin-top:80px !important;}
.m-t-100 {margin-top:100px !important;}
.m-t-120 {margin-top:120px !important;}
.m-t-150 {margin-top:150px !important;}
.m-t--10 {margin-top:-10px !important;}

/****  Margin Bottom  ****/
.m-b-0 {margin-bottom:0px !important;}
.m-b-5 {margin-bottom:5px !important;}
.m-b-10 {margin-bottom:10px !important;}
.m-b-15 {margin-bottom:15px !important;}
.m-b-20 {margin-bottom:20px !important;}
.m-b-30 {margin-bottom:30px !important;}
.m-b-40 {margin-bottom:40px !important;}
.m-b-50 {margin-bottom:50px !important;}
.m-b-60 {margin-bottom:60px !important;}
.m-b-70 {margin-bottom:70px !important;}
.m-b-80 {margin-bottom:80px !important;}
.m-b-100{margin-bottom:100px !important;}

/****  Margin Left  ****/
.m-l-0 {margin-left:0 !important;}
.m-l-5 {margin-left:5px !important;}
.m-l-10 {margin-left:10px !important;}
.m-l-20 {margin-left:20px !important;}
.m-l-30 {margin-left:30px !important;}
.m-l-40 {margin-left:40px !important;}
.m-l-60 {margin-left:60px !important;}
.m-l-80 {margin-left:80px !important;}
.m-l--10 {margin-left:-10px !important;}

/****  Margin Right  ****/
.m-r-0 {margin-right:0 !important;}
.m-r-5 {margin-right:5px !important;}
.m-r-10 {margin-right:10px !important;}
.m-r-20 {margin-right:20px !important;}
.m-r-30 {margin-right:30px !important;}
.m-r-40 {margin-right:40px !important;}
.m-r-60 {margin-right:60px !important;}
.m-r-80 {margin-right:80px !important;}

/****  Padding  ****/
.p-0 {padding:0 !important;}
.p-5 {padding:5px !important;}
.p-10 {padding:10px !important;}
.p-15 {padding:15px !important;}
.p-20 {padding:20px !important;}
.p-30 {padding:30px !important;}
.p-40 {padding:40px !important;}
.p-60 {padding:60px !important;}
.p-100 {padding:100px !important;}

/****  Padding Top  ****/
.p-t-0 {padding-top:0 !important;}
.p-t-10 {padding-top:10px !important;}
.p-t-15 {padding-top:15px !important;}
.p-t-20 {padding-top:20px !important;}
.p-t-30 {padding-top:30px !important;}
.p-t-40 {padding-top:40px !important;}
.p-t-60 {padding-top:60px !important;}
.p-t-80 {padding-top:80px !important;}
.p-t-100 {padding-top:100px !important;}
.p-t-120 {padding-top:120px !important;}

/****  Padding Bottom  ****/
.p-b-0 {padding-bottom:0 !important;}
.p-b-10 {padding-bottom:10px !important;}
.p-b-20 {padding-bottom:20px !important;}
.p-b-30 {padding-bottom:30px !important;}
.p-b-40 {padding-bottom:40px !important;}
.p-b-60 {padding-bottom:60px !important;}
.p-b-80 {padding-bottom:80px !important;}
.p-b-100 {padding-bottom:100px !important;}
.p-b-120 {padding-bottom:120px !important;}

/****  Padding Left  ****/
.p-l-0 {padding-left:0 !important;}
.p-l-5 {padding-left:5px !important;}
.p-l-10 {padding-left:10px !important;}
.p-l-15 {padding-left:15px !important;}
.p-l-20 {padding-left:20px !important;}
.p-l-30 {padding-left:30px !important;}
.p-l-40 {padding-left:40px !important;}
.p-l-60 {padding-left:60px !important;}
.p-l-100 {padding-left:100px !important;}

/* Padding Right  ****/
.p-r-0 {padding-right:0 !important;}
.p-r-5 {padding-right:5px !important;}
.p-r-10 {padding-right:10px !important;}
.p-r-15 {padding-right:15px !important;}
.p-r-20 {padding-right:20px !important;}
.p-r-30 {padding-right:30px !important;}
.p-r-40 {padding-right:40px !important;}
.p-r-60 {padding-right:60px !important;}
.p-r-100 {padding-right:100px !important;}

#wrapper {
	background: #fff;
	position: relative;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

.sticky {
	-moz-transition: all .1s;
	-webkit-transition: all .1s;
	transition: all .1s;
}

.fullwidth {
	width: 100%;
}

code {
	color: $grey;
	background-color: rgba(0, 0, 0, 0.1);
}

.hljs {
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 2px;
}

.dis-inline .hljs {
	display: inline-block;
}

hr {
	margin: 2rem 0;
}

.bg-cover {
	-moz-background-size: cover;
	-o-background-size: cover;
}

.container-fullwidth {
	position: relative;
	width: 100%;
	padding: 0 60px;
}

.container-large {
	padding-left: 8rem;
	padding-right: 8rem;
}

.boxed .container-large {
	margin-left: 2.5rem;
	margin-right: 2.5rem;
}

.container-large {
	&::before, &::after {
		display: table;
		content: " ";
	}
}

.icon-img {
	margin: auto;
	max-width: 100%;
	height: auto;
}

.img-cover {
	background-position: center center;
	background-size:cover;
	&.alt {
		background-position: center 0;
	}
}

@media (max-width: 1600px) {
	.container-large {
		padding-right: 3rem;
		padding-left: 3rem;
	}
}

@media (max-width: 992px) {
	h1 {
		font-size: 2.3rem;
	}
	h2 {
		font-size: 1.5rem;
	}
	h3 {
		//font-size: 1.2rem;
		font-size:25px;
	}
	h1.big-title {
		font-size: 3.5rem;
	}
	.sticky[class*="col-lg-"] {
		margin-top: 0 !important;
	}
	.same-height.col-lg-3 {
		height: auto !important;
	}
}

@media (max-width: 768px) {
	.container-fullwidth {
		padding: 0 1rem;
	}
	.container-large {
		padding-left: 1rem;
		padding-right: 1rem;
	}
	.big-title {
		font-size: 2.5rem;
	}
}

/* SCROLTOP */

a.scrollup {
	background: rgba(0, 0, 0, 0.6);
	border: 2px solid rgba(255, 255, 255, 0.8);
	height: 40px;
	width: 40px;
	color: #fff;
	font-size: 16px;
	line-height: 40px;
	text-align: center;
	text-decoration: none;
	position: fixed;
	bottom: 10px;
	right: 10px;
	z-index: 9999;
	opacity: .5;
	-moz-border-radius: 50% !important;
	-webkit-border-radius: 50% !important;
	border-radius: 50% !important;
	i {
		display: inline-block;
		color: #fff !important;
		position: absolute;
		top: 10px;
		left: 10px;
		font-weight: 700;
	}
	&:hover {
		opacity: 1;
		color: #fff;
		-webkit-transition: all .2s ease-in;
		-moz-transition: all .2s ease-in;
		-o-transition: all .2s ease-in;
		transition: all .2s ease-in;
	}
}

html.page-bordered a.scrollup {
	right: 28px;
	bottom: 28px;
}

/* GALLERY IMAGES CMS */

div.gallery {
	.gallery-item {
		float:left;
		min-height: 1px;
	    padding-left: 0.9375rem;
	    padding-right: 0.9375rem;
	    position: relative;
	    background:$wh;
	    max-height:285px;
	    .wp-caption-text {
	    	display:none;
	    }
	}
	&.gallery-columns-1 {
		.gallery-item {
			width:100%;
		}
	}
	&.gallery-columns-2 {
		.gallery-item {
			width:50%;
		}
	}
	&.gallery-columns-3 {
		.gallery-item {
			width:33.3333%;
		}
	}
	&.gallery-columns-4 {
		.gallery-item {
			width:25%;
		}
	}
	&.gallery-columns-5 {
		.gallery-item {
			width:25%;
		}
	}
	&.gallery-columns-6 {
		.gallery-item {
			width:16.6667%;
		}
	}
	&.gallery-columns-7 {
		.gallery-item {
			width:16.6667%;
		}
	}
	&.gallery-columns-8 {
		.gallery-item {
			width:16.6667%;
		}
	}
	&.gallery-columns-9 {
		.gallery-item {
			width:16.6667%;
		}
	}
}

/* OVERLAY ON IMAGES / SECTIONS */

.section {
	position: relative;
	padding: 5rem 0;
	.title {
		padding-top: 0;
	}
}

.section-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	background: #000;
	opacity: 0.8;
  /*z-index:28;*/
  &.bg-primary, &.overlay-primary, &.overlay-orange {
  	background: $gold;
  }
  &.overlay-blue {
  	background: #1b5765;
  }
  &.overlay-white {
  	background: #fff;
  }
  &.overlay-purple {
  	background: #7986cb;
  }
  &.overlay-red {
  	background: #e06363;
  }
  &.overlay-3 {
  	opacity: 0.3;
  }
  &.overlay-9 {
  	opacity: 0.9;
  }
}

.overlay-hover {
	z-index: 2;
}

.height-full .section-overlay, #page-title .section-overlay {
	z-index: 0;
}

@media (max-width: 768px) {
	.section {
		padding: 4rem 1rem;
	}
}

/* SCROLL DOWN BUTTONS */

.scroll-down {
	cursor: pointer;
	width: 28px;
	position: absolute;
	bottom: 60px;
	display: block;
	height: 50px;
	webkit-border-radius: 13px;
	-moz-border-radius: 14px;
	border-radius: 14px;
	border: 2px solid rgba(255, 255, 255, 0.5);
	left: 50%;
	margin-left: -12px;
	z-index: 200;
	span {
		display: block;
		margin: 6px auto;
		width: 2px;
		height: 6px;
		border-radius: 4px;
		background: #fff;
		border: 2px solid transparent;
		-webkit-animation-duration: 1.6s;
		animation-duration: 1.6s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
		-webkit-animation-name: scroll;
		animation-name: scroll;
	}
}

@-webkit-keyframes scroll {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateY(25px);
		transform: translateY(25px);
	}
}


@keyframes scroll {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateY(25px);
		-ms-transform: translateY(25px);
		transform: translateY(25px);
	}
}


@media (max-width: 992px) {
	.scroll-down {
		bottom: 30px;
	}
}

/* ROW TABLE DISPLAY */

.row-table > [class*="col-"] {
	padding: 5rem;
}

@media (min-width: 992px) {
	.row-table {
		display: table;
		margin: 0;
		> [class*="col-"] {
			display: table-cell;
			float: none;
		}
	}
}


/* CUSTOM RADIOS / CHECKBOX */

.checkbox-custom, .radio-custom {
    opacity: 0;
    position: absolute;   
}

.checkbox-custom, .checkbox-custom-label, .radio-custom, .radio-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
}

.checkbox-custom-label, .radio-custom-label {
    position: relative;
	font-size:15px;
	font-weight:500;
	color:$midGrey;
	padding:0;
	-webkit-touch-callout: none; /* iOS Safari */
	  -webkit-user-select: none;   /* Chrome/Safari/Opera */
	  -khtml-user-select: none;    /* Konqueror */
	  -moz-user-select: none;      /* Firefox */
	  -ms-user-select: none;       /* Internet Explorer/Edge */
	  user-select: none;           /* Non-prefixed version, currently */
	  outline: 0;
}

.checkbox-custom + .checkbox-custom-label:before {
    content: '';
    background: $gold;
    border: none;
    display: inline-block;
    vertical-align: middle;
    width: 26px;
    height: 26px;
    line-height:26px;
    margin-right: 14px;
    text-align: center;
    float: left;
    border-radius:5px;
}
.radio-custom + .radio-custom-label:before {
    content: "\f10c";
    font-family: 'FontAwesome';
    display: inline-block;
    margin-right: 10px;
    font-size:18px;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
    content: "\f00c";
    font-family: 'FontAwesome';
    background: $gold;
    color: #fff;
}

.radio-custom + .radio-custom-label:before {
    border-radius: 50%;
}

.radio-custom:checked + .radio-custom-label:before {
    content: "\f192";
    font-family: 'FontAwesome';
    font-size:18px;
    color: $midGrey;
}





/* HEADERS */

header:not(#header) {
	overflow: hidden;
	position: relative;
}

.section-header {
	padding: 4.5rem 0;
}

.header-sm, .header-md, .header-lg {
	position: relative;
	overflow: hidden;
}

.header-sm {
	padding: 3.5rem 0;
}

.header-md {
	padding: 6rem 0;
}

.header-lg {
	padding: 11rem 0;
	background-size: 100%;
	overflow: hidden;
}

.header-hg {
	padding: 19rem 0;
	background-size: 100%;
	overflow: hidden;
}

.header-map {
	padding: 0;
	height: 520px;
	background-size: 100%;
	overflow: hidden;
}

.header-icon {
	font-size: 3.8rem;
	margin-bottom: 1rem;
	display: inline-block;
	text-align: center;
	color: $gold;
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
}

.height-full {
	min-height: 500px;
	.video-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}
}

.top-element {
	text-align: center;
	display: table;
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 200;
}

.top-text {
	display: table-cell;
	height: 100%;
	vertical-align: middle;
}

.header-title {
	position: relative;
	margin-bottom: 2rem;
	color: #fff;
	font-weight: 700;
	letter-spacing: 0.5rem;
	margin-top: 0;
}

h1.header-title {
	font-size: 5rem;
}

h2.header-title {
	font-size: 3rem;
}

.subtitle {
	font-size: 17px;
	color: $gold;
	margin: 1rem 0;
	font-weight: 300;
	font-style:italic;
}

.section-dark .subtitle {
	color: rgba(255, 255, 255, 0.7);
}

.subtitle span {
	font-weight: normal;
	color: rgba(255, 255, 255, 0.5);
}

.t-dark .subtitle {
	color: rgba(0, 0, 0, 0.8);
	span {
		color: rgba(0, 0, 0, 0.3);
	}
}

.intro-message {
	font-size: 16px;
	line-height: 1.4;
	margin-bottom: 48px;
	margin-top: 30px;
	color: rgba(255, 255, 255, 0.7);
	letter-spacing: 3px;
}

.text-dark .intro-message {
	color: rgba(0, 0, 0, 0.7);
}

.top-element h2 {
	font-weight: 200;
}

.section-header.header-dark {
	color: #fff;
}

@media (max-width: 992px) {
	h1.header-title {
		font-size: 3rem;
	}
	h2.header-title {
		font-size: 2rem;
	}
}

@media (max-width: 768px) {
	.header-lg {
		padding: 0;
		height: 350px;
	}
	h1.header-title {
		font-size: 2.2rem;
	}
	h2.header-title {
		font-size: 1.5rem;
	}
	.subtitle {
		font-size: 1.3rem;
	}
}

/* Breadcrumb */
.breadcrumb-section {
	width:100%;
	border-bottom: solid 1px #e3e3e3;
	padding:22px 0;
}
.breadcrumb {
	display: table-cell;
	vertical-align: middle;
	text-align: right;
	background: transparent;
	padding: 0;
	font-size: 0.9rem;
	text-transform: uppercase !important;
	span {
		color: #515151;
	}
	a {
		color: #6D6D6D;
		font-weight: 600;
		-webkit-transition: all .2s;
		-moz-transition: all .2s;
		-o-transition: all .2s;
		transition: all .2s;
		&:hover {
			color: $gold;
		}
		&:after {
			content: "•";
			display: inline-block;
			padding-right: 8px;
			padding-left: 12px;
			color: rgba(0, 0, 0, 0.5);
		}
	}
}

.breadcrumb-list li {
	display: inline-block;
	a {
		color: rgba(255, 255, 255, 0.9);
		font-size: .9rem;
		margin: 1rem;
		padding-bottom: .2rem;
		letter-spacing: 1px;
		font-weight: 700;
		text-transform: uppercase;
		border-bottom: 2px solid rgba(255, 255, 255, 0.2);
		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
		&:hover {
			color: #fff;
			border-bottom: 2px solid rgba(255, 255, 255, 0);
		}
	}
}

.header-light .breadcrumb-list li a {
	color: rgba(0, 0, 0, 0.9);
	border-bottom: 2px solid rgba(0, 0, 0, 0.2);
	&:hover {
		color: #000;
		border-bottom: 2px solid rgba(0, 0, 0, 0);
	}
}

.breadcrumb > li + li:before {
	display: none;
}

.breadcrumb-lg {
	padding-left: 0;
	a {
		font-size: 1rem;
		font-weight: 700;
		color: rgba(0, 0, 0, 0.3);
		text-transform: uppercase;
		letter-spacing: 1px;
		&:after {
			color: rgba(0, 0, 0, 0.15);
			font-weight: 900;
		}
	}
	li:last-child a::after {
		display: none;
	}
}

@media (max-width: 768px) {
	.breadcrumb {
		display: block;
		text-align: center;
		margin-bottom: 0;
	}
}

/* Categories List: faq, etc */

.categories-list {
	padding-left: 0;
	margin-bottom: 3rem;
	text-align: center;
	li {
		display: inline-block;
		a {
			color: rgba(0, 0, 0, 0.9);
			font-size: 0.95rem;
			margin: 1rem;
			padding-bottom: .2rem;
			letter-spacing: 1px;
			font-weight: 700;
			text-transform: uppercase;
			border-bottom: 2px solid rgba(0, 0, 0, 0.1);
			-webkit-transition: all .3s ease-in-out;
			-moz-transition: all .3s ease-in-out;
			transition: all .3s ease-in-out;
			&:hover, &.current {
				color: $gold;
				border-bottom: 2px solid rgba(0, 0, 0, 0);
			}
		}
	}
}

.header-dark .categories-list li a {
	color: rgba(255, 255, 255, 0.9);
	border-bottom: 2px solid rgba(255, 255, 255, 0.2);
	&:hover {
		color: $gold;
		border-bottom: 2px solid rgba(255, 255, 255, 0);
	}
}

/* PAGE TITLE */

/* General */

#page-title {
	background-color: #F5F5F5;
	position: relative;
	padding: 45px 0;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	overflow: hidden;
  /*border-top: 1px solid rgba(0,0,0,0.1);*/
  > .container {
  	padding: 0;
  }
  .page-title-wrapper {
  	display: table;
  	height: 100%;
  	width: 100%;
  }
}

.page-title-txt {
	display: table-cell;
	vertical-align: middle;
	margin-bottom: 3rem;
}

.container-fluid .breadcrumb {
	padding-right: 10px;
}

.container .page-title-wrapper {
	padding: 0 15px;
}

#page-title {
	h1, h2 {
		text-transform: uppercase;
		font-weight: 600;
		margin: 0;
		letter-spacing: 1px;
	}
	p {
		margin-bottom: 0;
		margin-top: .8rem;
		font-size: 1.3rem;
		font-weight: 300;
		&.subtitle {
			font-size: 1.5rem;
		}
	}
}

/* Size */

.page-title-txt h1 {
	font-size: 2.3rem;
	&.header-title {
		position: relative;
		font-size: 5rem;
		text-transform: none;
		margin-bottom: 2.1rem;
		color: #fff;
		font-weight: 700;
		letter-spacing: 0.5rem;
	}
}

#page-title {
	.container.ov {
		overflow:visible !important;
	}
	&.wimage {
		background-size:cover;
		background-repeat:no-repeat;
		background-position:center;
		&.page-title-lg {
			padding:150px 0 55px 0;
			min-height:284px;
			h1 {
				font-size:44px;
				line-height:48px;
				color:$wh;
				font-weight:300;
				text-transform: none;
				text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
			}
		}
	}
	&.page-title-xs {
		padding: 1rem 0;
		h1 {
			font-size: 1.4rem;
			font-weight: 700;
		}
	}
	&.page-title-sm {
		padding: 2rem 0;
		h1 {
			font-size: 2rem;
		}
	}
	&.page-title-lg {
		padding:150px 0 55px 0;
		h1 {
			font-size:44px;
			line-height:48px;
			color:$midGrey;
			font-weight:300;
			text-transform: none;
		}
	}
	&.page-title-hg {
		padding: 16rem 0;
	}
}

.page-title-hg h1 {
	font-size: 3.2rem;
}

/* CMS CONTENT */
section.section {
	&.odd {
		background:$wh;
	}
	&.even {
		background:$bgGrey;
	}
	&.dark {
		background:#3b3b3b !important;
	}
}
.cms-type {
	text-align:center;
	&.cms-align-left {
		text-align:left;
	}
	h3 {
		font-size: 18px;
		font-weight:700;
		line-height:24px;
		margin:0 0 30px 0;
		text-transform: uppercase;
		sup {
			text-transform: lowercase;
		}
	}
	h4 {
		font-size:17px;
		font-style:italic;
		color:$lightTxt;
		margin-bottom:40px;
		font-weight:400;
	}
	blockquote, blockquote p {
		margin:20px 0;
		font-size:25px;
		font-weight:600;
		line-height:30px;
		color:$midGrey;
		p {
			margin:0;
		}
	}
	p {
		margin-bottom:22px;
		strong {
			color:$midGrey;
		}
	}
	.gallery {
		clear:both;
		float:none;
		overflow:hidden;
		margin:45px 0;
	}
	a {
		font-weight:500;
		&:hover {
			text-decoration: underline;
		}
	}
	ul {
		text-align:left;
		list-style: none;
		padding:0;
		margin:20px 0 50px 0;
		li {
			padding-left:14px;
			position:relative;
			margin-bottom:24px;
			display:block;
		    font-size: 15px;
		    font-weight: 300;
		    line-height: 21px;
			&:before {
				content: "\2022";
				font-size:36px;
				color:$midGrey;
				position:absolute;
				top:0;
				left:-14px;
			}	
		}
	}
	img {
		max-width:100%;
		&.alignright {
			float:right;
			margin:32px;
			margin-top:0;
		}
		&.alignleft {
			float:left;
			margin:32px;
			margin-top:0;
		}
	}
	&.smaller-text {
		ul {
			li {
				&:before {
					left:0;
				}
				font-size:14px;
				padding-left: 26px;
				margin-bottom:10px;
			}
		}
	}
}

/* Alignment */

.page-title-right {
	.page-title-txt {
		text-align: right;
	}
	.breadcrumb {
		text-align: left;
	}
}

.page-title-center {
	.page-title-txt {
		text-align: center;
		display: block;
	}
	.breadcrumb {
		text-align: center;
		display: block;
		margin-bottom: 0;
		margin-top: 1.5rem;
	}
}

#page-title {
	&.page-title-center {
		h1, h2 {
			margin-top: 1rem;
		}
	}
	&.page-title-dark {
		background-color: #303030;
		h1 {
			color: #fff;
		}
		p {
			color: #D4D4D4;
		}
		.breadcrumb {
			span {
				color: #B5B5B5;
			}
			a {
				color: #E7E7E7;
				&:hover {
					color: #fff;
				}
				&::after {
					content: "/";
					display: inline-block;
					padding-right: 8px;
					padding-left: 12px;
					color: rgba(255, 255, 255, 0.3);
				}
			}
		}
	}
	&.page-title-no-bg {
		background: none !important;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}
	&.bg-pattern {
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	}
	&.header-fading {
		overflow: hidden;
		padding: 0;
		margin-top: -30px;
		opacity: 0;
		-webkit-transition: all .6s ease-in;
		-moz-transition: all .6s ease-in;
		-o-transition: all .6s ease-in;
		transition: all .6s ease-in;
	}
	&.page-title-hg.header-fading.header-has-fade {
		padding: 16rem 0;
		height: auto;
		opacity: 1;
	}
}

/* Background */

@media (max-width: 992px) {
	.page-title-txt {
		h1 {
			font-size: 1.8rem !important;
			line-height: 1.6;
		}
		h2 {
			font-size: 1.4rem !important;
			line-height: 1.6;
		}
	}
	#page-title {
		&.page-title-lg {
			padding: 6rem 0;
		}
		&.page-title-hg {
			padding: 12rem 0;
		}
	}
}

@media (max-width: 768px) {
	.page-title-txt {
		h1 {
			font-size: 1.6rem !important;
		}
		display: block;
		text-align: center !important;
	}
	.page-title-right .breadcrumb {
		display: block;
		text-align: center !important;
	}
	.page-title-txt {
		margin-bottom: 1rem;
	}
	#page-title {
		&.page-title-lg {
			padding: 4rem 0;
		}
		&.page-title-hg {
			padding: 6rem 0;
		}
	}
}

@media (max-width: 540px) {
	#page-title {
		&.page-title-lg.video-bg, &.page-title-hg.video-bg {
			padding: 1rem 0;
		}
	}
}

/* Fading effect */

/* TITLES */

.title {
	position: relative;
	padding: 2rem 0 1rem 0;
	margin-bottom: 3rem;
	overflow: hidden;
	i {
		font-size: 2rem;
		display: block;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		margin-bottom: 1rem;
		color: #7B7B7B;
	}
	.icons-list i {
		display: inline-block;
	}
	i {
		&.video-icon {
			font-size: 3rem;
			background: rgba(255, 255, 255, 0.2);
			border-radius: 3px;
			padding: 0rem 1.2rem;
			display: inline-block;
			-webkit-transition: all .2s;
			-moz-transition: all .2s;
			transition: all .2s;
			&:hover {
				background: rgba(255, 255, 255, 1);
				color: #303030 !important;
			}
		}
		&.video-icon-dark {
			font-size: 3rem;
			background: rgba(0, 0, 0, 0.2);
			color: #E1E1E1 !important;
			border-radius: 3px;
			padding: 0rem 1.2rem;
			display: inline-block;
			-webkit-transition: all .2s;
			-moz-transition: all .2s;
			transition: all .2s;
			&:hover {
				background: rgba(0, 0, 0, 1);
				color: #fff !important;
			}
		}
	}
	h1, h2, h3, h4, h5 {
		letter-spacing: 1px;
		position: relative;
		font-weight: 700;
		text-transform: uppercase;
	}
	h1 span, h2 span, h3 span, h4 span, h5 span {
		color: $gold;
	}
	h1, h2, h3, h4, h5 {
		margin-top: 0;
		margin-bottom: 0;
	}
	p {
		margin-top: 0;
		margin-bottom: 0;
		font-size: 1.2rem;
		color: rgba(0, 0, 0, 0.7);
	}
	h1 {
		font-size:2.2rem;
	}
	&.wh {
		h3 {
			color:$wh;
		}
	}
}

.title-center {
	text-align: center;
}

.title-right {
	text-align: right;
}

.title {
	h1, h2, h3, h4, h5 {
		display: inline-block;
		position: relative;
		font-weight: 700;
	}
	> {
		span {
			display: inline-block;
			position: relative;
			font-weight: 700;
		}
		p {
			display: inline-block;
			position: relative;
			font-weight: 700;
			font-weight: normal;
			color: #323232;
		}
	}
	h1 ~ p, h2 ~ p, h3 ~ p, h4 ~ p, h5 ~ p {
		display: block;
	}
	p ~ {
		p, h1, h2, h3 {
			display: block;
		}
	}
	> a {
		display: block;
	}
	&.title-line {
		&.title-right h1:before, &.title-center h1:before, h1:after, &.title-right h2:before, &.title-center h2:before, h2:after, &.title-right h3:before, &.title-center h3:before, h3:after, &.title-right h4:before, &.title-center h4:before, h4:after, &.title-right h5:before, &.title-center h5:before, h5:after, &.title-right > span:before, &.title-center > span:before, > span:after, &.title-right > p:before, &.title-center > p:before, > p:after {
			content: "";
			position: absolute;
			height: 3px;
			border-bottom: 3px solid rgba(0, 0, 0, 0.04);
			width: 1200px;
		}
		h1 {
			&:before, &:after {
				top: 24px;
			}
		}
		h2 {
			&:before, &:after {
				top: 15px;
			}
		}
		h3 {
			&:before, &:after {
				top: 11px;
			}
		}
		h4 {
			&:before, &:after {
				top: 12px;
			}
		}
		h5 {
			&:before, &:after {
				top: 6px;
			}
		}
		> p {
			&:before, &:after {
				top: 14px;
			}
		}
	}
	h1:before, h2:before, h3:before, h4:before, h5:before {
		right: 100%;
		margin-right: 15px;
	}
	> {
		span:before, p:before {
			right: 100%;
			margin-right: 15px;
		}
	}
	h1:after, h2:after, h3:after, h4:after, h5:after {
		left: 100%;
		margin-left: 15px;
	}
	> {
		span:after, p:after {
			left: 100%;
			margin-left: 15px;
		}
	}
	&.title-line-bottom {
		padding-bottom: 14px;
		p {
			color: $gold;
			margin-top: 1rem;
			margin-bottom:0;
			font-style: italic;
			&.subtitle {
				margin-top: 0;
			}
		}
		&::after {
			content: "";
			position: absolute;
			left: 0%;
			height: 2px;
			bottom: 0px;
			border-bottom: 1px solid $midGrey;
			width: 35px;
		}
	}
	&.title-center.title-line-bottom::after {
		left: 50%;
		margin-left: -15px;
	}
	&.title-light.title-line-bottom::after {
		border-bottom: 2px solid #fff;
	}
	&.title-line-primary {
		padding-bottom: 0.5rem;
		p {
			color: $gold;
			margin-top: 1rem;
			margin-bottom: 0.5rem;
			font-style: italic;
			&.subtitle {
				margin-top: 0;
			}
		}
		&::after {
			content: "";
			position: absolute;
			left: 0%;
			height: 2px;
			bottom: 0px;
			border-bottom: 2px solid $gold;
			width: 30px;
			-webkit-transition: all .3s ease-in;
			-moz-transition: all .3s ease-in;
			-o-transition: all .3s ease-in;
			transition: all .3s ease-in;
		}
	}
	&.title-center.title-line-primary::after {
		left: 50%;
		margin-left: -15px;
	}
	&.title-light.title-line-primary::after {
		border-bottom: 2px solid #fff;
	}
}

section:hover .title {
	&.title-line-primary::after {
		width: 50px;
	}
	&.title-center.title-line-primary::after {
		margin-left: -25px;
		width: 50px;
	}
}

.title {
	&.title-light {
		color: #fff;
		p {
			color: #fff;
		}
	}
	&.title-line {
		&.title-right.title-light h1:before, &.title-center.title-light h1:before, &.title-light h1:after, &.title-right.title-light h2:before, &.title-center.title-light h2:before, &.title-light h2:after, &.title-right.title-light h3:before, &.title-center.title-light h3:before, &.title-light h3:after, &.title-right.title-light h4:before, &.title-center.title-light h4:before, &.title-light h4:after, &.title-right.title-light h5:before, &.title-center.title-light h5:before, &.title-light h5:after, &.title-right.title-light > span:before, &.title-center.title-light > span:before, &.title-light > span:after, &.title-center.title-light > p:before, &.title-light > p:after {
			border-bottom: 3px solid rgba(255, 255, 255, 0.04);
		}
	}
	.subtitle {
		margin-top: 0.6rem;
		color: $gold;
		font-weight:300;
		font-size: 17px;
		line-height: 22px;
		text-transform: none;
		margin-bottom: 0;
		font-style: italic;
		height: auto;
	}
}

.theme-amber section.amber .title .subtitle {
	color: rgba(0, 0, 0, 0.5);
}

.title {
	&.t-light {
		.subtitle {
			color: #D2D2D2;
		}
		&.title-line-bottom::after {
			border-bottom: 2px solid #fff;
		}
	}
	img {
		display: block;
		text-align: center;
		margin: 0 auto 0.5rem auto;
		max-width: 60px;
	}
	&.icon-right i {
		float: left;
		padding-right: 1rem;
		font-size: 3.2rem;
		padding-left: 0;
		margin-bottom: 0;
		padding-top: 0.5rem;
	}
	&.icon-right-big i {
		float: left;
		padding-right: 1.3rem;
		font-size: 3.8rem;
		padding-left: 0;
		margin-bottom: 0;
		padding-top: 0.8rem;
	}
}

.section-dark .title {
	&.title-line-bottom::after {
		border-bottom: 2px dashed #E0E0E0;
	}
	i {
		color: #C1C1C1;
	}
	> p {
		color: #D8D8D8;
	}
}

.text-element .title p {
	font-size: 1.4rem;
}

@media (max-width: 1200px) {
	.col-xl-6 ~ .col-xl-6 {
		padding-top: 4rem;
	}
}

@media (max-width: 992px) {
	.title {
		margin-bottom: 1.5rem;
	}
  /*
  .section>.container-fluid>.row:not(.posts)>[class*="col-lg"] ~ [class*="col-lg"], 
  .section>.container-large>.row:not(.posts)>[class*="col-lg"] ~ [class*="col-lg"], 
  .section>.container>.row:not(.posts)>[class*="col-lg"] ~ [class*="col-lg"], 
  .section>.row:not(.posts)>[class*="col-lg"] ~ [class*="col-lg"]  {padding-top: 4rem;}
  */
  .review_top_ornament_quotemark {
  	margin-bottom: 4rem;
  	line-height: 1rem;
  }
}

/* VIDEO */

.section-video {
	display: block;
	width: 100%;
	top: 0;
	left: 0;
	position: relative;
	height: 550px;
	.video-wrapper {
		position: absolute;
		display: block;
		width: 100%;
		top: 0;
		left: 0;
		position: absolute;
		~ div {
			position: absolute;
			top: 0;
			height: 100%;
			width: 100%;
			z-index: 20;
			display: table;
			> div {
				display: table-cell;
				vertical-align: middle;
				height: 100;
			}
		}
	}
}

.video-js {
	width: 101% !important;
	z-index: 10;
}

.audio-video-wrapper {
	position: relative;
}

.vjs-poster {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
}

.vjs-sublime-skin .vjs-big-play-button {
	bottom: 100px;
	&:before {
		content: "\e7fe";
		font-size: 60px;
		border: none;
	}
}

header {
	&.section-video {
		height: auto;
		&.section-lg .top-text, &.section-hg .top-text {
			display: block;
			margin-top: 8rem;
		}
		.audio-video-wrapper {
			position: absolute;
			top: 0;
			height: 100%;
			width: 100% !important;
		}
	}
	&.section-audio .audio-video-wrapper {
		position: absolute;
		top: 0;
		height: 100%;
		width: 100% !important;
	}
	&.section-video .video-js, &.section-audio .video-js {
		height: 100%;
	}
	&.section-video .vjs-fullscreen {
		padding-top: 0px;
	}
	.vjs-sublime-skin .vjs-big-play-button {
		display: none;
		&:before {
			content: "\ea49";
		}
	}
}

.video-bg {
	.container {
		z-index: 200;
		position: relative;
	}
	.video-wrapper {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		video {
			display: block;
			width: 100%;
			height: auto;
		}
	}
}

.video-overlay {
	&:before {
		content: "";
		background: rgba(0, 0, 0, 0.35);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
	}
	&.overlay-primary:before {
		background: rgba(255, 127, 0, 0.3);
	}
	&:after {
		content: "";
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACJJREFUeNpiZGBg8AVihv///28G0UwMaIARKIMigKECIMAASJsHAYcd2RAAAAAASUVORK5CYII=);
		background-size: 2px 2px;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 12;
	}
}

.parallax-zoom-out {
	background-position: 50% 0;
}

.vjs-sublime-skin {
	&.vjs-controls-disabled .vjs-big-play-button, &.vjs-has-started .vjs-big-play-button, &.vjs-using-native-controls .vjs-big-play-button {
		opacity: 1;
		pointer-events: auto;
	}
	&.vjs-playing.vjs-has-started .vjs-big-play-button:before {
		content: "\e7f8";
		cursor: pointer;
	}
}

.vjs-big-play-button {
	position: relative;
}

.poster {
	background-position: center center;
	background-size: cover;
	bottom: 0;
	left: 0;
	opacity: 1;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 10;
}

header {
	.vjs-sublime-skin .vjs-control-bar {
		right: 1rem;
	}
	&.section-video {
		.video-embed {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
		}
		.fluid-width-video-wrapper {
			height: 100%;
		}
	}
}

iframe {
	padding: 0;
	margin: 0;
	border: none;
	width: 100%;
}

.post .post-medias .video-js .vjs-tech {
	position: relative;
}

/* MAIN CONTAINER */

.header-transparent {
	#header ~ #main-content, &.header-no-sticky #header ~ #main-content {
		//margin-top: -90px;
	}
	#header.with-topbar ~ #main-content {
		margin-top: -105px;
	}
	&.header-no-sticky.topnav-top #header ~ #main-content {
		margin-top: -90px;
	}
	&.home {
		#header ~ #main-content, &.header-no-sticky #header ~ #main-content {
			//margin-top: -90px;
		}
	}
	#page-title {
		~ #main-content {
			margin-top: 0;
		}
		.container, .container-large, .container-fluid {
			margin-top: 45px;
		}
		&.page-title-center {
			.container, .container-large, .container-fluid {
				margin-top: 60px;
			}
		}
	}
	#main-content > header .container {
		margin-top: 70px;
	}
}

.home .header-transparent {
	#header ~ #main-content, &.header-no-sticky #header ~ #main-content {
		//margin-top: -90px;
	}
}

.container-fluid {
	padding-right: 30px;
	padding-left: 30px;
}

.creative-masonry .container-fluid {
	padding-right: 15px;
	padding-left: 15px;
}

#main-content {
	padding: 0;
	width: 100%;
	overflow: hidden;
	position: relative;
}

body::after {
	padding-top: 4rem;
	z-index: 310;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 0;
	height: 0;
	content: '';
	opacity: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
	background: rgba(0, 0, 0, 0);
}

@media (max-width: 1200px) {
	.header-transparent #main-content > header .container {
		margin-top: 45px;
	}
}

/* SECTION / CONTENT */

.section {
	overflow: hidden;
}

.section-overlapping {
	margin-top: -12rem;
	position: relative;
}

.section {
	&.section-md, &.section-lg, &.section-hg {
		display: table;
		height: 100%;
		width: 100%;
	}
	&.section-md > div, &.section-lg > div, &.section-hg > div {
		display: table-cell;
		height: 100%;
		vertical-align: middle;
	}
	&.section-md {
		min-height: 200px;
		padding: 2rem 0;
	}
	&.section-lg {
		min-height: 400px;
		padding: 6rem 0;
	}
	&.section-hg {
		min-height: 600px;
		padding: 105px 0;
	}
	&.section-lg .title, &.section-hg .title {
		margin-bottom: 85px;
	}
}

.section-dark {
	color: #fff;
	h1, h2, h3, h4, h5, p {
		color: #fff;
	}
}

.full-container {
	min-height: 20rem;
	padding: 0;
	margin: 0 0 2rem 0;
	img {
		width: 100%;
	}
}

.heading {
	text-align: center;
	margin-bottom: 3rem;
	font-size: 1.4rem;
	h1, h2, h3, h4 {
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 700;
		margin-bottom: 1rem;
	}
}

.top-logo {
	text-align: center;
	margin: auto;
	max-width: 300px;
	img {
		opacity: .3;
		width: 100%;
	}
}

section:not(#header):not(.height-full) {
	.container, .container-large, .container-fluid {
		overflow: hidden;
		position: relative;
	}
}

@media (max-width: 992px) {
	.section > {
		.row > [class*="col-"], .container > .row > [class*="col-"], .container-fluid > .row > [class*="col-"], .container-large > .row > [class*="col-"] {
			padding-top: 0;
		}
	}
}

@media (max-width: 1200px) {
	.section.section-video {
		min-height: 0 !important;
		padding: 8rem 0 !important;
	}
	header.section-video {
		&.section-hg .top-text, &.section-lg .top-text {
			margin-top: 4rem;
		}
	}
}

@media (max-width: 768px) {
	.section.section-video {
		min-height: 0 !important;
		padding: 6rem 0 !important;
	}
	header.section-video {
		&.section-hg .top-text, &.section-lg .top-text {
			margin-top: 4rem;
		}
	}
}

@media (max-width: 600px) {
	.section.section-video {
		min-height: 0 !important;
		padding: 3rem 0 !important;
	}
	header.section-video {
		&.section-hg .top-text, &.section-lg .top-text {
			margin-top: 3rem;
		}
	}
}

@media (max-width: 450px) {
	.section.section-video {
		min-height: 0 !important;
		padding: 0 !important;
	}
	header.section-video {
		&.section-hg .top-text, &.section-lg .top-text {
			padding-top: 2rem;
		}
	}
}

/* EQUAL HEIGHT CONTENT */

.section.half-section {
	display: table;
	padding: 0;
	width: 100%;
	height: 650px;
	padding: 0;
	overflow: hidden;
}

.half-section > div {
	width: 50%;
	height: 100%;
	display: table-cell;
	vertical-align: middle;
	position: relative;
}

section.half-section figure {
	height: 100%;
}

.third-section {
	display: table;
	padding: 0;
	width: 100%;
	height: 500px;
	overflow: hidden;
	> div {
		width: 33.33333%;
		display: table-cell;
		vertical-align: middle;
		padding: 0;
		height: 100%;
		position: relative;
	}
}

section.third-section figure {
	height: 100%;
}

.half-section {
	.text-element {
		padding: 20px;
		overflow: hidden;
	}
	.text-element-wrapper {
		display: table;
		padding: 4rem 2rem;
		width: 100%;
		overflow: hidden;
	}
	&.half-section-first .text-element {
		padding-top: 0;
	}
	&.half-section-last .text-element {
		padding-bottom: 0;
	}
	&.half-section-alone .text-element {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.map-element {
	overflow: hidden;
	min-height: 300px;
	.map {
		position: absolute;
		top: 0;
		left: 0;
		width: 99.89%;
		height: 104%;
		min-height: 330px;
	}
}

.half-section.half-section-fullwidth {
	padding-top: 0 !important;
	min-height: 0 !important;
	height: auto;
}

.section.half-section.half-section-fullwidth .map-element .title {
	margin-top: 4rem;
}

.half-section.half-section-fullwidth {
	> div {
		width: 100%;
		display: block;
	}
	&.border-top {
		border-top: none !important;
	}
	.feature-box {
		.feature-content {
			display: block;
		}
		.icon {
			display: block;
			float: none;
			text-align: center;
			margin: auto;
		}
	}
	.p-30.p-r-100 {
		padding-right: 30px !important;
	}
	.title.icon-right-big i {
		float: none;
		margin: auto;
		text-align: center;
		padding-right: 0;
	}
	.img-cover:not(.img-visible-small-screen) {
		display: none;
	}
	.feature-box {
		margin: auto;
		margin-bottom: 1.2rem;
	}
	> div {
		padding: 0;
	}
}

.half-section-fullwidth ~ .half-section-fullwidth {
	margin-top: 20px;
}

.half-section.half-section-fullwidth {
	.img-cover {
		max-height: 500px !important;
		.title {
			margin-top: 10rem;
		}
	}
	display: block;
	> div, .text-element-inner, .text-element-wrapper {
		display: block;
	}
	.img-bottom {
		display: none;
	}
	text-align: center !important;
	&.t-right {
		text-align: center !important;
	}
	&.m-t-10, &.m-t-20, &.m-t-30 {
		margin-top: 0 !important;
	}
	.chart-wrapper {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
	.text-element-inner {
		&.p-l-100 {
			padding-left: 0 !important;
		}
		&.p-r-100 {
			padding-right: 0 !important;
		}
	}
	> .map {
		width: 100% !important;
		min-height: 400px;
	}
}

@media (min-width: 1600px) {
	.half-section {
		> .text-element .text-element-wrapper {
			padding: 4rem 8rem 2rem 8rem;
		}
		div ~ .text-element .text-element-wrapper {
			padding-right: 10rem;
		}
	}
}

@media (max-width: 1900px) {
  /*.section.half-section {height: 500px;}*/
}

@media (max-width: 1360px) {
  /*.section.half-section {height: 350px;}*/
  .section.half-section.section-hg {
  	height: 500px;
  }
  .text-element-inner .p-60 {
  	padding: 20px !important;
  }
}

@media (max-width: 992px) {
	.third-section {
		> div {
			width: 100%;
			display: block;
		}
		text-align: center !important;
		.t-right {
			text-align: center !important;
		}
	}
}

@media (max-width: 768px) {
	.half-section {
		> div {
			width: 100%;
			display: block;
		}
		text-align: center !important;
		.t-right {
			text-align: center !important;
		}
	}
}

/* TEXT ELEMENT */

.text-element {
	display: table;
	height: 100%;
	padding: 2rem;
	.title {
		margin-bottom: 1rem;
		h3 {
			font-size: 1.4rem;
			text-transform: uppercase;
			letter-spacing: 2px;
			margin-bottom: 0;
			padding-bottom: 0;
			line-height: normal;
		}
	}
	p {
		font-size: 1.1rem;
	}
}

.text-element-wrapper {
	display: table-cell;
	height: 100%;
	vertical-align: middle;
}

.text-element-inner {
	display: table-cell;
	height: 100%;
	vertical-align: middle;
	form {
		padding: 3.5rem;
	}
}

@media (max-width: 1400px) {
	.text-element .title h3 {
		font-size: 1.2rem;
	}
}

@media (max-width: 992px) {
	.text-element p:not(.subtitle) {
		display: block;
	}
}

@media (max-width: 1200px) {
	.text-element-inner form {
		padding: 1.5rem;
	}
}

@media (max-width: 500px) {
	.text-element-inner form.p-60 {
		padding: 1rem !important;
	}
}

/* CREATIVE ELEMENT */

.creative-element {
	display: table;
	height: 100%;
	width: 100%;
	padding: 30px;
	overflow: hidden;
	h1, .title {
		margin-bottom: 0.5rem;
		text-transform: none !important;
		font-weight: normal;
	}
	h1 {
		line-height: 1.5;
	}
	.title h3 {
		font-size: 1.5rem;
		font-weight: normal;
		text-transform: none;
		letter-spacing: 2px;
		margin-bottom: 0;
		padding-bottom: 0;
		line-height: normal;
	}
}

.boxed .creative-element .title h3 {
	font-size: 1.3rem;
}

.creative-element-wrapper, .creative-element-inner {
	display: table-cell;
	height: 100%;
	vertical-align: middle;
}

.creative-element {
	.title .subtitle {
		font-style: normal;
		color: $gold;
		font-weight: 600;
		font-size: 0.9rem;
		text-transform: uppercase;
		letter-spacing: 1px;
	}
	.text-animate.type .words-wrapper.selected {
		background-color: rgba(0, 0, 0, 0.3);
	}
}

.words-wrapper b {
	font-weight: normal;
}

/* CALL TO ACTION */

.section-call-to-action {
	padding: 2.5rem 0;
	.action-wrapper {
		position: relative;
	}
	.container, .container-large {
		overflow: visible !important;
	}
	.action-icon {
		font-size: 3.5rem;
		margin-bottom: 1.5rem;
		text-align: center;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
	.action-title {
		font-weight: 600;
		line-height: 1.5;
		margin-top: 0;
		text-transform: uppercase;
		letter-spacing: 1px;
		margin-bottom: 0;
	}
	p {
		&.action-subtitle {
			color: rgba(0, 0, 0, 0.4);
			font-size: 1.6rem;
			line-height: 1.2;
			padding: 0 1rem;
			font-style: italic;
			margin-top: 0.6rem;
		}
		font-size: 1.1rem;
		margin-bottom: 0;
		margin-top: 1.5rem;
	}
	.btn {
		margin-right: 0;
		margin-bottom: 0;
	}
	&.action-sm {
		padding: 1.5rem 0;
	}
	&.action-lg {
		padding: 5rem 0;
	}
	&.action-hg {
		padding: 9rem 0;
	}
	.action-btn {
		position: absolute;
		height: 50px;
		right: 0;
		top: 50%;
		margin-top: -25px;
	}
	&.section-dark .action-subtitle {
		color: rgba(255, 255, 255, 0.5);
	}
	&.action-left {
		.action-btn {
			left: 0;
			right: auto;
		}
		.action-text {
			text-align: right;
		}
	}
	&.action-center {
		.action-btn {
			position: relative;
			margin-top: 1.5rem;
			text-align: center;
			height: auto;
		}
		.action-text {
			text-align: center;
		}
	}
	&.action-sm {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
	&.action-boxed {
		padding-left: 2rem;
		padding-right: 2rem;
		border-radius: 2px;
	}
	&.action-bordered {
		border: 1px solid rgba(0, 0, 0, 0.1);
	}
}

@media (max-width: 992px) {
	.section-call-to-action {
		text-align: center;
		&.action-left .action-text {
			text-align: center;
		}
		.action-btn {
			position: relative;
			right: auto;
			top: auto;
			margin-top: 2rem;
		}
		&.action-lg {
			padding: 3rem 0;
		}
	}
}

/* SEPARATORS */

.separator {
	font-size: 1.2rem;
	line-height: 0.5;
	position: relative;
	margin: 3rem 0;
	color: rgba(0, 0, 0, 0.2);
	overflow: hidden;
	i {
		font-size: 20px;
	}
	span {
		display: inline-block;
		position: relative;
	}
	i {
		color: rgba(0, 0, 0, 0.3);
	}
	&.separator-line {
		width: 100%;
		position: relative;
		overflow: visible;
		.mask {
			overflow: hidden;
			height: 20px;
			&:after {
				content: '';
				display: block;
				margin: -23px auto 0;
				width: 100%;
				height: 25px;
				border: 2px solid rgba(0, 0, 0, 0.1);
			}
		}
		span {
			width: 50px;
			height: 50px;
			position: absolute;
			bottom: 100%;
			margin-bottom: -25px;
			left: 50%;
			margin-left: -25px;
			border-radius: 100%;
			border: 2px solid rgba(0, 0, 0, 0.12);
			background: #fff;
			i {
				position: absolute;
				top: 4px;
				bottom: 4px;
				left: 4px;
				right: 4px;
				border-radius: 100%;
				text-align: center;
				line-height: 40px;
				font-style: normal;
			}
		}
	}
	&.separator-rounded i {
		text-align: center;
		background: rgba(0, 0, 0, 0.1);
		color: rgba(0, 0, 0, 0.17);
		border: none;
	}
	&.separator-line.separator-rounded span {
		&:before, &:after {
			top: 20px;
		}
		&:before {
			right: 100%;
			margin-right: 0;
		}
		&:after {
			left: 100%;
			margin-left: 0;
		}
	}
	&.separator-fade {
		width: 100%;
		position: relative;
		overflow: visible;
		.mask {
			overflow: hidden;
			height: 20px;
			&:after {
				content: '';
				display: block;
				margin: -25px auto 0;
				width: 100%;
				height: 25px;
				border-radius: 125px / 12px;
				box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
			}
		}
		span {
			width: 50px;
			height: 50px;
			position: absolute;
			bottom: 100%;
			margin-bottom: -25px;
			left: 50%;
			margin-left: -25px;
			border-radius: 100%;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
			background: #fff;
			i {
				position: absolute;
				top: 4px;
				bottom: 4px;
				left: 4px;
				right: 4px;
				border-radius: 100%;
				border: 1px dashed #aaa;
				text-align: center;
				line-height: 40px;
				font-style: normal;
			}
		}
	}
	&.separator-left span {
		left: 25px !important;
		right: auto;
	}
	&.separator-right span {
		right: 0 !important;
		left: auto;
	}
}

/* INFOBOX */

.infobox {
	padding: 2rem;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0 1px 12px rgba(0, 0, 0, 0.18);
	&.infobox-square {
		border-radius: 0;
	}
}

@media (max-width: 992px) {
	.infobox {
		box-shadow: none;
	}
}

/* Infos */

.section-infos {
	[class*="col-"] {
		padding: 6rem 3.5rem;
	}
	.info-title {
		margin-bottom: 0;
	}
	.info-subtitle {
		font-style: italic;
		font-size: 1.1rem;
		color: rgba(0, 0, 0, 0.65);
		margin-bottom: 1rem;
	}
	.info-dark {
		.info-title {
			color: #fff;
		}
		p {
			color: rgba(255, 255, 255, 0.9);
		}
		.info-subtitle {
			color: rgba(255, 255, 255, 0.65);
		}
	}
}

/* WORKS */

.section-works {
	.row {
		margin-right: 0;
		margin-left: 0;
	}
	[class*="col-"] {
		padding-left: 0;
		padding-right: 0;
	}
	img {
		width: 100%;
	}
}

/* CHARTS */

.chart-wrapper {
	position: relative;
	display: block;
	width: 80%;
	height: 80%;
	margin-left: 10%;
	margin-top: 20%;
	canvas {
		width: 100%;
	}
}

/* NEWSLETTER */

.section-newsletter {
	margin-top: 30px;
	&.section {
		margin-top: 0;
	}
	.title {
		padding-top: 0;
	}
	.subtitle {
		color: rgba(0, 0, 0, 0.7) !important;
	}
	&.section-dark .subtitle {
		color: rgba(255, 255, 255, 0.7) !important;
	}
	.form-control {
		border: 2px solid #fff;
	}
	input[type=email] {
		width: 450px;
	}
}

@media (max-width: 768px) {
	.section-newsletter input[type=email] {
		width: 80%;
		margin: auto;
	}
}

@media (min-width: 768px) {
	.extended-product-desc ~ .section-newsletter, .extended-product-img ~ .section-newsletter {
		margin-top: 30px;
	}
}

/* FEATURES BOXES */

.feature-box {
	margin-bottom: 3rem;
	display: table;
	position: relative;
	&.feature-full {
		width: 100%;
	}
	.icon {
		display: table-cell;
		float: left;
		padding-right: 1rem;
		border-radius: 50%;
		width: 76px;
		height: 76px;
		color: #fff;
		padding-right: 0;
		font-size: 24px;
		text-align: center;
		margin-right: 25px;
		background: $gold;
		border-radius: 50%;
		-webkit-transition: all .3s;
		-moz-transition: all .3s;
		-o-transition: all .3s;
		transition: all .3s;
	}
	.feature-content {
		display: table-cell;
		vertical-align: top;
		p {
			color: rgba(0, 0, 0, 0.6);
			&.subtitle {
				font-size: 1.3rem;
				color: $gold;
			}
		}
		ul, li {
			list-style:none;
			padding:0;
		}
		ul {
			li {
				font-size:14px;
				line-height:16px;
				font-weight:300;
				margin-bottom:10px;
				a {
					-webkit-transition: all .3s;
					-moz-transition: all .3s;
					-o-transition: all .3s;
					transition: all .3s;
					color: $lightTxt;
					&:hover {
						color:$gold;
					}
				}
			}
		}
	}
	.feature-title {
		text-transform: uppercase;
		font-weight: 500;
		font-size:19px;
		line-height:22px;
		margin-bottom:20px;
	}
	.icon {
		img {
			max-width: 100%;
		}
		&.icon-simple {
			width: 50px;
		}
		&.icon-no-bg {
			background: none;
			color: #8E8E8E;
		}
	}
	&.dark .feature-title {
		color: #fff;
	}
}

.section-dark .feature-box .feature-title {
	color: #fff;
}

.feature-box.dark {
	.icon.icon-gray, p {
		color: rgba(255, 255, 255, 0.6);
	}
}

.section-dark .feature-box p {
	color: rgba(255, 255, 255, 0.6);
}

.feature-box.dark .icon.icon-line, .section-dark .feature-box .icon.icon-line {
	border: 1px solid rgba(255, 255, 255, 0.15);
	color: rgba(255, 255, 255, 0.6);
}

.feature-box {
	&.feature-sm .icon {
		width: 40px;
		height: 40px;
		font-size: 16px;
		i {
			padding-top: 2px;
			font-size: 25px;
		}
	}
	&.feature-lg .icon {
		width: 96px;
		height: 96px;
		font-size: 28px;
		i {
			padding-top: 27px;
			font-size: 38px;
		}
		&.icon-simple i {
			padding-top: 0;
			&.education_pencil-47 {
				padding-top: 8px;
			}
		}
	}
	.icon {
		i {
			padding-top: 25px;
			display: inline-block;
		}
		&.icon-simple i {
			padding-top: 8px;
		}
	}
}

.section-dark .feature-box .icon i {
	color: rgba(255, 255, 255, 0.4);
}

.feature-box {
	h1 {
		font-size: 1.7rem;
	}
	h2 {
		font-size: 1.2rem;
	}
	h3 {
		font-size: 1rem;
	}
	p {
		margin-bottom: 0;
	}
	&.feature-lg {
		h1 {
			font-size: 2rem;
		}
		h2 {
			font-size: 1.6rem;
		}
		h3 {
			font-size: 1.3rem;
		}
	}
	&.feature-sm {
		h1 {
			font-size: 1.5rem;
		}
		h2 {
			font-size: 1rem;
		}
		h3 {
			font-size: 0.8rem;
		}
		p {
			font-size: 0.9rem;
		}
	}
	.icon.icon-gray {
		background: rgba(0, 0, 0, 0.08);
		color: rgba(0, 0, 0, 0.8);
	}
	&:hover .icon.icon-gray {
		background: #383838;
		color: #fff;
	}
	.icon {
		&.icon-dark {
			background: $midGrey;
		}
		&.icon-line {
			background: none;
			border: 1px solid rgba(0, 0, 0, 0.15);
			color: rgba(0, 0, 0, 0.6);
		}
	}
}

.section-dark .feature-box .icon.icon-line {
	background: none;
	border: 1px solid rgba(0, 0, 0, 0.15);
	color: rgba(0, 0, 0, 0.6);
}

.feature-box {
	.icon {
		&.icon-square {
			border-radius: 2px;
		}
		&.icon-simple {
			background: none;
			border: none;
			color: rgba(0, 0, 0, 0.7);
			font-size: 28px;
			height: 60px;
			padding-top: 0;
			text-align: left;
		}
	}
	&.feature-sm .icon.icon-simple {
		font-size: 16px;
		height: auto;
	}
	&.feature-lg .icon.icon-simple {
		width: auto;
		i {
			font-size: 4rem;
		}
	}
	&.center {
		float: none;
		text-align: center;
		display: block;
		margin: auto;
		.icon, .feature-content {
			float: none;
			text-align: center;
			display: block;
			margin: auto;
		}
		.icon {
			margin-bottom: 1rem;
			&.icon-simple {
				margin-bottom: 0;
				~ h3 {
					margin-top: 1.3rem;
					margin-bottom: 1rem;
				}
			}
		}
		img {
			margin: auto;
			text-align: center;
			width: auto;
		}
	}
	&.boxed {
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 2px;
		padding: 1.5rem;
		position: relative;
		.icon {
			position: absolute;
			top: -38px;
			left: 50%;
			margin-left: -38px;
			&.icon-line {
				background: #fff;
			}
		}
	}
	&.feature-lg.boxed .icon {
		top: -48px;
		margin-left: -48px;
	}
	&.boxed {
		h3 {
			margin-top: 3rem;
		}
		.btn {
			margin-right: 0 !important;
		}
	}
	&.feature-icon-right {
		text-align: right;
		.icon {
			margin-right: 0;
			margin-left: 1.3rem;
			float: right;
		}
	}
}

@media (max-width: 1200px) {
	.feature-box {
		margin-bottom: 1.6rem;
		p {
			font-size: 1rem;
			line-height: 1.6;
		}
	}
	.split-section .feature-box p {
		font-size: 1rem;
		line-height: 1.6;
	}
}

@media (max-width: 992px) {
	div[class*="col-lg-"] .feature-box {
		margin: 0 auto 2.2rem auto !important;
		&.m-t-80 {
			margin: 0 auto 2.2rem auto !important;
		}
		&.feature-full {
			width: auto;
		}
		.icon, .feature-content {
			float: none;
			text-align: center;
			margin: auto;
			display: block;
		}
		.icon {
			margin-bottom: 1rem;
		}
		&.feature-icon-right {
			.icon {
				position: absolute;
				top: 0;
				left: 50%;
				margin-left: -38px;
			}
			.feature-content {
				padding-top: 90px;
			}
		}
	}
}

/* MAP */

.map {
	width: 100%;
	margin: 0 auto;
	display: block;
	height: 350px;
	margin-bottom: 1rem;
	&.f-left {
		width: 50%;
		height: 210px;
		margin: .5rem 1rem 0 0;
	}
	&.f-right {
		width: 50%;
		height: 210px;
		margin: .5rem 0 0 1rem;
	}
}

.fullwidth > .map {
	margin-bottom: 0;
}

.half-section > .map {
	width: 50% !important;
}

.gm-style-iw {
	text-align: left;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 0.85rem;
	line-height: normal;
	margin-bottom: 0;
	p {
		text-align: left;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: 0.85rem;
		line-height: normal;
		margin-bottom: 0;
	}
}

.map-header {
	position: absolute;
	width: 100%;
	left: 0;
	top: 0;
	height: 100%;
	height: calc(100% + 30px) !important;
}

.section-fullmap {
	width: 100%;
	height: 100%;
	min-height: 900px;
	overflow: hidden;
	position: relative;
	.infobox {
		margin-top: 4rem;
	}
}

.map-background {
	position: relative;
	> .row {
		padding-top: 80px;
		padding-bottom: 80px;
	}
}

.section-fullmap .map, .map-background .map {
	position: absolute;
	height: 100%;
	min-height: 800px;
}

@media (max-width: 992px) {
	.section-fullmap {
		&:not(.section-contact) {
			height: auto !important;
			min-height: 0 !important;
			.map {
				opacity: 0;
			}
		}
		.infobox {
			box-shadow: none;
		}
		.map {
			min-height: 0;
		}
	}
	.map-background {
		.map {
			min-height: 0;
		}
		> .row {
			padding-top: 50px;
		}
	}
	.section-fullmap .infobox {
		margin-top: 0;
	}
}

@media (max-width: 768px) {
	.map-background {
		height: auto !important;
		padding-top: 0 !important;
		> {
			.row {
				padding-top: 0;
				padding-bottom: 0;
				&.m-t-100 {
					margin-top: 0 !important;
				}
			}
			.map {
				opacity: 0;
			}
		}
	}
	.map {
		&.f-left, &.f-right {
			float: none !important;
			margin: 0 !important;
			width: 100% !important;
		}
	}
}

/* NOTIFCATIONS */

#toast-container {
	i {
		font-size: 2rem;
		color: #65B9EA;
		display: block;
		text-align: center;
		margin-bottom: 0.5rem;
		padding-right: 0.4rem;
	}
	> {
		div {
			width: auto;
			-moz-border-radius: 0;
			-webkit-border-radius: 0;
			border-radius: 0;
			opacity: 1;
			padding: 0.6rem;
			-moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
			-webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
			-o-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
			box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
		}
		:hover {
			-webkit-box-shadow: none;
			box-shadow: none;
		}
		.toast-info {
			background-image: none !important;
		}
		div {
			padding-right: 20px;
		}
	}
}

.toast-info {
	background: #383838;
}

.toast-warning {
	background: #CB6C6C;
}

.toast-builder {
	top: 1rem;
	right: 340px;
}

.toast-warning .text-wrapper {
	padding-left: 40px;
}

.toast-top-right {
	top: 65px;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

.topnav-top .toast-top-right {
	top: 95px;
}

.toast-top-center {
	top: 70px;
}

.toast-center {
	text-align: center;
}

/* PROGRESS BAR */

progress[value] {
	&::-webkit-progress-bar {
		background-color: transparent;
	}
	&::-webkit-progress-value {
		background: blue;
	}
}

.progress {
	position: relative;
	-webkit-box-shadow: none;
	background: #ebedef;
	box-shadow: none;
	height: 5px;
	padding: 0;
	text-transform: uppercase;
	letter-spacing: 1px;
	overflow: visible;
	margin-top: 2.2rem;
	margin-bottom: 4rem;
}

progress {
	height: 100%;
	width: 0%;
	text-align: left;
	color: #121212;
	border-radius: 2px;
	-webkit-box-shadow: none;
	background: #121212;
	box-shadow: none;
	line-height: 2rem;
	float: left;
	overflow: visible;
	-webkit-transition: width .6s ease;
	-o-transition: width .6s ease;
	transition: width .6s ease;
}

.progress .progress-info {
	position: absolute;
	top: -1.8rem;
	width: 100%;
	font-size: 0.85rem;
}

.progress-info .progress-value {
	opacity: 0;
	float: right;
	-webkit-transition: opacity .6s ease;
	-o-transition: opacity .6s ease;
	transition: opacity .6s ease;
}

.progress-bar-primary {
	background-color: $gold;
	&[value]::-webkit-progress-value {
		background-color: $gold;
	}
}

.progress-bar-blue {
	background-color: #319db5;
	&[value]::-webkit-progress-value {
		background-color: #319db5;
	}
}

.progress-bar-success {
	background-color: #49B79E;
	&[value]::-webkit-progress-value {
		background-color: #49B79E;
	}
}

.progress-bar-danger {
	background-color: #C45959;
	&[value]::-webkit-progress-value {
		background-color: #C45959;
	}
}

.progress-bar-warning {
	background-color: #ff9122;
	&[value]::-webkit-progress-value {
		background-color: #ff9122;
	}
}

.progress {
	&.progress-bar-sm {
		height: 1px;
	}
	height: 4px;
	&.progress-bar-lg {
		height: 6px;
	}
	&.progress-bar-hg {
		height: 10px;
	}
}

/* CIRCULAR PROGRESS CHART */

.circular-bar {
	padding: 0 2rem;
	margin-bottom: 6rem;
	text-align: center;
	opacity: 0;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	&.circular-visible {
		opacity: 1;
	}
	.circular-bar-content {
		margin-top: -110px;
		text-align: center;
		strong {
			display: block;
			font-weight: 700;
			font-size: 18px;
			font-size: 1.2rem;
			line-height: 1.5rem;
			line-height: 24px;
		}
		label {
			display: block;
			font-weight: 300;
			font-size: 15px;
			font-size: .9375rem;
			line-height: 1.25rem;
			line-height: 20px;
		}
	}
	> div {
		display: block !important;
		margin: auto;
	}
}

/* IMAGES ZOOM */

.easyzoom {
	position: relative;
	display: inline-block;
	*display: inline;
	*zoom: 1;
	img {
		vertical-align: bottom;
	}
	&.is-loading img {
		cursor: progress;
	}
	&.is-ready img {
		cursor: crosshair;
	}
	&.is-error img {
		cursor: not-allowed;
	}
}

.easyzoom-notice {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 150;
	width: 10em;
	margin: -1em 0 0 -5em;
	line-height: 2em;
	text-align: center;
	background: #FFF;
	box-shadow: 0 0 10px #888;
}

.easyzoom-flyout {
	position: absolute;
	z-index: 100;
	overflow: hidden;
	background: #FFF;
	top: 0;
	z-index: 20000;
	opacity: 0;
	-webkit-transition: opacity .6s ease;
	-moz-transition: opacity .6s ease;
	transition: opacity .6s ease;
	&:hover {
		opacity: 1;
	}
}

.easyzoom--overlay .easyzoom-flyout {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.easyzoom--adjacent .easyzoom-flyout {
	top: 0;
	left: 100%;
	width: 100%;
	height: 100%;
	margin-left: 20px;
}

.easyzoom-flyout {
	background: #eaeaea;
	img {
		max-width: none !important;
		width: auto !important;
	}
}

/* ICONS */

.icon-square a, .icon-rounded a, .icon-circle a {
	margin: 0 .4rem .4rem 0;
	display: inline-block;
	overflow: hidden;
}

.icon-square a:hover, .icon-rounded a:hover, .icon-circle a:hover {
	color: #fff;
}

.icon-hover {
	a {
		-webkit-transition: background .3s;
		-moz-transition: background .3s;
		-ms-transition: background .3s;
		-o-transition: background .3s;
		transition: background .3s ease-in-out;
	}
	i {
		width: 40px;
		display: inline-block;
		position: relative;
		-webkit-transition: transform .3s ease-in-out, opacity .3s ease-in-out;
		-moz-transition: transform .3s ease-in-out, opacity .3s ease-in-out;
		-ms-transition: transform .3s ease-in-out, opacity .3s ease-in-outt;
		-o-transition: transform .3s ease-in-out, opacity .3s ease-in-out;
		transition: transform .3s ease-in-out, opacity .3s ease-in-out;
	}
}

.icon-square i, .icon-rounded i, .icon-circle i, .icon-line i {
	width: 40px;
	display: inline-block;
	position: relative;
	-webkit-transition: transform .3s ease-in-out, opacity .3s ease-in-out;
	-moz-transition: transform .3s ease-in-out, opacity .3s ease-in-out;
	-ms-transition: transform .3s ease-in-out, opacity .3s ease-in-outt;
	-o-transition: transform .3s ease-in-out, opacity .3s ease-in-out;
	transition: transform .3s ease-in-out, opacity .3s ease-in-out;
}

.icon-square a, .icon-rounded a, .icon-circle a {
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	font-size: 1.4rem;
}

.icon-square a {
	border-radius: 0;
}

.icon-rounded a {
	border-radius: 4px;
}

.icon-circle a {
	border-radius: 50%;
}

.icon-gray a {
	background: #F1F1F1;
	color: #535353 !important;
}

.icon-dark a {
	background: #393939;
	color: #fff !important;
}

.icon-line a {
	background: none;
	border: 1px solid #E0E0E0;
	color: #535353 !important;
	&:hover {
		border-color: transparent;
	}
}

.icon-hover a {
	i {
		transform: translateY(0);
	}
	&:hover i {
		transform: translateY(-40px);
	}
	i:last-child {
		color: #fff;
		opacity: 0;
	}
	&:hover i {
		&:first-child {
			color: #fff;
			opacity: 0;
		}
		&:last-child {
			opacity: 1;
		}
	}
}

/* Icon Size */

.icon-sm {
	&.icon-hover i, &.icon-square i, &.icon-rounded i, &.icon-circle i, &.icon-line i {
		width: 22px;
	}
}

.icon-lg {
	&.icon-hover i, &.icon-square i, &.icon-rounded i, &.icon-circle i, &.icon-line i {
		width: 60px;
	}
}

.icon-sm {
	&.icon-square a, &.icon-rounded a, &.icon-circle a {
		width: 25px;
		height: 25px;
		line-height: 25px;
		text-align: center;
		font-size: 0.9rem;
	}
}

.icon-lg {
	&.icon-square a, &.icon-rounded a, &.icon-circle a {
		width: 60px;
		height: 60px;
		line-height: 60px;
		text-align: center;
		font-size: 1.8rem;
	}
}

.icon-sm.icon-hover a:hover i {
	transform: translateY(-25px);
}

.icon-lg.icon-hover a:hover i {
	transform: translateY(-60px);
}

/* Social Icon Color */

.icon-500px:hover, .icon-colored .icon-500px {
	background: #222222 !important;
}

.icon-android:hover, .icon-colored .icon-android {
	background: #a4c639 !important;
}

.icon-apple:hover, .icon-colored .icon-apple {
	background: #5BC236 !important;
}

.icon-behance:hover, .icon-colored .icon-behance {
	background: #053eff !important;
}

.icon-bitbucket:hover, .icon-colored .icon-bitbucket {
	background: #205081 !important;
}

.icon-codepen:hover, .icon-colored .icon-codepen {
	background: #4E4E4E !important;
}

.icon-css3:hover, .icon-colored .icon-css3 {
	background: #26a8dc !important;
}

.icon-delicious:hover, .icon-colored .icon-delicious {
	background: #3399ff !important;
}

.icon-devianart:hover, .icon-colored .icon-devianart {
	background: #4e6252 !important;
}

.icon-digg:hover, .icon-colored .icon-digg {
	background: #000000 !important;
}

.icon-dribbble:hover, .icon-colored .icon-dribbble {
	background: #ea4c89 !important;
}

.icon-dropbox:hover, .icon-colored .icon-dropbox {
	background: #007ee5 !important;
}

.icon-facebook:hover, .icon-colored .icon-facebook {
	background: #3b5998 !important;
}

.icon-firefox:hover, .icon-colored .icon-firefox {
	background: #e66000 !important;
}

.icon-flickr:hover, .icon-colored .icon-flickr {
	background: #ff0084 !important;
}

.icon-foursquare:hover, .icon-colored .icon-foursquare {
	background: #f94877 !important;
}

.icon-gg:hover, .icon-colored .icon-gg {
	background: #747474 !important;
}

.icon-git:hover, .icon-colored .icon-git {
	background: #f34c27 !important;
}

.icon-github:hover, .icon-colored .icon-github {
	background: #4183c4 !important;
}

.icon-google:hover, .icon-colored .icon-google, .icon-google-plus:hover, .icon-colored .icon-google-plus {
	background: #dd4b39 !important;
}

.icon-houzz:hover, .icon-colored .icon-houzz {
	background: #7ac142 !important;
}

.icon-html5:hover, .icon-colored .icon-html5 {
	background: #e34f26 !important;
}

.icon-instagram:hover, .icon-colored .icon-instagram {
	background: #125688 !important;
}

.icon-internet-explorer:hover, .icon-colored .icon-internet-explorer {
	background: #1ebbee !important;
}

.icon-ioxhost:hover, .icon-colored .icon-ioxhost {
	background: #faa829 !important;
}

.icon-joomla:hover, .icon-colored .icon-joomla {
	background: #0fa13c !important;
}

.icon-lastfm:hover, .icon-colored .icon-lastfm {
	background: #d51007 !important;
}

.icon-linkedin:hover, .icon-colored .icon-linkedin {
	background: #007bb5 !important;
}

.icon-linux:hover, .icon-colored .icon-linux {
	background: #1793d1 !important;
}

.icon-maxcdn:hover, .icon-colored .icon-maxcdn {
	background: #555555 !important;
}

.icon-medium:hover, .icon-colored .icon-medium {
	background: $midGrey !important;
}

.icon-odnoklassniki:hover, .icon-colored .icon-odnoklassniki {
	background: #ed812b !important;
}

.icon-opencart:hover, .icon-colored .icon-opencart {
	background: #33c6f0 !important;
}

.icon-openid:hover, .icon-colored .icon-openid {
	background: #f28018 !important;
}

.icon-opera:hover, .icon-colored .icon-opera {
	background: #cc0f16 !important;
}

.icon-pagelines:hover, .icon-colored .icon-pagelines {
	background: #343434 !important;
}

.icon-paypal:hover, .icon-colored .icon-paypal {
	background: #003087 !important;
}

.icon-pinterest:hover, .icon-colored .icon-pinterest {
	background: #cb2027 !important;
}

.icon-qq:hover, .icon-colored .icon-qq {
	background: $midGrey !important;
}

.icon-rebel:hover, .icon-colored .icon-rebel {
	background: #ff9c00 !important;
}

.icon-safari:hover, .icon-colored .icon-safari {
	background: #2cb4c4 !important;
}

.icon-sellsy:hover, .icon-colored .icon-sellsy {
	background: #0174c8 !important;
}

.icon-share-alt:hover, .icon-colored .icon-share-alt {
	background: #4DA25D !important;
}

.icon-simplybuilt:hover, .icon-colored .icon-simplybuilt {
	background: #2E2E2E !important;
}

.icon-skyatlas:hover, .icon-colored .icon-skyatlas {
	background: #08455b !important;
}

.icon-skype:hover, .icon-colored .icon-skype {
	background: #00aff0 !important;
}

.icon-slack:hover, .icon-colored .icon-slack {
	background: #6ecadc !important;
}

.icon-slideshare:hover, .icon-colored .icon-slideshare {
	background: #067ab6 !important;
}

.icon-soundcloud:hover, .icon-colored .icon-soundcloud {
	background: #ff8800 !important;
}

.icon-spotify:hover, .icon-colored .icon-spotify {
	background: #2ebd59 !important;
}

.icon-stack-exchange:hover, .icon-colored .icon-stack-exchange {
	background: $midGrey !important;
}

.icon-stack-overflow:hover, .icon-colored .icon-stack-overflow {
	background: #fe7a15 !important;
}

.icon-steam:hover, .icon-colored .icon-steam {
	background: #171a21 !important;
}

.icon-stumbleupon:hover, .icon-colored .icon-stumbleupon {
	background: #eb4924 !important;
}

.tencent-weibo:hover, .icon-colored .icon-weibo {
	background: #ff5640 !important;
}

.icon-trello:hover, .icon-colored .icon-trello {
	background: #0079bf !important;
}

.icon-tripadvisor:hover, .icon-colored .icon-tripadvisor {
	background: #589442 !important;
}

.icon-tumblr:hover, .icon-colored .icon-tumblr {
	background: #32506d !important;
}

.icon-twitter:hover, .icon-colored .icon-twitter {
	background: #00aced !important;
}

.icon-viacoin:hover, .icon-colored .icon-viacoin {
	background: #3F3A3A !important;
}

.icon-vimeo:hover, .icon-colored .icon-vimeo {
	background: #aad450 !important;
}

.icon-vine:hover, .icon-colored .icon-vine {
	background: #00b488 !important;
}

.icon-vk:hover, .icon-colored .icon-vk {
	background: $midGrey !important;
}

.icon-weixin:hover, .icon-colored .icon-weixin {
	background: #2D415F !important;
}

.icon-whatsapp:hover, .icon-colored .icon-whatsapp {
	background: #4dc247 !important;
}

.icon-wikipedia-w:hover, .icon-colored .icon-wikipedia-w {
	background: #727272 !important;
}

.icon-windows:hover, .icon-colored .icon-windows {
	background: #00bcf2 !important;
}

.icon-wordpress:hover, .icon-colored .icon-wordpress {
	background: #21759b !important;
}

.icon-xing:hover, .icon-colored .icon-xing {
	background: #026466 !important;
}

.icon-yahoo:hover, .icon-colored .icon-yahoo {
	background: #400191 !important;
}

.icon-yelp:hover, .icon-colored .icon-yelp {
	background: #cd201f !important;
}

.icon-youtube:hover, .icon-colored .icon-youtube {
	background: #bb0000 !important;
}

.social-icons i {
	font-size: 1.1rem;
	margin-right: 2rem;
	color: rgba(0, 0, 0, 0.5);
}

/* COUNTERS */

.section-countup {
	padding: 0;
	.container-fluid {
		padding-right: 0;
		padding-left: 0;
	}
	[class*="col-"] {
		border-right: 1px dashed rgba(0, 0, 0, 0.15);
		&:last-child {
			border-right: none;
		}
	}
	&.section > {
		.container-fluid > .row > [class*=col-], .container-large > .row > [class*=col-], .container > .row > [class*=col-] {
			padding-top: 0;
		}
	}
}

.section > .row > [class*=col-] {
	padding-top: 0;
}

.counter {
	padding: 1.5rem 0;
	p {
		text-align: center;
		margin-top: 0;
		letter-spacing: 1px;
		color: #000;
	}
}

.countup {
	font-weight: 700;
	text-align: center;
}

.counter {
	.countup {
		font-size: 1.9rem;
		font-weight: 600;
		margin-bottom: 0.6rem;
		line-height: normal;
	}
	i {
		text-align: center;
		font-size: 1.9rem;
		display: block;
		margin-bottom: 0.4rem;
		color: rgba(0, 0, 0, 0.3);
	}
}

.countup.countup-inline {
	display: inline;
	text-align: left;
}

.counter {
	.count-title {
		font-weight: 600;
		font-size: 1rem;
		text-transform: uppercase;
		margin-bottom: 0.4rem;
		line-height: normal;
	}
	.count-subtitle {
		color: rgba(0, 0, 0, 0.4);
		font-size: 1.2rem;
		font-style: italic;
		line-height: 0.9rem;
		margin-top: 1rem;
		font-weight: 600;
	}
}

.countdown-block {
	font-size: 1.6rem;
	text-align: center;
	float: left;
	padding: 2rem;
	width: 20%;
	color: rgba(0, 0, 0, 0.5);
	span {
		display: block;
		font-size: 24px;
		color: #000;
		font-weight: 600;
		letter-spacing: 1px;
	}
}

.counter.counter-lg {
	.countup {
		font-size: 3rem;
		margin-bottom: 0.7rem;
	}
	i {
		font-size: 2.6rem;
	}
}

.countdown-block:not(:last-child) {
	border-right: 2px solid rgba(0, 0, 0, 0.05);
}

.countdown.countdown-inline {
	display: inline-block;
	font-weight: 700;
}

.counter-creative.counter .count-title {
	font-size: 1.25rem;
	text-transform: none;
}

@media (max-width: 992px) {
	.section-countup .col-lg-3:nth-child(2) {
		border-right: none;
	}
	.counter.counter-lg .countup {
		font-size: 1.8rem;
		margin-bottom: 0.7rem;
	}
}

@media (max-width: 768px) {
	.section-countup .col-lg-3 {
		border-right: none;
		border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
		&:last-child {
			border-bottom: none;
		}
	}
	.countdown-block {
		font-size: 1.2rem;
		padding: 1rem;
	}
}

/* Tooltip */

.tooltip-inner {
	border-radius: 0;
	padding: 8px 10px;
	background-color: #191919;
	max-width: 350px !important;
	p {
		color: #fff;
		font-weight: 300 !important;
		font-size: 0.95rem !important;
		letter-spacing: 1px;
		margin-bottom: 0;
	}
}

.tooltip.top .tooltip-arrow {
	margin-left: -10px;
	border-width: 8px 10px 0;
}

.tooltip-arrow {
	border-top-color: #191919;
}

.tooltip.bottom .tooltip-arrow {
	top: 1px;
}

/* Item */

.item {
	.item-meta {
		margin-bottom: 10px;
		color: rgba(0, 0, 0, 0.6);
		font-size: 0.9rem;
		a {
			margin-bottom: 10px;
			color: rgba(0, 0, 0, 0.6);
			font-size: 0.9rem;
			&:hover {
				color: $gold;
			}
		}
		span {
			display: inline-block;
		}
	}
	.video-wrapper {
		position: relative;
		padding-bottom: 56.25%;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	.flex-control-nav {
		bottom: 0;
		z-index: 2;
	}
	.flex-control-paging li a {
		width: 9px;
		height: 9px;
	}
}

/* Alert */

.alert {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	color: rgba(255, 255, 255, 0.8);
	border-color: rgba(0, 0, 0, 0);
	.icon-circle {
		background-color: rgba(0, 0, 0, 0.05);
		font-size: 25px;
		display: inline-block;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		width: 55px;
		height: 55px;
		text-align: center;
		padding-top: 10px;
	}
	.media-body {
		width: auto;
		p {
			margin-bottom: 0;
			white-space: normal;
			color: rgba(255, 255, 255, 0.8);
		}
	}
	a {
		color: #3C3C3C;
		font-weight: 600;
	}
	button {
		margin-bottom: 0;
	}
	.close .icons-office-52 {
		font-size: 10px;
	}
	.media-left img {
		border: 3px solid rgba(0, 0, 0, 0.2);
		margin-top: -3px;
		width: 62px;
	}
	> p + p {
		margin-top: 0;
	}
}

h4.alert-title {
	color: rgba(0, 0, 0, 0.8);
	font-size: 1.1rem;
	font-weight: 600;
	margin-bottom: 0.3rem;
	margin-top: 0.5rem;
}

.alert-primary {
	background-color: #EB8B73;
}

.alert-success {
	background-color: #b2dfdb;
	&.alert .media-body p {
		color: rgba(0, 0, 0, 0.7);
	}
}

.alert-info {
	background-color: #bbdefb;
	color: rgba(0, 0, 0, 0.7);
	color: #515F6B;
}

.alert.alert-info a {
	color: #247699;
}

.alert-warning {
	background-color: #ffccbc;
	color: rgba(0, 0, 0, 0.7);
	color: #865E51;
	&.alert .media-body p {
		color: rgba(0, 0, 0, 0.7);
	}
}

.alert-danger {
	background-color: #e57373;
}

.alert-dark {
	background-color: #33363c;
	h4.alert-title {
		color: #fff;
	}
	&.alert {
		a {
			color: #5DBFD5;
		}
		.media-left img {
			border: 3px solid rgba(255, 255, 255, 0.2);
		}
		.icon-circle {
			background-color: rgba(255, 255, 255, 0.1);
		}
		.media-body p {
			height: auto;
			line-height: 20px;
			margin-bottom: 0;
			overflow: visible;
		}
	}
}

/* Clients */

.clients {
	padding-top: 30px !important;
}

.owl-carousel.clients {
	padding: 1rem 0 !important;
}

.clients {
	.item {
		padding: 0 40px;
		opacity: 0.4;
		webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}
	&.clients-10 .item {
		padding: 0 20px;
	}
	.item {
		&.col-md-1 {
			padding: 0 20px;
		}
		&:hover {
			opacity: 0.6;
		}
	}
	img {
		width: 100%;
		webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}
	.row {
		[class*="col-"] {
			&:before {
				content: "";
				position: absolute;
				bottom: -20px;
				right: 0;
				left: auto;
				height: 40px;
				width: 2px;
				border-left: 2px solid #eee;
			}
			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				right: -20px;
				left: auto;
				width: 40px;
				height: 2px;
				border-bottom: 2px solid #eee;
			}
			&:last-child {
				&:before, &:after {
					display: none;
				}
			}
		}
		&:last-child [class*="col-"] {
			&:before, &:after {
				display: none;
			}
		}
	}
}

.clients-boxed {
	padding-left: 15px;
	padding-right: 15px;
	margin-top: -15px;
	margin-bottom: -15px;
	img {
		padding: 5% 20%;
		opacity: 0.4;
	}
	[class*="col-"] {
		&:before, &:after {
			display: none;
		}
		border-right: 1px dashed rgba(0, 0, 0, 0.15);
		border-bottom: 1px dashed rgba(0, 0, 0, 0.15);
	}
}

.clients .row {
	[class*="col-"]:last-child {
		border-right: none;
	}
	&:last-child [class*="col-"] {
		border-bottom: none;
	}
}

.clients-boxed [class*="col-"] img {
	opacity: 0.9;
}

@media (max-width: 768px) {
	.clients-boxed [class*="col-"] {
		border-right: none;
	}
	.clients .row:last-child [class*="col-"] {
		border-bottom: 1px dashed rgba(0, 0, 0, 0.15);
	}
}

/* Testimonials */

.testimonial {
	.testimonial-content {
		font-style: italic;
		color: #8C8C8C;
		border: 1px solid #E3E3E3;
		border-radius: 3px;
		padding: 1rem;
		background-color: #fff;
		z-index: 200;
		&::before {
			content: "";
			height: 10px;
			width: 10px;
			position: absolute;
			background: #fff;
			left: 22px;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			border-bottom: 1px solid #E3E3E3;
			border-right: 1px solid #E3E3E3;
			bottom: -6px;
		}
	}
	.testimonial-meta {
		img {
			border-radius: 50%;
			width: 45px;
			float: left;
			margin-right: 1rem;
		}
		p {
			margin-bottom: 0;
		}
		.testimonial-name {
			font-weight: 700;
			font-size: 1rem;
			color: #666666;
		}
		.testimonial-subtitle {
			color: #8C8C8C;
			font-weight: normal;
			font-size: 1rem;
			a {
				color: $gold;
			}
		}
	}
	&.testimonial-center {
		.testimonial-content::before {
			left: 50%;
			margin-left: -5px;
		}
		.testimonial-info {
			text-align: center;
		}
	}
	&.testimonial-right {
		.testimonial-content::before {
			left: auto;
			right: 22px;
		}
		.testimonial-info {
			text-align: right;
		}
		.testimonial-meta img {
			float: right;
			margin-right: 0;
			margin-left: 1rem;
		}
	}
}

.bx-slider .testimonial {
	text-align: center;
}

.testimonial-photos {
	text-align: center;
	.photo-hold {
		display: inline-block;
		width: 100px;
		padding: 10px 10px 0 10px;
		margin-right: 10px;
		margin-left: 10px;
		border-radius: 4px;
		box-sizing: border-box;
		-moz-transition: all .3s linear;
		-o-transition: all .3s linear;
		-webkit-transition: all .3s linear;
		transition: all .3s linear;
	}
	.photo-bg {
		background: #000;
		display: block;
	}
	img {
		border-radius: 50%;
		opacity: .4;
		width: 100%;
		-moz-transition: all .3s linear;
		-o-transition: all .3s linear;
		-webkit-transition: all .3s linear;
		transition: all .3s linear;
	}
	.active img {
		opacity: 1;
	}
}

.testimonial-description {
	color: #9F9F9F;
}

.testimonial {
	.comment {
		font-style: italic;
		color: rgba(0, 0, 0, 0.6);
	}
	.client-info {
		color: $midGrey;
	}
}

.review_top_ornament {
	text-align: center;
}

.review_top_ornament_line {
	border-top: solid 1px rgba(0, 0, 0, 0.1);
}

.review_top_ornament_quotemark {
	color: rgba(0, 0, 0, 0.1);
}

.review_top_ornament_line {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	width: 6rem;
	display: inline-block;
}

.review_top_ornament_quotemark {
	font-weight: bold;
	font-size: 5rem;
	display: inline-block;
	margin-bottom: 3rem;
	line-height: 3rem;
}

.review_bottom_ornament {
	text-align: center;
}

.review_bottom_ornament_line {
	border-top: solid 1px rgba(0, 0, 0, 0.1);
}

.review_bottom_ornament_triangle {
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid rgba(0, 0, 0, 0.16);
}

.review_bottom_ornament_line {
	margin-left: 0rem;
	margin-right: 0rem;
	width: 4rem;
	display: inline-block;
}

.review_bottom_ornament_triangle {
	width: 0;
	height: 0;
	position: relative;
	display: inline-block;
	top: 9px;
	margin-left: -5px;
	margin-right: -5px;
}

.review_bottom_ornament_line {
	margin-left: 0rem;
	margin-right: 0rem;
	width: 4rem;
	display: inline-block;
}

.review_bottom_ornament_triangle {
	width: 0;
	height: 0;
	position: relative;
	display: inline-block;
	top: 9px;
	margin-left: -5px;
	margin-right: -5px;
	&:after {
		content: '';
		width: 0;
		height: 0;
		position: absolute;
		top: -10px;
		left: -9px;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;
		border-top: 9px solid white;
	}
}

.testimonial {
	&.style-2 {
		img {
			display: block;
			max-width: 80px;
			border-radius: 50%;
			margin: 0 auto;
		}
		.testimonial-content {
			background: none;
			border: none;
			text-align: center;
			&::before {
				display: none;
			}
		}
	}
	.testimonial-quote {
		display: block;
		margin: auto;
		text-align: center;
		font-size: 2rem;
		color: rgba(0, 0, 0, 0.15);
	}
}

/*
.testimonial.style-2.testimonial-img-bottom .testimonial-content::after {bottom:auto;top:-35px;}
*/

.section-dark .testimonial .testimonial-quote {
	color: rgba(255, 255, 255, 0.15);
}

.testimonial.style-2 {
	.testimonial-info {
		text-align: center;
	}
	img ~ .testimonial-content {
		margin-top: 0;
	}
}

.testimonial-content ~ img ~ .testimonial-meta {
	margin-top: 1rem;
}

.testimonial-img-bottom .testimonial-content {}

.section-dark {
	.testimonial-content {
		color: rgba(255, 255, 255, 0.8);
	}
	.testimonial {
		.comment {
			color: rgba(255, 255, 255, 0.8);
		}
		.testimonial-meta .testimonial-subtitle {
			color: rgba(255, 255, 255, 0.5);
		}
		&.style-2 {
			color: rgba(255, 255, 255, 0.6);
			.testimonial-content::after {
				color: rgba(255, 255, 255, 0.25);
			}
		}
		&:not(.style-2) .testimonial-content {
			background: #262626;
			border: 1px solid #393939;
			&::before {
				background: #262626;
				border-bottom: 1px solid #393939;
				border-right: 1px solid #393939;
			}
		}
	}
	.review_top_ornament_line {
		border-top: solid 1px rgba(255, 255, 255, 0.2);
	}
	.review_top_ornament_quotemark {
		color: rgba(255, 255, 255, 0.2);
	}
	.review_bottom_ornament_line {
		border-top: solid 1px rgba(255, 255, 255, 0.2);
	}
	.review_bottom_ornament_triangle {
		border-top: 10px solid rgba(255, 255, 255, 0.25);
		&:after {
			border-top: 9px solid #181717;
		}
	}
	.testimonial {
		.testimonial-meta .testimonial-name {
			color: rgba(255, 255, 255, 0.9);
		}
		.client-info {
			color: #B3B3B3;
		}
	}
}

/* PRELOADERS */

.spinner {
	width: 40px;
	height: 40px;
	position: relative;
	margin: 1rem auto;
}

.double-bounce1 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #333;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
	animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #333;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
	animation: sk-bounce 2.0s infinite ease-in-out;
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
	0%, 100% {
		-webkit-transform: scale(0);
	}

	50% {
		-webkit-transform: scale(1);
	}
}


@keyframes sk-bounce {
	0%, 100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}

	50% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}


/* MODAL WINDOWS */

.white-popup-block {
	background: #FFF;
	padding: 1.5rem 2rem;
	text-align: left;
	max-width: 1000px;
	margin: 2.5rem auto;
	position: relative;
}

.slickModal {
	.close {
		opacity: 1;
	}
	&.newsletter {
		color: #fff;
		.wrapper {
			padding: 3rem;
			padding-bottom: 2.5rem;
		}
	}
	h3 {
		font-weight: 700;
		font-size: 1.6rem;
		margin-bottom: 1.2rem;
		text-align: left;
		color: #fff;
	}
	.white-modal h3 {
		color: #121212;
	}
	&.newsletter {
		p {
			font-size: 1.1rem;
			text-align: left;
		}
		.privacy {
			font-size: 0.9rem;
			float: left;
			color: #E1E1E1;
			margin-top: 1rem;
			&:hover {
				color: #fff;
			}
		}
		.checkbox {
			text-align: left;
			margin-bottom: 0 !important;
			label {
				font-weight: 300;
				font-size: 0.85;
			}
		}
	}
	.form-group {
		margin-bottom: 15px;
	}
	&.newsletter .close.icon {
		&:after, &:before {
			background: #fff;
		}
	}
	.white-modal {
		background: #fff;
	}
	.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
		position: relative;
		min-height: 1px;
		padding-right: 15px;
		padding-left: 15px;
	}
	.row {
		margin-right: -15px;
		margin-left: -15px;
	}
	.form-group {
		margin-bottom: 15px;
		label {
			font-weight: 600;
			font-size: 0.8rem;
			margin-bottom: 5px;
			font-weight: 700;
		}
	}
	.checkbox {
		margin: 2rem 0;
	}
	.map {
		height: 100%;
	}
}

.cookieNotice {
	background: rgba(0, 0, 0, 0.8);
	color: #fff;
	text-align: left;
	width: 100%;
	height: 90px;
	padding: 24px;
	h3 {
		font-weight: 900;
		padding-bottom: 6px;
		font-size: 18px;
		margin-bottom: 0;
	}
	p {
		color: #fff;
		text-align: left;
	}
	a {
		color: #fff;
		text-align: left;
		text-decoration: underline;
	}
	label {
		position: absolute;
		right: 30px;
		top: 30px;
		background: url(../img/various/down.png) 0 0 no-repeat;
		width: 32px;
		height: 32px;
		content: "";
		opacity: .6;
		&:hover {
			opacity: 1;
		}
	}
}

@media (max-width: 1000px) {
	.slickModal .window.modal-lg {
		width: 90%;
	}
}

@media (max-width: 1000px) {
	.slickModal .window.modal-lg {
		width: 90%;
	}
}

@media (max-width: 610px) {
	.slickModal .window {
		&.modal-lg {
			width: 96% !important;
		}
		width: 100% !important;
	}
}

/* Magnific Popup */

.mfp-close {
	cursor: pointer;
	width: 44px;
	height: 38px;
	line-height: 38px;
	position: absolute;
	right: 0;
	top: 0;
	text-decoration: none;
	text-align: center;
	opacity: .65;
	filter: alpha(opacity = 65);
	padding: 0 0 18px 10px;
	color: #333;
	font-style: normal;
	font-size: 20px;
}

.mfp-bottom-bar {
	margin-top: 0;
}

.mfp-counter {
	display: none;
}

.mfp-iframe-holder .mfp-close, .mfp-image-holder .mfp-close {
	top: 10px;
	right: -6px;
}

.mfp-content figcaption {
	margin-top: 18px;
}

.mfp-zoom-in {
	.mfp-with-anim {
		opacity: 0;
		transition: all .2s ease-in-out;
		transform: scale(0.8);
	}
	&.mfp-bg {
		opacity: 0;
		transition: all .3s ease-out;
	}
	&.mfp-ready {
		.mfp-with-anim {
			opacity: 1;
			transform: scale(1);
		}
		&.mfp-bg {
			opacity: .8;
		}
	}
	&.mfp-removing {
		.mfp-with-anim {
			transform: scale(0.8);
			opacity: 0;
		}
		&.mfp-bg {
			opacity: 0;
		}
	}
}

.mfp-newspaper {
	.mfp-with-anim {
		opacity: 0;
		-webkit-transition: all .2s ease-in-out;
		transition: all .5s;
		transform: scale(0) rotate(500deg);
	}
	&.mfp-bg {
		opacity: 0;
		transition: all .5s;
	}
	&.mfp-ready {
		.mfp-with-anim {
			opacity: 1;
			transform: scale(1) rotate(0deg);
		}
		&.mfp-bg {
			opacity: .8;
		}
	}
	&.mfp-removing {
		.mfp-with-anim {
			transform: scale(0) rotate(500deg);
			opacity: 0;
		}
		&.mfp-bg {
			opacity: 0;
		}
	}
}

.mfp-move-horizontal {
	.mfp-with-anim {
		opacity: 0;
		transition: all .3s;
		transform: translateX(-50px);
		-webkit-transition-delay: 100ms;
		-moz-transition-delay: 100ms;
		-o-transition-delay: 100ms;
		transition-delay: 100ms;
	}
	&.mfp-bg {
		opacity: 0;
		transition: all .3s;
	}
	&.mfp-ready {
		.mfp-with-anim {
			opacity: 1;
			transform: translateX(0);
			-webkit-transition-delay: 0;
			-moz-transition-delay: 0;
			-o-transition-delay: 0;
			transition-delay: 0;
		}
		&.mfp-bg {
			opacity: .8;
		}
	}
	&.mfp-removing {
		.mfp-with-anim {
			transform: translateX(50px);
			opacity: 0;
		}
		&.mfp-bg {
			opacity: 0;
		}
	}
}

.mfp-move-from-top {
	.mfp-content {
		vertical-align: top;
	}
	.mfp-with-anim {
		opacity: 0;
		transition: all .2s;
		transform: translateY(-100px);
	}
	&.mfp-bg {
		opacity: 0;
		transition: all .2s;
	}
	&.mfp-ready {
		.mfp-with-anim {
			opacity: 1;
			transform: translateY(0);
		}
		&.mfp-bg {
			opacity: .8;
		}
	}
	&.mfp-removing {
		.mfp-with-anim {
			transform: translateY(-50px);
			opacity: 0;
		}
		&.mfp-bg {
			opacity: 0;
		}
	}
}

.mfp-3d-unfold {
	.mfp-content {
		perspective: 2000px;
	}
	.mfp-with-anim {
		opacity: 0;
		transition: all .3s ease-in-out;
		transform-style: preserve-3d;
		transform: rotateY(-60deg);
	}
	&.mfp-bg {
		opacity: 0;
		transition: all .5s;
	}
	&.mfp-ready {
		.mfp-with-anim {
			opacity: 1;
			transform: rotateY(0deg);
		}
		&.mfp-bg {
			opacity: .8;
		}
	}
	&.mfp-removing {
		.mfp-with-anim {
			transform: rotateY(60deg);
			opacity: 0;
		}
		&.mfp-bg {
			opacity: 0;
		}
	}
}

.mfp-zoom-out {
	.mfp-with-anim {
		opacity: 0;
		transition: all .3s ease-in-out;
		transform: scale(1.3);
	}
	&.mfp-bg {
		opacity: 0;
		transition: all .3s ease-out;
	}
	&.mfp-ready {
		.mfp-with-anim {
			opacity: 1;
			transform: scale(1);
		}
		&.mfp-bg {
			opacity: .8;
		}
	}
	&.mfp-removing {
		.mfp-with-anim {
			transform: scale(1.3);
			opacity: 0;
		}
		&.mfp-bg {
			opacity: 0;
		}
	}
}

@keyframes hinge {
	0% {
		transform: rotate(0);
		transform-origin: top left;
		animation-timing-function: ease-in-out;
	}

	20%,60% {
		transform: rotate(80deg);
		transform-origin: top left;
		animation-timing-function: ease-in-out;
	}

	40% {
		transform: rotate(60deg);
		transform-origin: top left;
		animation-timing-function: ease-in-out;
	}

	80% {
		transform: rotate(60deg) translateY(0);
		opacity: 1;
		transform-origin: top left;
		animation-timing-function: ease-in-out;
	}

	100% {
		transform: translateY(700px);
		opacity: 0;
	}
}


.hinge {
	animation-duration: 1s;
	animation-name: hinge;
}

.mfp-with-fade {
	.mfp-content, &.mfp-bg {
		opacity: 0;
		transition: opacity .5s ease-out;
	}
	&.mfp-ready {
		.mfp-content {
			opacity: 1;
		}
		&.mfp-bg {
			opacity: .8;
		}
	}
	&.mfp-removing.mfp-bg {
		opacity: 0;
	}
}

/* Tables */

table {
	background: #F9F9F9;
	text-align: center;
	color: #373a3c;
}

.table > {
	tbody > tr > {
		td, th {
			border-top: none;
		}
	}
	tfoot > tr > {
		td, th {
			border-top: none;
		}
	}
	thead > tr > {
		td, th {
			border-top: none;
		}
	}
}

table {
	tr, td {
		z-index: 2;
	}
	th {
		position: relative;
	}
	tr, td {
		position: static;
	}
}

.table > {
	thead > tr > th {
		&:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			border-bottom: 2px solid rgba(0, 0, 0, 0.2);
		}
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: 0.9rem;
		border-bottom: none;
		small {
			display: block;
			text-transform: none;
			color: #929292;
			font-weight: normal;
			margin-top: 0.3rem;
			font-weight: 0.85rem;
		}
	}
	tbody > tr > {
		td, th {
			padding: 10px 20px;
		}
	}
	tfoot > tr > {
		td, th {
			padding: 10px 20px;
		}
	}
	thead > tr > {
		td, th {
			padding: 10px 20px;
		}
	}
}

table {
	th.title-h {
		color: #fff;
		text-align: left !important;
	}
	td {
		&.title-h {
			color: #fff;
			text-align: left !important;
		}
		&.table-link a {
			display: block;
			font-weight: 600;
			z-index: 3;
		}
	}
}

.table-striped > {
	tbody > tr {
		&:nth-of-type(odd) {
			background: none;
			td:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(0, 0, 0, 0.06);
			}
		}
		> {
			td, th {
				border: none;
			}
		}
	}
	tfoot > tr > {
		td, th {
			border: none;
		}
	}
	thead > tr > td {
		border: none;
	}
}

.table-hover > tbody > tr:hover {
	background: none;
	td:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.06);
		z-index: -1;
	}
}

.table > {
	tbody > tr {
		&.success > {
			td, th {
				background-color: rgba(0, 0, 0, 0.08);
			}
		}
		> {
			td.success, th.success {
				background-color: rgba(0, 0, 0, 0.08);
			}
		}
	}
	tfoot > tr {
		&.success > {
			td, th {
				background-color: rgba(0, 0, 0, 0.08);
			}
		}
		> {
			td.success, th.success {
				background-color: rgba(0, 0, 0, 0.08);
			}
		}
	}
	thead > tr {
		&.success > {
			td, th {
				background-color: rgba(0, 0, 0, 0.08);
			}
		}
		> {
			td.success, th.success {
				background-color: rgba(0, 0, 0, 0.08);
			}
		}
	}
}

.table-dark {
	th:not(.title-h) {
		color: #fff;
		background: #343434;
	}
	td {
		&:not(.title-h) {
			color: #fff;
			background: #343434;
		}
		&.table-link a {
			color: #fff;
		}
	}
}

.table > tbody > tr:hover > .success {
	background-color: #d0e9c6;
}

.table-bordered {
	> {
		tbody > tr > {
			td, th {
				border: none;
			}
		}
		tfoot > tr > {
			td, th {
				border: none;
			}
		}
		thead > tr > {
			td, th {
				border: none;
			}
		}
	}
	tr > {
		th:after, td:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.15);
			border-right: 1px solid rgba(0, 0, 0, 0.15);
		}
	}
}

@media (max-width: 767px) {
	.table-responsive.table-dark {
		border: 1px solid rgba(255, 255, 255, 0.2);
	}
}

/* FORM SEARCH */
/* Off Top Search */

#search.top-search, .search.top-search {
	padding-left: 40px;
	padding-right: 40px;
	background: #262626;
	color: #fff;
	margin: 0;
	overflow: hidden;
	width: 100%;
	height: 0;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
	z-index: 300;
}

#search.top-search .container, .search.top-search .container {
	position: relative;
	padding-left: 0;
	padding-right: 0;
}

#search.top-search .search-inner, .search.top-search .search-inner {
	opacity: 0;
	margin-top: -1rem;
	position: relative;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

#search.top-search.search-open .search-inner, .search.top-search.search-open .search-inner {
	opacity: 1;
	margin-top: 0;
}

#search.top-search.search-open, .search.top-search.search-open {
	height: 50px;
}

#search.top-search input, .search.top-search input {
	position: absolute;
	width: 100%;
	padding: 0 0 0 10px;
	background-color: #262626;
	border: medium none;
	box-shadow: none;
	color: #262626;
	display: inline-block;
	font-size: 1rem;
	line-height: 20px;
	height: 20px;
	margin: 15px 0;
	float: left;
	-moz-transition: color 200ms ease-in-out;
	-o-transition: color 200ms ease-in-out;
	-webkit-transition: color 200ms ease-in-out;
	transition: color 200ms ease-in-out;
	text-transform: uppercase;
	letter-spacing: 1px;
	&:focus {
		position: absolute;
		width: 100%;
		padding: 0 0 0 10px;
		background-color: #262626;
		border: medium none;
		box-shadow: none;
		color: #262626;
		display: inline-block;
		font-size: 1rem;
		line-height: 20px;
		height: 20px;
		margin: 15px 0;
		float: left;
		-moz-transition: color 200ms ease-in-out;
		-o-transition: color 200ms ease-in-out;
		-webkit-transition: color 200ms ease-in-out;
		transition: color 200ms ease-in-out;
		text-transform: uppercase;
		letter-spacing: 1px;
	}
}

#search.top-search.search-open input, .search.top-search.search-open input {
	color: #A2A2A2;
	&:focus {
		color: #A2A2A2;
	}
}

#search.top-search input[type="submit"], .search.top-search input[type="submit"] {
	visibility: hidden;
	position: absolute;
}

#search.top-search i, .search.top-search i {
	font-size: 1rem;
	display: inline-block;
	float: left;
	padding: 0;
	line-height: 50px;
	-moz-transition: color 200ms ease-in-out;
	-o-transition: color 200ms ease-in-out;
	-webkit-transition: color 200ms ease-in-out;
	transition: color 200ms ease-in-out;
}

#search.top-search .search-close, .search.top-search .search-close {
	font-size: 13px;
	position: absolute;
	line-height: 50px;
	height: 50px;
	top: 0;
	right: 10px;
}

#search .container .search-close, .search .container .search-close {
	right: 20px;
}

#search.top-search .search-close i, .search.top-search .search-close i {
	color: #B2B2B2;
	-moz-transition: color 250ms ease-in-out;
	-o-transition: color 250ms ease-in-out;
	-webkit-transition: color 250ms ease-in-out;
	transition: color 250ms ease-in-out;
}

#search.top-search .search-close:hover i, .search.top-search .search-close:hover i {
	color: #F2F2F2;
}

#search.top-search button, .search.top-search button {
	opacity: 0;
}

.search-open {
	#main-navigation {
		.main-nav-wrapper {
			top: 50px;
		}
		top: 50px !important;
	}
	&.admin-bar #main-navigation {
		top: 82px !important;
	}
}

.admin-bar #search.top-search, .admin-bar .search.top-search {
	top: 32px !important;
}

@media (max-width: 975px) {
	#wp-admin-bar-top-secondary, #wp-admin-bar-customize, #wp-admin-bar-updates, #wp-admin-bar-new-content, #wp-admin-bar-edit, #wp-admin-bar-wpseo-menu, a.screen-reader-shortcut {
		display: none !important;
	}
}

@media (max-width: 782px) {
	#wpadminbar {
		height: 32px !important;
		.quicklinks {
			.ab-empty-item, > ul > li > a {
				height: 32px !important;
			}
		}
		#wp-admin-bar-wp-logo > .ab-item .ab-icon {
			height: 32px !important;
		}
		.quicklinks > ul > li > a {
			line-height: 32px !important;
		}
		#wp-admin-bar-wp-logo > .ab-item .ab-icon:before {
			font: (20px / 1) dashicons !important;
			top: -12px;
		}
		#wp-admin-bar-customize > .ab-item:before, #wp-admin-bar-edit > .ab-item:before, #wp-admin-bar-my-account > .ab-item:before, #wp-admin-bar-my-sites > .ab-item:before, #wp-admin-bar-site-name > .ab-item:before {
			font: 400 (25px / 1) dashicons !important;
			top: 5px;
		}
	}
}

/* Off Nav Search */

#search.nav-search, .search.nav-search {
	height: 60px;
	visibility: hidden;
	opacity: 0;
	padding-left: 40px;
	padding-right: 40px;
	background: #373737;
	color: #fff;
	margin: 0;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
	z-index: 300;
}

#search.nav-search .container, .search.nav-search .container {
	position: relative;
	padding-left: 0;
	padding-right: 0;
	height: 100%;
}

#search.nav-search.search-open, .search.nav-search.search-open {
	visibility: visible;
	opacity: 1;
}

#search.nav-search input, .search.nav-search input {
	line-height: 60px;
	height: 60px;
	width: 100%;
	padding: 0 0 0 10px;
	background-color: transparent;
	border: medium none;
	box-shadow: none;
	color: #262626;
	display: block;
	font-size: 2rem;
	margin: 0;
	float: left;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: 600;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
	&:focus {
		line-height: 60px;
		height: 60px;
		width: 100%;
		padding: 0 0 0 10px;
		background-color: transparent;
		border: medium none;
		box-shadow: none;
		color: #262626;
		display: block;
		font-size: 2rem;
		margin: 0;
		float: left;
		text-transform: uppercase;
		letter-spacing: 2px;
		font-weight: 600;
		-webkit-transition: all .4s ease-in-out;
		-moz-transition: all .4s ease-in-out;
		transition: all .4s ease-in-out;
	}
}

#search.nav-search.search-open input, .search.nav-search.search-open input {
	color: $gold;
	&:focus {
		color: $gold;
	}
}

#search.nav-search input[type="submit"], .search.nav-search input[type="submit"] {
	visibility: hidden;
	position: absolute;
}

#search.nav-search i, .search.nav-search i {
	line-height: 59px;
	font-size: 1.2rem;
	display: table;
	float: left;
	padding: 0;
	font-weight: 600;
	height: 100%;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

#search.nav-search .search-close, .search.nav-search .search-close {
	font-size: 18px;
	position: absolute;
	line-height: 100%;
	height: 100%;
	top: 0;
	right: 10px;
	display: block;
}

#search .container .search-close, .search .container .search-close {
	right: 20px;
}

#search.nav-search .search-close i, .search.nav-search .search-close i {
	color: #6D6D6D;
}

#search.nav-search .search-close:hover i, .search.nav-search .search-close:hover i {
	color: #565656;
}

#search input::-webkit-input-placeholder, .search input::-webkit-input-placeholder, #search input:-moz-placeholder, .search input:-moz-placeholder, #search input::-moz-placeholder, .search input::-moz-placeholder, #search input:-ms-input-placeholder, .search input:-ms-input-placeholder {
	color: #6B6B6B !important;
}

.topnav-top {
	#search.nav-search, .search.nav-search {
		background: transparent;
	}
	#search.nav-search input, .search.nav-search input, #search.nav-search, .search.nav-search {
		height: 90px;
	}
	#search.nav-search i, .search.nav-search i {
		line-height: 90px;
	}
	#search.nav-search, .search.nav-search {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}
}

.search-nav-open #main-navigation {
	opacity: 0;
}

/* Off Fullscreen Search */

.search-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	color: $midGrey;
	background: rgba(255, 255, 255, 0.9);
	z-index: 2000;
	.search-overlay-close {
		position: absolute;
		font-size: 2rem;
		right: 20px;
		top: 20px;
		color: #C3C3C3;
		z-index: 100;
	}
	visibility: hidden;
	opacity: 0;
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s, visibility 0s 0.3s;
	transition: transform 0.3s, opacity 0.3s, visibility 0s 0.3s;
	&.open {
		visibility: visible;
		opacity: 1;
		-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
		transition: opacity 0.3s, transform 0.3s;
	}
	form {
		width: 60%;
		height: 160px;
		margin: 0 auto;
		position: absolute;
		left: 20%;
		top: 50%;
		margin-top: -80px;
		-webkit-transform: translateY(20px);
		transform: translateY(20px);
		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}
	&.open form {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		i {
			color: #C6C6C6 !important;
		}
	}
	form {
		input {
			font-size: 5rem;
			font-weight: 300;
			width: 100%;
			height: 100%;
			padding: 0 10% 0 10px;
			outline: none !important;
			background: transparent;
			color: #fff;
			border: none !important;
			border-bottom: 1px solid #909090 !important;
			&:focus {
				font-size: 5rem;
				font-weight: 300;
				width: 100%;
				height: 100%;
				padding: 0 10% 0 10px;
				outline: none !important;
				background: transparent;
				color: #fff;
				border: none !important;
				border-bottom: 1px solid #909090 !important;
			}
		}
		button {
			position: absolute;
			width: 80px;
			font-size: 3rem;
			height: 80px;
			right: 0;
			top: 55px;
			background: none;
			border: none;
			background: none;
		}
	}
	&.overlay-dark {
		color: #C6C6C6;
		background: rgba(0, 0, 0, 0.9);
		.search-overlay-close {
			color: #C3C3C3;
		}
	}
}

@media (max-width: 992px) {
	.search-overlay form {
		height: 80px;
		margin-top: -40px;
		input {
			font-size: 2.5rem;
			&:focus {
				font-size: 2.5rem;
			}
		}
		button {
			font-size: 2.2rem;
			height: 40px;
			width: 40px;
			top: 28px;
		}
	}
}

@media (max-width: 500px) {
	.search-overlay {
		.search-overlay-close {
			font-size: 1.3rem;
		}
		form {
			height: 50px;
			margin-top: -25px;
			input {
				font-size: 1.4rem;
				&:focus {
					font-size: 1.4rem;
				}
			}
			button {
				font-size: 1.5rem;
				height: 30px;
				width: 30px;
				top: 15px;
			}
		}
	}
}

/* FULLPAGE */

html.fp-enabled, .fp-enabled body {
	margin: 0;
	padding: 0;
	overflow: hidden;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#superContainer {
	height: 100%;
	position: relative;
	-ms-touch-action: none;
	touch-action: none;
}

.fp-section {
	position: relative;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.fp-slide {
	float: left;
	height: 100%;
	display: block;
}

.fp-slidesContainer {
	height: 100%;
	display: block;
}

.fp-slides {
	z-index: 1;
	height: 100%;
	overflow: hidden;
	position: relative;
	-webkit-transition: all .3s ease-out;
	transition: all .3s ease-out;
}

.fp-section.fp-table, .fp-slide.fp-table {
	display: table;
	table-layout: fixed;
	width: 100%;
}

.fp-tableCell {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	height: 100%;
}

.fp-slidesContainer {
	float: left;
	position: relative;
}

.fp-controlArrow {
	position: absolute;
	z-index: 4;
	top: 50%;
	cursor: pointer;
	width: 0;
	height: 0;
	border-style: solid;
	margin-top: -38px;
	-webkit-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	&.fp-prev {
		left: 15px;
		width: 0;
		border-width: 38.5px 34px 38.5px 0;
		border-color: transparent #fff transparent transparent;
	}
	&.fp-next {
		right: 15px;
		border-width: 38.5px 0 38.5px 34px;
		border-color: transparent transparent transparent #fff;
	}
}

.fp-scrollable {
	overflow: scroll;
}

.fp-notransition {
	-webkit-transition: none !important;
	transition: none !important;
}

#fp-nav {
	&.right {
		right: 17px;
	}
	&.left {
		left: 17px;
	}
}

.fp-slidesNav {
	position: absolute;
	z-index: 4;
	left: 50%;
	opacity: 1;
	&.bottom {
		bottom: 17px;
	}
	&.top {
		top: 17px;
	}
}

#fp-nav ul, .fp-slidesNav ul {
	margin: 0;
	padding: 0;
}

#fp-nav ul li {
	display: block;
	width: 14px;
	height: 13px;
	margin: 7px;
	position: relative;
}

.fp-slidesNav ul li {
	display: block;
	width: 14px;
	height: 13px;
	margin: 7px;
	position: relative;
	display: inline-block;
}

#fp-nav ul li a, .fp-slidesNav ul li a {
	display: block;
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	cursor: pointer;
	text-decoration: none;
}

#fp-nav ul li a.active span, .fp-slidesNav ul li a.active span, #fp-nav ul li:hover a.active span, .fp-slidesNav ul li:hover a.active span {
	height: 12px;
	width: 12px;
	margin: -6px 0 0 -6px;
	border-radius: 100%;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
	border-radius: 50%;
	position: absolute;
	z-index: 1;
	height: 4px;
	width: 4px;
	border: 0;
	background: #333;
	left: 50%;
	top: 50%;
	margin: -2px 0 0 -2px;
	-webkit-transition: all .1s ease-in-out;
	-moz-transition: all .1s ease-in-out;
	-o-transition: all .1s ease-in-out;
	transition: all .1s ease-in-out;
}

#fp-nav ul li:hover a span, .fp-slidesNav ul li:hover a span {
	width: 10px;
	height: 10px;
	margin: -5px 0 0 -5px;
}

#fp-nav {
	ul li {
		.fp-tooltip {
			position: absolute;
			top: -2px;
			color: #fff;
			font-size: 14px;
			white-space: nowrap;
			max-width: 220px;
			overflow: hidden;
			display: block;
			opacity: 0;
			width: 0;
		}
		&:hover .fp-tooltip {
			-webkit-transition: opacity .2s ease-in;
			transition: opacity .2s ease-in;
			width: auto;
			opacity: 1;
		}
	}
	&.fp-show-active a.active + .fp-tooltip {
		-webkit-transition: opacity .2s ease-in;
		transition: opacity .2s ease-in;
		width: auto;
		opacity: 1;
	}
	ul li .fp-tooltip {
		&.right {
			right: 20px;
		}
		&.left {
			left: 20px;
		}
	}
}

.fp-auto-height {
	&.fp-section, .fp-slide, .fp-tableCell {
		height: auto !important;
	}
}

#fp-nav {
	position: fixed;
	z-index: 100;
	margin-top: -32px;
	top: 50%;
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	left: 10px;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	ul li {
		.fp-tooltip {
			color: $midGrey;
			width: auto;
		}
		margin-bottom: 25px;
	}
}

.fp-slidesNav ul li {
	margin-bottom: 25px;
}

#fp-nav {
	ul li {
		.fp-tooltip {
			&.left {
				left: 30px;
				-webkit-transition: all 0.3s;
				transition: all .3s;
			}
			top: -8px;
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-radius: 0;
			padding: 3px 10px;
			font-size: 0.9rem;
			line-height: 18px;
		}
		&:hover .fp-tooltip {
			left: 20px;
		}
	}
	&.fp-show-active a.active + .fp-tooltip {
		left: 20px;
	}
	ul li a span {
		background: #232325;
	}
}

.fp-slidesNav ul li a span {
	background: #232325;
}

#fp-nav ul li a:not(.active) span, .fp-slidesNav ul li a:not(.active) span {
	width: 12px;
	height: 12px;
	margin: -6px 0 0 -6px;
}

#fp-nav.nav-light {
	color: #EEEEEE;
	ul li {
		.fp-tooltip {
			border: 1px solid rgba(255, 255, 255, 0.1);
			color: #E7E7E7;
		}
		a {
			span {
				background: transparent;
			}
			&.active span {
				box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.3);
			}
			&:not(.active) span {
				box-shadow: inset 0 0 0 6px rgba(255, 255, 255, 1);
			}
		}
	}
}

.fp-slidesNav ul li a:not(.active) span {
	box-shadow: inset 0 0 0 6px rgba(255, 255, 255, 1);
}

#fp-nav.nav-light ul li a:not(.active) span:hover {
	box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.3);
}

@media (max-width: 768px) {
	.fp-tableCell {
		padding-left: 2.2rem;
	}
}

/* SPLIT SCREEN ON SCROLL */

body.split-screen {
	background: #fff !important;
}

.split-screen #main-content {
	overflow: visible !important;
}

body.split-screen #main-navigation > .main-nav-wrapper {
	border-bottom: none !important;
}

.split-section {
	position: relative;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0 18%;
	min-height: 500px;
	&.split-no-padding {
		padding: 0;
		.img-cover {
			background-position: center;
		}
	}
	&.ms-table {
		display: table;
		width: 100%;
	}
}

.ms-tableCell {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	height: 100%;
}

.ms-easing {
	-webkit-transition: all .7s ease-out;
	-moz-transition: all .7s ease-out;
	-o-transition: all .7s ease-out;
	transition: all .7s ease-out;
}

#multiscroll-nav {
	position: fixed;
	z-index: 100;
	margin-top: -32px;
	top: 50%;
	opacity: 1;
	&.right {
		right: 17px;
	}
	&.left {
		left: 17px;
	}
	ul {
		margin: 0;
		padding: 0;
	}
	li {
		display: block;
		width: 14px;
		height: 13px;
		margin: 7px;
		margin-bottom: 25px;
		position: relative;
		a {
			display: block;
			position: relative;
			z-index: 1;
			width: 100%;
			height: 100%;
			cursor: pointer;
			text-decoration: none;
			span {
				webkit-transition: all .1s ease-in-out;
				-moz-transition: all .1s ease-in-out;
				-o-transition: all .1s ease-in-out;
				transition: all .1s ease-in-out;
			}
			&.active span {
				box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.3);
			}
			&:not(.active) span {
				box-shadow: inset 0 0 0 6px rgba(255, 255, 255, 1);
				&:hover {
					box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.3);
				}
			}
		}
	}
	span {
		top: 2px;
		left: 2px;
		width: 12px;
		height: 12px;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		position: absolute;
		z-index: 1;
	}
}

.multiscroll-tooltip {
	position: absolute;
	color: #fff;
	font-size: 14px;
	top: -2px;
	white-space: nowrap;
	max-width: 220px;
	&.right {
		right: 20px;
	}
	&.left {
		left: 20px;
	}
}

.split-section .grid {
	&.grid-3 .item, &.grid-2 .item {
		height: auto;
	}
}

.split-logo, .title .split-logo {
	max-width: 220px;
	display: block;
	text-align: center;
	margin: 1rem auto;
}

.split-left, .split-right {
	touch-action: initial !important;
}

.split-section .title i {
	font-size: 3.2rem;
}

.split-screen {
	#main-menu li.active a {
		color: $gold !important;
	}
	.flexslider {
		margin-bottom: 0;
	}
}

.split-section p {
	font-size: 1.1rem;
	font-weight: 100;
}

.split-section-wrapper > .title {
	padding-top: 0 !important;
}

.split-section .flexslider .dis-table {
	padding: 0 18%;
}

.split-no-padding {
	.row {
		margin-left: 0;
		margin-right: 0;
	}
	[class*="col-"] {
		padding-left: 0;
		padding-right: 0;
	}
}

#multiscroll-nav.nav-dark li a {
	&.active span {
		box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.3);
	}
	&:not(.active) span {
		box-shadow: inset 0 0 0 6px rgba(0, 0, 0, 1);
		&:hover {
			box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.3);
		}
	}
}

.split-section {
	.fullscreen {
		position: absolute;
		width: 200%;
		margin-top: -100px;
		left: 0;
		z-index: 1;
		vertical-align: middle;
		text-align: center;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	.title .subtitle {
		line-height: 1.5;
	}
	.section-overlay {
		z-index: 0;
	}
}

@media (min-width: 992px) {
	html.split-bordered {
		padding: 40px 30px;
		background: #fff;
		#logo {
			display: none;
		}
		&::before, &::after {
			content: "";
			height: 40px;
			left: 0;
			right: 0;
			position: fixed;
			background: #fff;
			width: 100%;
		}
		&::before {
			top: 0;
		}
		&::after {
			bottom: 0;
		}
		.footer-reveal {
			#footer {
				left: 30px;
				right: 30px;
				bottom: 30px;
			}
			#main-content {
				-moz-box-shadow: none;
				-webkit-box-shadow: none;
				box-shadow: none;
			}
		}
		.container-fluid {
			background-repeat: no-repeat;
			background-position: center center;
			padding-right: 0;
			padding-left: 0;
			margin-left: -30px;
			margin-right: -30px;
		}
		.split-screen #header {
			top: 1px;
			left: 10px;
		}
		.topnav-top {
			#main-navigation .main-nav-wrapper, #main-menu > ul > li > a {
				height: 40px;
				line-height: 40px;
			}
		}
		#multiscroll-nav {
			&.right {
				right: 3px;
			}
			li a {
				&.active span {
					box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.3);
				}
				&:not(.active) span {
					box-shadow: inset 0 0 0 6px rgba(0, 0, 0, 1);
					&:hover {
						box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.3);
					}
				}
			}
		}
		#main-navigation {
			#main-menu > ul > li > a, .nav-right i, .tools-btn span {
				color: #121212 !important;
			}
			#main-menu > ul > li.active > a {
				color: $gold !important;
			}
		}
		.multiscroll-tooltip.right {
			right: 30px;
		}
		.split-left .split-section {
			width: calc(100% - 15px);
		}
		.split-right .split-section {
			width: calc(100% - 15px);
			float: right;
		}
		.flex-control-nav {
			bottom: 80px;
		}
		#main-menu {
			padding-left: 17px;
		}
	}
}

@media (max-width: 1200px) {
	html.split-bordered {
		padding: 30px 30px;
	}
}

@media (max-width: 992px) {
	body.split-screen, html {
		overflow: visible !important;
		height: auto !important;
	}
	body.split-screen #main-content {
		position: static;
		overflow: visible !important;
	}
	.split-section {
		&.ms-table:not(.split-no-padding) {
			padding: 4rem 2rem;
		}
		display: table;
		height: 100%;
		width: 100%;
	}
	.split-section-wrapper {
		display: table;
		vertical-align: middle;
		width: 100%;
	}
	.split-left, .split-right {
		touch-action: initial !important;
		width: 100% !important;
		position: static !important;
		height: auto !important;
		top: auto !important;
	}
	.ms-tableCell {
		height: auto !important;
	}
	#multiscroll-nav {
		display: none;
	}
	.split-section.ms-table {
		height: auto !important;
	}
	.split-section-wrapper {
		display: table-cell;
		vertical-align: middle;
		width: 100%;
		height: 100%;
	}
	.ms-tableCell .split-section-wrapper {
		display: block;
	}
	.split-left .split-section:first-child .split-section-wrapper {
		padding-top: 4rem;
	}
	.split-section {
		padding: 0 2rem;
	}
}

/* CALENDAR */

.page-calendar .add-category {
	display: inline-block;
	margin-top: 20px;
	text-decoration: none;
	i {
		padding-right: 8px;
	}
}

.fc-draggable.fc-event {
	border: none;
}

.external-event {
	cursor: move;
	margin: 10px 0;
	padding: 6px;
}

.fc-event {
	border-radius: 0;
	margin: 3px 6px;
	padding: 4px 8px;
}

.fc-state-default {
	background: #fff;
	box-shadow: none !important;
	color: #000;
	margin-right: -1px;
	outline: none !important;
	text-shadow: none;
	&:hover {
		background: #E4E4E4;
	}
	&.fc-corner-right {
		background-color: #fff;
		border-bottom-right-radius: 4px;
		border-top-right-radius: 4px;
		border: none;
		color: #111;
		&:hover {
			background: #E4E4E4;
		}
	}
}

.fc-toolbar {
	display: none;
	.fc-state-active, .ui-state-active {
		background: #319db5;
		box-shadow: none;
		color: #fff;
		cursor: default;
		margin-right: -1px;
	}
}

.fc-state-default {
	&.fc-corner-right.fc-state-active {
		background: #319db5;
		box-shadow: none;
		color: #fff;
		cursor: default;
		margin-right: -1px;
	}
	&.fc-state-disabled {
		background-color: #ccc;
		border-bottom-right-radius: 4px;
		border-top-right-radius: 4px;
		color: #111;
	}
}

.fc {
	.fc-toolbar > :first-child {
		border: none;
	}
	.fc-button-group {
		> * {
			border: none;
		}
		.fc-prev-button, .fc-next-button {
			background: #2b2e33;
			color: #fff;
		}
	}
}

.fc-state-default.fc-corner-right.fc-state-disabled {
	background: #C8C8C8;
}

.fc-unthemed {
	.fc-today, .fc-future {
		background: #fff;
	}
}

.fc-basic-view td.fc-day-number {
	padding-right: 8px;
	padding-top: 8px;
	&.fc-past {
		filter: alpha(opacity = 30);
		opacity: .3;
	}
}

.fc th {
	border-color: transparent !important;
	border-width: 0;
	border: none;
	background: #fff;
}

.fc-unthemed {
	thead, .fc-row {
		border-color: transparent !important;
		border-width: 0;
		border: none;
		background: #fff;
	}
}

thead {
	.fc-widget-header {
		border-color: transparent !important;
		border-width: 0;
		border: none;
		background: #fff;
	}
	.fc-head-container {
		padding-top: 1rem;
		padding-bottom: 0.8rem;
		background: #fff;
	}
}

.fc-time-grid .fc-slats td {
	height: 4.5rem;
	text-align: center;
}

.fc-event {
	border: none;
}

.fc-time-grid-event .fc-title {
	font-size: 1rem;
	text-transform: uppercase;
	letter-spacing: 1px;
	padding-top: 1.1rem;
}

.fc-v-event .fc-time {
	display: none;
}

@media (max-width: 768px) {
	.fc-time-grid-event .fc-title {
		font-size: 0.8rem;
	}
}

/* SERIF DESIGN */

.serif-design {
	.title h1, .tp-caption-subtitle {
		text-transform: none !important;
		font-weight: 300 !important;
	}
	.counter .count-subtitle {
		font-size: 1.1rem;
	}
	.text-element .title p, .title .subtitle {
		font-size: 1.3rem;
		line-height: 1.4rem;
	}
	.section-call-to-action p.action-subtitle {
		font-size: 1.4rem;
	}
	.feature-box .feature-content p.subtitle, .team .team-info .team-job {
		font-size: 1.2rem;
	}
}

/* CREATIVE DESIGN */

.creative-design {
	.title h1, .tp-caption-subtitle {
		text-transform: none !important;
		font-weight: 300 !important;
	}
	.title p {
		font-size: 1.3rem;
	}
	.btn {
		text-transform: uppercase;
		letter-spacing: 1px;
	}
	.tp-caption-title {
		text-transform: none;
	}
}

/* SIDEBAR CONTENT */

.section-page {
	> {
		.row > [class*="col-"], .container > .row > [class*="col-"], .container-fluid > .row > [class*="col-"], .container-large > .row > [class*="col-"] {
			padding-top: 0;
		}
		.row {
			margin-left: 0;
			margin-right: 0;
		}
	}
	#sidebar-left, #sidebar-right, .sidebar-left, .sidebar-right {
		padding-left: 0;
		padding-right: 0;
	}
	#page-content, .page-content {
		padding: 0 3rem;
		background: #fff;
		z-index: 5;
	}
}

.section-dl {
	.btn-lg {
		margin-top:30px;
	}
}

#sidebar-left, #sidebar-right, .sidebar-left, .sidebar-right {
	z-index: 3;
}

.sidebar-left ~ .sidebar-left {
	z-index: 2;
}

#sidebar-left .sidebar-wrapper, #sidebar-right .sidebar-wrapper, .sidebar-left .sidebar-wrapper, .sidebar-right .sidebar-wrapper {
	padding: 3.6rem;
}

.container {
	#sidebar-right, #sidebar-left, .sidebar-right, .sidebar-left {
		padding-left: 0;
		padding-right: 0;
	}
	#sidebar-right .sidebar-wrapper, .sidebar-right .sidebar-wrapper {
		padding-right: 0;
		padding-left: 3rem;
	}
	#sidebar-left .sidebar-wrapper, .sidebar-left .sidebar-wrapper {
		padding-left: 0;
		padding-right: 3rem;
	}
}

#sidebar-left:after, #sidebar-right:after, .sidebar-left:after, .sidebar-right:after {
	background: #F3F3F3;
	z-index: -1;
	content: "";
	width: 1200px;
	height: 100%;
	position: absolute;
	top: 0;
	display: block;
}

#sidebar-left:after, .sidebar-left:after {
	right: 0;
}

#sidebar-right:after, .sidebar-right:after {
	left: 0;
}

.break-section {
	background:$bgGrey;
}

@media (max-width: 1400px) {
	#sidebar-left .sidebar-wrapper, #sidebar-right .sidebar-wrapper, .sidebar-left .sidebar-wrapper, .sidebar-right .sidebar-wrapper {
		padding: 3.6rem 1.6rem 1.6rem 1.6rem;
	}
}

@media (max-width: 1200px) {
	.sidebar-left:not(.col-lg-4), .sidebar-right:not(.col-lg-4) {
		min-height: 0 !important;
		margin-top: 30px;
	}
	.sidebar-left:not(.col-lg-4) {
		~ .sidebar-left {
			margin-right: -20px;
		}
		&:after {
			min-height: 0;
		}
	}
	.sidebar-right:not(.col-lg-4):after {
		min-height: 0;
		left: -30px;
	}
	.sidebar-left:not(.col-lg-4):after {
		right: -30px;
	}
}

@media (max-width: 992px) {
	.sidebar-left, .sidebar-right {
		min-height: 0 !important;
	}
	.sidebar-left {
		~ .sidebar-left {
			margin-right: -20px;
		}
		&:after {
			min-height: 0;
		}
	}
	.sidebar-right:after {
		min-height: 0;
		left: -30px;
	}
	.sidebar-left:after {
		right: -30px;
	}
}

/* CHAT INTERCOM STYLE: DEMO PURPOSE */
@media (min-width: 768px) {
	#intercom-container {
		#intercom-launcher {
			bottom: 62px !important;
			right: 12px !important;
		}
		.intercom-launcher-button {
			background-color: #2E2E2E !important;
			width: 38px !important;
			height: 38px !important;
			box-shadow: none !important;
			background-size: 16px 15px !important;
			border: none !important;
		}
	}
}

@media (max-width: 768px) {
	#intercom-container {
		display: none !important;
	}
}

.admin-bar #main-navigation {
	top: 32px !important;
}


body.hasbraz {
	overflow:hidden !important;
	@import 'https://fonts.googleapis.com/css?family=Parisienne';
}
#braz_overlay {
	width:100%;
	height:120%;
	left:0;
	right:0;
	bottom:0;
	top:0;
	position:fixed;
	display:none;
	background: #000 url("../../images/stage.jpg") no-repeat scroll center top;
	z-index:999999;
	overflow:scroll !important;
	cursor:pointer;
}
#braz_content {
	width:50%;
	height:885px;
	overflow:hidden;
	position:absolute;
	top:80px;
	left:50%;
	margin-left:-25%;
	text-align:center;
	h1 {
		text-align:center;
		font-size:100px;
		font-family: 'Parisienne', cursive;
		color:#ffffff;
		position:relative;
		font-weight:100;
		text-shadow:3px 4px 8px #c60c79, -4px -3px 8px #c60c79, 6px -4px 8px #c60c79;
		margin-top:30px;
	}
	#braz_img {
		opacity: 0;
    	visibility: hidden;
		margin:0 auto;
		margin-top:15px;
		&.visible {
			opacity: 1;
    		visibility: visible;
		}
	}
	#braz_tv_container {
		width:344px;
		height:364px;
		display:block;
		position:absolute;
		left:50%;
		margin-left:-172px;
		bottom:0;
		opacity: 0;
    	visibility: hidden;
		&.visible {
			opacity: 1;
    		visibility: visible;
		}
		img#braz_tv {
			position:absolute;
			z-index:10;
			top:0;
			width:344px;
			height:364px;
			left:0;
		}
		img#braz_dance {
			margin-left: 35px;
		    position: absolute;
		    top: 30px;
		    width: 225px;
		    z-index: 9;
		    left:0;
		}
	}
	
}
body.error404 {
	background:#1e1e1e;
	.nf-bg {
		background-size:auto;
		background-repeat:no-repeat;
		height:691px;
	}

	#wrapper {
		background:transparent !important;
		#main-content {
			-webkit-box-shadow: none !important;
			-moz-box-shadow: none !important;
			box-shadow: none !important;
		}
	}
}

@media (max-width: 1200px) {
	body.error404 {
		.nf-bg {
			background-position:-200px 0;
		}

	}
}
@media (max-width: 960px) {
	body.error404 {
		.nf-bg {
			background-position:-400px 0;
		}

	}
}
@media (max-width: 768px) {
	body.error404 {
		.nf-bg {
			background-position:-600px 0;
		}
	}
}
@media (max-width: 560px) {
	body.error404 {
		.nf-bg {
			background-position:-800px 0;
		}

	}
}

.select2-results__option {
	padding:0;
}
.select2-container--default .select2-selection--single {
	min-width:77px;
}

@media (max-width: 535px) {
	#intervention .wrap_inte .title h3 {
		padding-right:0 !important;
	}
}