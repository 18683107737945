/*!
FORMS
*/

.form-control {
  font-weight: normal;
  padding: 9px 12px;
  height: 34px;
  font-size: 1rem;
  border: 2px solid #EDEDED;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

textarea.form-control {
  height: auto;
  border:0;
}

.form-control {
  &:focus {
    border-color: #E2785D;
  }
  &[disabled] {
    background: #E8E8E8;
  }
}

.form-inline .btn {
  margin-bottom: 0;
}

.form-group {
  position: relative;
  &.row {
    margin-bottom: 0;
    > [class*=col-] {
      margin-bottom: 15px;
    }
  }
  label {
    color: #3B3B3B;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    padding-right: 6px;
  }
  small {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 700;
  }
}

.required:after {
  font-size: 1.5rem;
  color: #CECECE;
  content: "*";
  position: absolute;
  right: 1rem;
  top: 0;
}

.form-group.required:after {
  right: 0;
}

input {
  &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
    color: #C0C0C0 !important;
  }
}

textarea {
  &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
    color: #C0C0C0 !important;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.form-group.form-grouped {
  border: 2px solid #EDEDED;
  overflow: hidden;
  padding: 6px 11px 5px 11px;
  background: #fff;
  &.focused {
    border-color: #E2785D;
  }
  &.disabled {
    background: #E8E8E8;
    color: rgba(0, 0, 0, 0.2);
    input {
      &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
        color: #CDCDCD !important;
      }
    }
  }
  input {
    background: none;
    border: none;
    padding: 0;
    height: 25px;
    line-height: 25px;
  }
  label {
    margin-bottom: 0;
  }
}

.form-control.white {
  border: 2px solid #fff;
  &:focus {
    border-color: #383737 !important;
    background: #fff;
  }
}

.input-lg {
  font-size: 1.1rem;
}

.input-group .form-control:focus {
  border-right: 1px solid #C9C9C9;
}

.input-group-btn .btn {
  margin-right: 0;
  margin-bottom: 0;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
  font-size: 0.9rem;
}

.has-error {
  .checkbox, .checkbox-inline, .control-label, .help-block, .radio, .radio-inline, &.checkbox label, &.checkbox-inline label, &.radio label, &.radio-inline label {
    color: #a94442;
  }
  &.form-group {
    border-color:#f09d9d;
  }
}

#msgSubmit {
  margin-bottom: 1rem;
  font-size:14px;
  text-align:center;
}

/* Append / Prepend Icon */

.append-icon, .prepend-icon {
  position: relative;
}

.append-icon {
  input {
    padding-right: 2.5rem !important;
  }
  i {
    color: rgba(0, 0, 0, 0.2);
    font-size: 1.1rem;
    font-weight: bold;
    height: 34px;
    line-height: 34px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 34px;
  }
}

.prepend-icon i {
  color: rgba(0, 0, 0, 0.2);
  font-size: 1.1rem;
  font-weight: bold;
  height: 34px;
  line-height: 34px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 34px;
}

.append-icon {
  .btn {
    color: rgba(0, 0, 0, 0.2);
    font-size: 1.1rem;
    font-weight: bold;
    height: 34px;
    line-height: 34px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 34px;
  }
  i, .btn {
    left: auto;
    right: 0;
  }
}

.prepend-icon {
  i {
    left: 0;
    right: auto;
  }
  input {
    padding-left: 2.5rem !important;
    &.input-lg {
      padding-left: 3rem !important;
    }
  }
}

.form-group.form-grouped {
  &.required:after {
    font-size: 1.5rem;
    color: #F4605C;
    content: "*";
    position: absolute;
    right: 1rem;
    top: 0.4rem;
  }
  .prepend-icon input {
    padding-left: 1.8rem;
  }
  .append-icon input {
    padding-right: 1.8rem;
  }
  .prepend-icon i, .append-icon i {
    height: 28px;
    line-height: 28px;
    top: auto;
    bottom: -3px;
  }
  .prepend-icon i {
    text-align: left;
  }
  .append-icon i {
    text-align: right;
  }
}

.append-icon {
  .btn {
    background: transparent !important;
    margin-right: 0;
    &:hover, &:focus, &:hover::before {
      background: transparent !important;
      margin-right: 0;
    }
    i {
      font-size: 1rem;
    }
  }
  .input-lg ~ i {
    font-size: 1.4rem;
    height: 45px;
    line-height: 47px;
    width: 45px;
  }
}

.prepend-icon .input-lg ~ i, .append-icon .input-lg ~ .btn i {
  font-size: 1.4rem;
  height: 45px;
  line-height: 47px;
  width: 45px;
}

/* Size */

.form-control {
  &.input-sm {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
  }
  &.input-lg {
    height: 46px;
    padding: 0 16px;
    font-size: 1.1rem;
    line-height: 46px;
  }
}

.form-group {
  &.form-grouped > {
    .input-sm {
      padding: 0;
      height: 16px;
      line-height: 16px;
    }
    .input-lg {
      height: 34px;
      line-height: 34px;
    }
  }
  .label-lg {
    font-size: 1rem;
  }
  .label-sm {
    color: rgba(0, 0, 0, 0.45);
    font-size: 0.95rem;
  }
}

/* Select Input */

.select2-container--default .select2-selection--single {
  height: 34px;
  border: 2px solid #EDEDED;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.select2-dropdown {
  padding: 9px 12px;
  border: 2px solid #E2785D;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.select2-container--default {
  .select2-search--dropdown .select2-search__field {
    border: 2px solid #EDEDED;
    background: #fff;
  }
  .select2-selection--multiple {
    border: 2px solid #EDEDED;
    height: auto;
    line-height: 19px;
    background: #fff;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
  }
  .select2-selection--single {
    .select2-selection__arrow {
      height: 34px;
      right: 3px;
    }
    .select2-selection__rendered {
      line-height: 32px;
    }
  }
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 23px;
}

.select2-search--dropdown {
  padding: 0 0 8px 0;
}

.select2-container--default {
  .select2-results__option--highlighted[aria-selected] {
    background-color: rgba(0, 0, 0, 0.08);
    color: #121212;
  }
  &.select2-container--open.select2-container--above {
    .select2-selection--single, .select2-selection--multiple {
      border: 2px solid #E2785D;
    }
  }
  &.select2-container--focus .select2-selection--multiple {
    border: 2px solid #E2785D;
  }
  .select2-selection--multiple {
    .select2-selection__choice {
      border-radius: 0;
      background: #E2785D;
      border: none;
      color: #fff;
      font-size: 85%;
    }
    .select2-selection__choice__remove {
      color: #fff;
      margin-right: 4px;
    }
  }
}

.form-grouped {
  .select2-container--default {
    .select2-selection--single, .select2-selection--multiple {
      border: none;
    }
  }
  &.form-grouped-select-2 {
    padding: 0;
    > label {
      display: block;
      padding: 7px 12px 0 12px;
    }
  }
  .select2-container {
    margin-left: -2px;
    border-width: 0;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding: 0 12px;
  }
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 12px;
  }
}

.select2-container--open {
  .select2-selection--single {
    border: 2px solid #E2785D;
  }
  .select2-dropdown--below {
    margin-top: -2px;
    border-top: none !important;
  }
}

.select2-selection--single.input-lg {
  height: 46px;
  line-height: 46px;
  padding: 0;
  .select2-selection__rendered {
    line-height: 44px;
    text-align: left;
  }
  .select2-selection__arrow {
    height: 44px;
  }
}

.form-grouped .select2-container--default.select2-container--open.select2-container--below {
  .select2-selection--single, .select2-selection--multiple {
    border-top: none;
  }
}

html .form-group .select2-container--default.select2-container--focus.select2-container--above .select2-selection--multiple {
  border-top: none !important;
}

.select2-dropdown.select2-dropdown--above {
  border-bottom: none !important;
}

html .form-group .select2-container--default {
  &.select2-container--open.select2-container--above .select2-selection--single {
    border-top: none !important;
  }
  &.select2-container--focus .select2-selection--multiple {
    border: none !important;
  }
}

/* Quantity selector */

.quantity {
  width: 75px;
  height: 50px;
  border: 2px solid #EDEDED;
  position: relative;
  -webkit-transition: border .3s;
  -moz-transition: border 0.3s;
  transition: border .3s;
  &:hover {
    border: 2px solid #343434;
  }
  .plus, .minus {
    line-height: 23px;
    border-left: 2px solid #EDEDED;
    color: #363636;
    width: 26px;
    text-align: center;
    font-size: 0.7rem;
    font-weight: 700;
    position: absolute;
    right: 0;
    display: block;
    height: 50%;
    cursor: pointer;
    z-index: 2;
    -webkit-transition: background .2s;
    -moz-transition: background 0.2s;
    transition: background .2s;
  }
  .plus:hover {
    background: #EDEDED;
  }
  .minus {
    &:hover {
      background: #EDEDED;
    }
    bottom: 0;
  }
  .plus {
    top: 0;
    border-bottom: 1px solid #e8e8e8;
  }
  input {
    width: 48px;
    height: 100%;
    border: none;
    text-align: center;
    font-weight: 600;
  }
}

input {
  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* Tags Input */

.bootstrap-tagsinput {
  border: 2px solid #EDEDED;
  border-radius: 0;
  box-shadow: none;
  padding: 3px 6px 5px 6px;
  width: 100%;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.focused .bootstrap-tagsinput {
  border: 2px solid #E2785D;
}

.form-grouped .bootstrap-tagsinput {
  border: none !important;
}

/* Label */

.label {
  border-radius: 0;
  font-size: 85%;
  padding: .3em .4em .45em .4em;
}

.label-primary {
  background-color: #E2785D;
}

/* Datepicker */

.datepicker table tr td.today {
  background-color: #E1E1E1;
  border-color: none;
  border-radius: 0;
  &:hover {
    background-color: #E1E1E1;
    border-color: none;
    border-radius: 0;
  }
  &.disabled {
    background-color: #E1E1E1;
    border-color: none;
    border-radius: 0;
    &:hover {
      background-color: #E1E1E1;
      border-color: none;
      border-radius: 0;
    }
  }
  &:hover, &.disabled:hover, &:focus, &:hover:focus {
    background-color: #EDEDED;
    border-color: none;
    border-radius: 0;
  }
  &.disabled {
    &:focus, &:hover:focus {
      background-color: #EDEDED;
      border-color: none;
      border-radius: 0;
    }
  }
  &:active, &:hover:active {
    background-color: #EDEDED;
    border-color: none;
    border-radius: 0;
  }
  &.disabled {
    &:active, &:hover:active {
      background-color: #EDEDED;
      border-color: none;
      border-radius: 0;
    }
  }
  &.active, &:hover.active {
    background-color: #EDEDED;
    border-color: none;
    border-radius: 0;
  }
  &.disabled {
    &.active, &:hover.active {
      background-color: #EDEDED;
      border-color: none;
      border-radius: 0;
    }
  }
}

.open .dropdown-toggle.datepicker table tr td.today {
  background-color: #EDEDED;
  border-color: none;
  border-radius: 0;
  &:hover {
    background-color: #EDEDED;
    border-color: none;
    border-radius: 0;
  }
  &.disabled {
    background-color: #EDEDED;
    border-color: none;
    border-radius: 0;
    &:hover {
      background-color: #EDEDED;
      border-color: none;
      border-radius: 0;
    }
  }
}

.datepicker table tr td.active {
  &:hover, &.disabled:hover, &:focus, &:hover:focus {
    background-color: #E2785D;
    border-color: none;
    border-radius: 0;
  }
  &.disabled {
    &:focus, &:hover:focus {
      background-color: #E2785D;
      border-color: none;
      border-radius: 0;
    }
  }
  &:active, &:hover:active {
    background-color: #E2785D;
    border-color: none;
    border-radius: 0;
  }
  &.disabled {
    &:active, &:hover:active {
      background-color: #E2785D;
      border-color: none;
      border-radius: 0;
    }
  }
  &.active, &:hover.active {
    background-color: #E2785D;
    border-color: none;
    border-radius: 0;
  }
  &.disabled {
    &.active, &:hover.active {
      background-color: #E2785D;
      border-color: none;
      border-radius: 0;
    }
  }
}

.open .dropdown-toggle.datepicker table tr td.active {
  background-color: #E2785D;
  border-color: none;
  border-radius: 0;
  &:hover {
    background-color: #E2785D;
    border-color: none;
    border-radius: 0;
  }
  &.disabled {
    background-color: #E2785D;
    border-color: none;
    border-radius: 0;
    &:hover {
      background-color: #E2785D;
      border-color: none;
      border-radius: 0;
    }
  }
}

.datepicker {
  thead tr th {
    &.prev, &.next {
      color: #E2785D;
    }
  }
  table tr {
    td, th {
      border-radius: 0;
    }
    td span.active {
      &:hover, &.disabled:hover, &:focus, &:hover:focus {
        background-color: #E2785D;
        border-color: #E2785D;
        text-shadow: none;
      }
      &.disabled {
        &:focus, &:hover:focus {
          background-color: #E2785D;
          border-color: #E2785D;
          text-shadow: none;
        }
      }
      &:active, &:hover:active {
        background-color: #E2785D;
        border-color: #E2785D;
        text-shadow: none;
      }
      &.disabled {
        &:active, &:hover:active {
          background-color: #E2785D;
          border-color: #E2785D;
          text-shadow: none;
        }
      }
      &.active, &:hover.active {
        background-color: #E2785D;
        border-color: #E2785D;
        text-shadow: none;
      }
      &.disabled {
        &.active, &:hover.active {
          background-color: #E2785D;
          border-color: #E2785D;
          text-shadow: none;
        }
      }
    }
  }
}

.open .dropdown-toggle.datepicker table tr td span.active {
  background-color: #E2785D;
  border-color: #E2785D;
  text-shadow: none;
  &:hover {
    background-color: #E2785D;
    border-color: #E2785D;
    text-shadow: none;
  }
  &.disabled {
    background-color: #E2785D;
    border-color: #E2785D;
    text-shadow: none;
    &:hover {
      background-color: #E2785D;
      border-color: #E2785D;
      text-shadow: none;
    }
  }
}

/* Radio & Checkbox */

.checkbox label, .radio label {
  font-weight: 700;
}

.form-list {
  margin-top: 16px;
  padding-left: 0;
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

.checkbox + .checkbox, .radio + .radio {
  margin-top: 0px;
}

.form-list {
  li {
    display: inline-block;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0 26px 16px 0;
    float: left;
  }
  label {
    margin-bottom: 0;
  }
}

/* Switcher */

.switcher {
  text-align: center;
  height: 40px;
  line-height: 40px;
  .fieldset {
    display: inline-block;
    position: relative;
    padding: 2px;
    border-radius: 50em;
    border: 1px solid rgba(255, 255, 255, 0.4);
    margin-bottom: 0;
  }
  input[type="radio"] {
    position: absolute;
    opacity: 0;
  }
  label {
    position: relative;
    z-index: 1;
    display: inline-block;
    float: left;
    width: 90px;
    height: 34px;
    line-height: 34px;
    cursor: pointer;
    font-size: 1rem;
    margin-bottom: 0;
    color: #FFF;
  }
  .switch {
    position: absolute;
    top: 2px;
    left: 2px;
    height: 34px;
    width: 90px;
    background-color: #E2785D;
    border-radius: 20px;
    -webkit-transition: -webkit-transform .5s,background .3s;
    -moz-transition: -moz-transform .5s,background .3s;
    transition: transform .5s,background .3s;
  }
  input[type="radio"]:checked + label {
    + .switch, &:nth-of-type(n) + .switch {
      -webkit-transform: translateX(90px);
      -moz-transform: translateX(90px);
      -ms-transform: translateX(90px);
      -o-transform: translateX(90px);
      transform: translateX(90px);
    }
  }
}

.no-js .switcher {
  display: none;
}

.switcher input {
  &[type="radio"] + label {
    &::before, &::after {
      display: none;
    }
    padding-left: 0;
    font-size: 0.82rem;
    font-weight: 600;
  }
  &[type="checkbox"] + label {
    padding-left: 0;
    font-size: 0.82rem;
    font-weight: 600;
  }
}

.nav-light .switcher {
  .fieldset {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  label {
    color: #121212;
  }
}

/* Register */

.form-register {
  .form-group {
    margin-bottom: 1.5rem;
  }
  .checkbox {
    margin: 0.8rem 0 2rem 0;
  }
}

@media (max-width: 768px) {
  textarea.form-control {
    max-height: 120px;
  }
}

@media (max-width: 768px) {
  .form-list li {
    display: block;
    float: none;
  }
}