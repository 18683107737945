/*!
NAVIGATION
*/

/* TOP NAVIGATION */

#header {
	position: absolute;
	width: 100%;
	z-index: 300;
	height: 60px;
	-webkit-transition: height .4s ease-in-out;
	-o-transition: height .4s  ease-in-out;
	transition: height .4s  ease-in-out;
}

.topnav-top #header {
	height: 75px;
}

#header.with-topbar, .topnav-top #header.with-topbar {
	height: 105px;
}

#main-navigation {
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	position: fixed;
	width: auto;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

.boxed #main-navigation {
	max-width: 1240px;
	margin: auto;
	left: 0;
}

body:not(.header-transparent):not(.left-nav):not(.header-dark) #main-navigation > .main-nav-wrapper {
	background: rgba(68, 68, 68, 0.16);
}

.header-scroll-transparent:not(.topnav-top) .main-nav-wrapper {
	background: none !important;
}

.header-light.header-scroll-dark {
	&.topnav-top #main-navigation > .main-nav-wrapper {
		background: $wh;
	}
	&:not(.topnav-top) #main-navigation > .main-nav-wrapper {
		background: transparent;
	}
}

.left-nav #main-navigation {
	background: none;
}

@media (min-width: 768px) {
	#topbar ~ #main-navigation {
		top: 45px;
	}
	.header-no-sticky #topbar ~ #main-navigation {
		top: 0;
	}
}

#main-navigation .main-nav-wrapper {
	height: 60px;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

.topnav-top #main-navigation .main-nav-wrapper {
	height: 75px;
	box-shadow: none;
}

#header {
	padding: 0;
	width: 100%;
}

#main-navigation {
	.main-nav-wrapper {
		> div {
			height: 100%;
		}
		.container {
			position: relative;
		}
	}
	&:not(.nav-bottom) .main-nav-wrapper {
		> div {
			height: 100%;
		}
		.container-fluid {
			padding-right: 30px;
			padding-left: 30px;
			position: relative;
		}
	}
	> nav {
		height: 100%;
		width: 100%;
		transition: all .5s ease-in-out;
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		&.white {
			background: $wh;
		}
		i {
			font-size: 1.6rem;
			display: table-cell;
			vertical-align: middle;
			text-align: center;
		}
	}
}

.nav-left, .nav-center, .nav-right {
	display: table;
	margin: auto;
}

.nav-left {
	float: left;
	height: 100%;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

#main-navigation {
	.nav-center {
		float: none;
		text-align: center;
		position: static;
	}
	.nav-right {
		float: right;
		height: 100%;
		display: table;
		i {
			padding-top: 6px;
		}
		.ui-2_menu-dots {
			padding-top: 0;
		}
	}
	.logo {
		top: 10px;
		-webkit-transition: all .4s ease-in-out;
		-moz-transition: all .4s ease-in-out;
		transition: all .4s ease-in-out;
	}
}

header .menu-black .nav-right i {
	color: #333 !important;
}

.menu-black .toggle-menu {
	span:not(.menu-label) {
		background-color: #333 !important;
		&::before, &::after {
			background-color: #333 !important;
		}
	}
	&.active span {
		background: none !important;
	}
}

.nav-tools {
	height: 100%;
	vertical-align: middle;
	display: table-cell;
	padding-left: 0;
	li {
		height: 100%;
		display: table-cell;
		vertical-align: middle;
		padding: 0;
		padding:0;
		list-style: none;
		color:$wh;
		a {
			margin-top: 0;
			display: table-cell;
			vertical-align: middle;
			padding:0 14px;
			line-height:75px;
		}
	}
	.tools-btn-icon {
		width: 32px;
		height: 32px;
		display: inline-block;
		text-align: center;
		font-size: 18px;
	}
	.toggle-menu {
		top: 3px;
	}
}

.topnav-top .nav-tools .toggle-menu {
	top: 2px;
}

#main-navigation {
	.tools-btn span:not(.tools-btn-icon) {
		display: inline-block;
		margin-left: -7px;
		margin-right: 7px;
	}
	a:not(.btn), .nav-right i {
		color: #232325;
	}
}

#logo {
	display: table-cell;
	vertical-align: middle;
	padding-right: 30px;
	height: 100%;
	position: relative;
}

#main-navigation .container #logo {
	border-right: none !important;
}

#logo a {
	display: block;
	height: 36px;
	img {
		height: 100%;
		width: auto;
		margin-top: 2px;
		display: block;
		webkit-transition: all .4s ease-in-out;
		-moz-transition: all .4s ease-in-out;
		transition: all .4s ease-in-out;
	}
}

.topnav-top #logo a {
	height: 36px;
}

#logo.logo-lg a img {
	height: 40px;
}

.topnav-top #logo.logo-lg a img {
	height: 50px;
}

#main-navigation {
	#main-menu > ul > li > a, .main-menu > ul > li > a, .nav-right i, .tools-btn span {
		color: #121212;
	}
	#main-menu > ul {
		> li {
			> a {
				color: $wh;
				&:hover, &.active {
					background: rgba(68, 68, 68, 0.16);
				}
			}
			&.current > a {
				color: #E2785D;
			}
		}
		.is-open, > li.current i {
			color: #E2785D;
		}
	}
	.main-menu > ul {
		> li.current > a, .is-open, > li.current i {
			color: #E2785D;
		}
	}
}

#main-menu, .main-menu {
	float: right;
	height: 100%;
	padding: 0 5px;
	vertical-align: middle;
	display: table-cell;
	position: relative;
}

#main-menu {
	ul {
		display:none;
		&.sf-js-enabled {
			display:block;
		}
	}
	ul, li {
		list-style: none;
	}
}

.main-menu {
	ul, li {
		list-style: none;
	}
}

#main-menu > ul > li:not(.mega-menu) ul, .main-menu > ul > li:not(.mega-menu) ul {
	background: $wh;
}

#main-menu ul {
	li {
		max-width: 285px;
		text-align: center;
	}
	ul ul li {
		position: relative;
	}
}

.main-menu ul ul ul li {
	position: relative;
}

#main-menu > ul, .main-menu > ul {
	height: 100%;
	list-style: none;
	display: table;
	height: 100%;
	margin-bottom: 0;
	padding-left:0;
}

#main-menu > ul > li, .main-menu > ul > li {
	position: relative;
	float: left;
	height: 100%;
}

#main-menu > ul > li > a, .main-menu > ul > li > a {
	height: 60px;
	font-size: 13px;
	font-weight: 400;
	text-transform: uppercase;
	display: table-cell;
	vertical-align: middle;
	letter-spacing: 2px;
	margin: 0;
	padding: 0 18px;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

.nav-tools li a:not(.btn) {
	height: 60px;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

.topnav-top {
	#main-menu > ul > li > a, .main-menu > ul > li > a, .nav-tools li a {
		height: 75px;
	}
	#topbar ~ #main-navigation {
		#main-menu > ul > li > a, .main-menu > ul > li > a, .nav-tools li a:not(.btn) {
			height: 60px;
		}
	}
}

#main-menu ul li ul, .main-menu ul li ul {
	padding-left: 0;
	position: absolute;
	z-index: 10;
}

#main-menu ul > li:not(.mega-menu) > ul, .main-menu ul > li:not(.mega-menu) > ul {
	width: 285px;
	box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.06);
	padding: 0;
}

.left-nav #main-aside-menu ul > li:not(.mega-menu) > ul {
	padding: 0;
}

#main-menu ul ul a, .main-menu ul ul a {
	height: 38px;
	line-height: 38px;
	font-weight: 500;
}

#main-menu ul ul ul, .main-menu ul ul ul {
	top: 0;
	left: 285px;
}

#main-menu ul ul ul.last, .main-menu ul ul ul.last {
	top: 0;
	left: -285px;
}

#main-menu {
	> ul > li > ul a, .mega-menu ul a {
		color: #4E4E4E;
		webkit-transition: all .2s ease-in-out;
		-moz-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
	}
}

.main-menu {
	> ul > li > ul a, .mega-menu ul a {
		color: #4E4E4E;
		webkit-transition: all .2s ease-in-out;
		-moz-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
	}
}

#main-menu > ul > li {
	> ul a:not(.btn):hover, &.mega-menu .mega-menu-content a:not(.menu-title):not(.btn):hover {
		color: #121212;
	}
}

.main-menu > ul > li {
	> ul a:hover, &.mega-menu .mega-menu-content a:not(.menu-title):hover {
		color: #121212;
	}
}

#main-menu .mega-menu ul a:hover, .main-menu .mega-menu ul a:hover {
	border-radius: 3px;
}

#main-menu .submenu, .main-menu .submenu, #main-menu .submenu > a, .main-menu .submenu > a {
	position: relative;
}

#main-menu .submenu > a::after, .main-menu .submenu > a::after {
	content: "\f105";
	font: normal normal normal 14px/1 FontAwesome;
	position: absolute;
	right: 25px;
	top: 50%;
	margin-top: -7px;
	color: #121212;
}

#main-menu .mega-menu-content, .main-menu .mega-menu-content {
	position: absolute;
	overflow: hidden;
	left: 30px;
	right: 30px;
	box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.1);
	background-color: $wh;
}

.container {
	#main-menu .mega-menu-content, .main-menu .mega-menu-content {
		left: 0;
		right: 0;
		overflow: hidden;
	}
}

#main-menu > ul > li > ul, .main-menu > ul > li > ul {
	margin-top: 0;
}

#main-menu .mega-menu-content ul, .main-menu .mega-menu-content ul {
	padding: 15px 20px !important;
}

body:not(.left-nav) {
	#main-menu .mega-menu-content ul ul, .main-menu .mega-menu-content ul ul {
		display: block !important;
		opacity: 1 !important;
		border: none !important;
		left: 0;
		width: 100% !important;
		box-shadow: none !important;
		position: relative;
		padding: 0 !important;
	}
}

#main-menu .mega-menu-content.col-4 > {
	ul, div:not(.menu-bg-image) {
		width: 25%;
		position: relative;
		float: left;
		z-index: 3;
	}
}

.main-menu .mega-menu-content.col-4 > {
	ul, div:not(.menu-bg-image) {
		width: 25%;
		position: relative;
		float: left;
		z-index: 3;
	}
}

#main-menu .mega-menu-content.col-5 > {
	ul, div:not(.menu-bg-image) {
		width: 20%;
		position: relative;
		float: left;
		z-index: 3;
	}
}

.main-menu .mega-menu-content.col-5 > {
	ul, div:not(.menu-bg-image) {
		width: 20%;
		position: relative;
		float: left;
		z-index: 3;
	}
}

#main-menu .mega-menu-content > ul:not(.no-border):after, .main-menu .mega-menu-content > ul:not(.no-border):after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 15px;
	right: 0;
	width: 1px;
	height: 100%;
	background: rgba(0, 0, 0, 0.07);
}

#main-menu .mega-menu-content > ul:last-child:after, .main-menu .mega-menu-content > ul:last-child:after {
	display: none;
}

#main-menu > ul > li.mega-menu, .main-menu > ul > li.mega-menu {
	position: static;
}

#main-menu > ul > li > ul > li a, .main-menu > ul > li > ul > li a {
	display: block;
	padding: 12px 42px 12px 20px;
	height: 100%;
	font-size: 13px;
	text-align: left;
	line-height: 20px;
	text-transform: uppercase;
}

#main-menu > ul > li {
	> ul > li {
		&.sfHover {
			a, a.active {
				background: #f5f5f5 !important;
				color: $gold !important;
			}
			> ul > li a, > ul > li a.active {
				color: #232325 !important;
				&:hover, &.active {
					color: $gold !important;
				}
			}
		}
		a:hover, a.active {
			background: #f5f5f5 !important;
			color: $gold !important;
		}
		> ul > li a:hover, > ul > li a.active {
			color: $gold !important;
		}
	}
	&.mega-menu .mega-menu-content a {
		display: block;
		padding-left: 15px;
		height: 100%;
		font-size: 0.9rem;
		text-align: left;
	}
}

.main-menu > ul > li.mega-menu .mega-menu-content a {
	display: block;
	padding-left: 15px;
	height: 100%;
	font-size: 0.9rem;
	text-align: left;
}

#main-menu > ul > li > ul > li a:hover, .main-menu > ul > li > ul > li a:hover {
	padding-left: 18px;
}

#main-menu > ul > li.mega-menu .mega-menu-content .menu-title, .main-menu > ul > li.mega-menu .mega-menu-content .menu-title {
	font-weight: 700;
	letter-spacing: 2px;
	font-size: 0.78rem;
	color: $bl;
	text-transform: uppercase;
}

#main-menu > ul > li.mega-menu .mega-menu-content > ul > li > a:not(.menu-title):hover, .main-menu > ul > li.mega-menu .mega-menu-content > ul > li > a:not(.menu-title):hover {
	padding-left: 15px;
	background: none;
	color: #4E4E4E;
}

#main-menu > ul > li.mega-menu .mega-menu-content > ul > li > ul a, .main-menu > ul > li.mega-menu .mega-menu-content > ul > li > ul a {
	font-weight: 500;
	text-transform: none;
	color: rgba(0, 0, 0, 0.7);
}

#main-menu > ul > li.mega-menu .mega-menu-content > ul > li > ul a:hover, .main-menu > ul > li.mega-menu .mega-menu-content > ul > li > ul a:hover {
	padding-left: 18px;
}

#main-menu ul li {
	ul li ul {
		background: #f5f5f5 !important;
	}
	&.break {
		margin-right: 105px;
	}
}

.nav-left {
	#main-menu > ul, .main-menu > ul {
		padding-left: 10px;
	}
}

.header-no-sticky {
	#main-navigation {
		position: fixed;
		top: -70px;
		&.nav-visible {
			position: fixed;
			top: 0;
		}
	}
	#topbar, #main-navigation {
		position: relative;
	}
}

/* ROUND IN THE MIDDLE */
.logo-place {
	width: 104px;
	height: 75px;
	min-width: 104px;
}
.round-logo {
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
	width: 104px;
	height: 75px;
  /* 40 */
  img {
  	-webkit-transition: all .4s ease-in-out;
  	-moz-transition: all .4s ease-in-out;
  	transition: all .4s ease-in-out;
  	position: absolute;
  	top: 20px;
  	left: 50%;
  	margin-left: -34px;
  	z-index: 2;
  }
  .half {
  	position: absolute;
  	height: 23px;
  	width: 104px;
  	overflow: hidden;
  	bottom: -23px;
  	z-index: 1;
  	&:after {
  		-webkit-transition: all .4s ease-in-out;
  		-moz-transition: all .4s ease-in-out;
  		transition: all .4s ease-in-out;
  		content: "";
  		background: rgba(68, 68, 68, 0.16);
  		display: block;
  		width: 104px;
  		height: 104px;
  		position: absolute;
  		bottom: 0;
  		border-radius: 100%;
  	}
  }
}

body:not(.topnav-top):not(.header-scroll-transparent):not(.dark-skin) #main-navigation > .main-nav-wrapper .round-logo {
	height: 60px;
	img {
		top: 5px;
	}
	.half:after {
		background: rgba(68, 68, 68, 0.36) !important;
	}
}


@media (max-width: 1200px) {
	#main-navigation:not(.nav-bottom) .main-nav-wrapper .container-fluid {
		padding-left: 0;
	}
	.toggle-menu {
		width:51px !important;
		height:51px !important;
		background:#c49d62 !important;
		margin:0 !important;
		padding:0 !important;
		top:0 !important;
		&:hover {
			background:lighten(#c49d62, 5%) !important;
		}
	}
	#main-navigation .nav-center {
		float:left !important;
		position:inherit !important;
		text-align:left !important;
	}
	.nav-tools li {
		float:left;
	}
	.topnav-top #header, .topnav-top #main-menu > ul > li > a, .topnav-top .main-menu > ul > li > a, .topnav-top .nav-tools li a, .topnav-top #main-navigation .main-nav-wrapper {
		height:51px !important;
	}
	#header, #main-navigation .main-nav-wrapper, .nav-tools li a:not(.btn) {
		height:51px !important;
	}
	.nav-tools li a {
		line-height:51px !important;
	}
	.topnav-top {
		#main-navigation {
			.main-nav-wrapper {
				height: 51px !important;
			}
			&.nav-logo-center .nav-center {
				height: 51px !important;
				top: 0;
			}
		}
		#header, #main-aside-navigation .main-nav-wrapper {
			height: 51px;
		}
	}
	.nav-tools .toggle-menu {
		top:3px;
	}
	.menu-mobile.topnav-top .mobile-menu-btn .toggle-menu {
		top:22px;
	}
	body:not(.header-tansparent):not(.left-nav):not(.right-nav):not(.header-scroll-dark):not(.header-dark):not(.header-scroll-transparent) #header #main-navigation .main-nav-wrapper, .header-light.header-scroll-dark.topnav-top #main-navigation .main-nav-wrapper, .header-top-light.header-scroll-dark.topnav-top #main-navigation .main-nav-wrapper {
		background:$darkGrey !important;
	}
	.header-info {
		display: none;
	}
	.iace-tool {
		border-left:solid 1px $midGrey;
		a {
			&:hover {
				background: rgba(84, 84, 84, 0.5) !important;
			}
		}
	}
	.nav-tools li a {
		width: 51px;
	}
	.header-top-search form {
		float: right;
	}
	.topnav-top .top-logo-center #main-navigation .main-nav-wrapper {
		height: 0;
	}
	.top-logo-center {
		#main-navigation .main-nav-wrapper {
			height: 0;
		}
		li.mobile-menu-btn {
			position: fixed;
			right: 20px;
			top: 20px;
		}
	}
}

body:not(.topnav-top):not(.header-scroll-transparent):not(.dark-skin) #main-navigation > .main-nav-wrapper {
	background: rgba(68, 68, 68, 0.36) !important;
}

body:not(.header-tansparent):not(.left-nav):not(.right-nav):not(.header-scroll-dark):not(.header-dark):not(.header-scroll-transparent) #header #main-navigation .main-nav-wrapper, .header-light.header-scroll-dark.topnav-top #main-navigation .main-nav-wrapper, .header-top-light.header-scroll-dark.topnav-top #main-navigation .main-nav-wrapper {
	background: rgba(68, 68, 68, 0.16);
}

.header-light #topbar {
	background: $wh;
}

/* HAMBURGER BUTTON */

.toggle-menu {
	margin-top: 2px;
	height: 28px;
	width: 22px;
	background: none;
	position: relative;
	margin: auto;
	margin-left: 4px;
	padding: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	box-shadow: none;
	border-radius: none;
	border: none;
	cursor: pointer;
	-webkit-transition: background .3s ease-in-out;
	-moz-transition: background .3s ease-in-out;
	-o-transition: background .3s ease-in-out;
	transition: background .3s ease-in-out;
	cursor: pointer;
	&:focus {
		outline: 0;
	}
	span:not(.menu-label) {
		display: block;
		position: absolute;
		top: 9px;
		left: 0px;
		right: 0;
		height: 2px;
		background: $wh;
		-webkit-transition: background 0s .3s;
		transition: background 0s .3s;
		&::after {
			position: absolute;
			display: block;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: $wh;
			content: "";
		}
		&::before {
			position: absolute;
			display: block;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: $wh;
			content: "";
			top: -8px;
		}
		&::after {
			bottom: -8px;
			-webkit-transition-duration: .3s,.3s;
			transition-duration: .3s,.3s;
			-webkit-transition-delay: .3s,0s;
			transition-delay: .3s,0s;
		}
		&::before {
			-webkit-transition-duration: .3s,.3s;
			transition-duration: .3s,.3s;
			-webkit-transition-delay: .3s,0s;
			transition-delay: .3s,0s;
			-webkit-transition-property: top,-webkit-transform;
			transition-property: top,transform;
		}
		&::after {
			-webkit-transition-property: bottom,-webkit-transform;
			transition-property: bottom,transform;
		}
	}
	&.active span:not(.menu-label) {
		background: 0 0 !important;
		&::before {
			top: 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
		&::after {
			bottom: 0;
			-webkit-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			transform: rotate(-45deg);
			-webkit-transition-delay: 0s,.3s;
			transition-delay: 0s,.3s;
		}
		&::before {
			-webkit-transition-delay: 0s,.3s;
			transition-delay: 0s,.3s;
		}
	}
	&.tools-btn-icon {
		margin-left: 0;
	}
}

.topnav-top .toggle-menu {
	top: 7px;
}

.toggle-menu {
	&:focus {
		outline: none;
	}
}

/***** ASIDE MENU *****/

#aside-nav {
	background: $darkGrey;
	width: 300px;
	z-index: 1000;
	position: fixed;
	top: 0;
	left: -300px;
	height: 100%;
	overflow: hidden;
	-webkit-box-shadow: 0 0px 6px -1px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0px 6px -1px rgba(0, 0, 0, 0.2);
	box-shadow: 0 0px 6px -1px rgba(0, 0, 0, 0.2);
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

#main-aside-navigation {
	width: 300px;
	height: calc(100% - 40px);
	overflow: auto;
	position: relative;
	.main-nav-wrapper {
		height: auto;
		border-bottom: none !important;
		width: 300px;
		#aside-logo {
			margin: 23px 0 23px 0;
			display: block;
			padding-right: 0;
			a img {
				display: block;
				margin: auto;
				max-width: 170px;
				height: auto;
			}
			.logo-iace {
				margin-left:16px;
				display:block;
				@include retina-sprite($logo_mobile-group);
			}
		}
	}
}

#main-aside-menu {
	ul, li {
		list-style: none;
	}
	> ul {
		> li {
			border-bottom: solid 1px #414141;
			> a {
				color: #939598;
			}
			&.current > a {
				color: #939598;
			}
		}
		.is-open {
			color: #939598 !important;
			background: #3c3c3c;
		}
	}
	ul {
		li ul {
			background: #3c3c3c;
		}
		ul ul li {
			position: relative;
		}
		li ul {
			padding-left: 0;
		}
		> li > ul {
			width: 220px;
			box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.06);
			padding: 0;
		}
		ul a {
			height: 38px;
			line-height: 38px;
			font-weight: 500;
		}
		> li > ul {
			width: 300px;
		}
	}
	> ul {
		height: 100%;
		list-style: none;
		display: table;
		height: 100%;
		margin-bottom: 0;
		> li {
			position: relative;
			float: left;
			height: 100%;
			> a {
				font-size: 0.85rem;
				font-weight: 400;
				text-transform: uppercase;
				display: table-cell;
				vertical-align: middle;
				letter-spacing: 2px;
				margin: 0;
				padding: 0 15px;
				-webkit-transition: all .4s ease-in-out;
				-moz-transition: all .4s ease-in-out;
				transition: all .4s ease-in-out;
			}
		}
	}
	> ul {
		> li {
			> ul {
				border-top: solid 1px #4b4b4b !important;
				a {
					color: #939598;
					webkit-transition: all .2s ease-in-out;
					-moz-transition: all .2s ease-in-out;
					transition: all .2s ease-in-out;
					&:hover {
						color: #939598;
					}
				}
				margin-top: 0;
				> li a {
					display: block;
					padding-left: 30px;
					height: 100%;
					font-size: 14px;
					text-align: left;
					margin:0 !important;
					&.is-open {
						background:#484848;
					}
				}
				> li ul {
					border-top: solid 1px #565656 !important;
					background:#484848;
					> li {
						a {
							padding-left:40px;
						}
						border-bottom: solid 1px #565656 !important;
					}
				}
			}
			width: 300px;
			float: none;
			height: auto;
			display: block;
			padding-left: 0;
		}
		width: 300px;
		float: none;
		height: auto;
		display: block;
		padding-left: 0;
	}
	width: 300px;
	float: none;
	height: auto;
	display: block;
	padding-left: 0;
	padding-left: 0;
	li.submenu {
		position: relative;
		> a {
			position: relative;
			&::after {
				content: "\f105";
				font: normal normal normal 14px/1 FontAwesome;
				position: absolute;
				right: 12px;
				top: 50%;
				margin-top:-6px;
				color: #939598;
			}
		}
	}
	.submenu {
		> a:after {
			content: "\f105";
			font: normal normal normal 14px/1 FontAwesome;
			position: absolute;
			right: 12px;
			top: 12px;
			-webkit-transition: transform .2s linear;
			-o-transition: transform .2s linear;
			transition: transform .2s linear;
		}
		&:hover > a:after {
			filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-ms-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		> a.is-open:after {
			filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
			-webkit-transform: rotate(90deg);
			-moz-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			-o-transform: rotate(90deg);
			transform: rotate(90deg);
		}
	}
	> ul > li > a {
		padding: 12px 15px;
	}
	ul {
		ul {
			margin: 0;
			padding-left: 12px;
			position: relative;
			width: 100%;
			background-color: transparent;
			box-shadow: none;
			border: none;
			height: auto;
			z-index: 1;
			top: 0;
			left: 0;
			border-left: none !important;
		}
		> li > ul {
			border-top: none;
			box-shadow: none;
		}
	}
	> ul > li > ul a:hover {
		background: transparent;
	}
}

#main-aside-navigation #main-aside-menu > ul > li > a {
	width: 100%;
	display: block;
	font-weight: 600;
	line-height: normal;
	height: auto;
}

#main-aside-menu > ul {
	> li > ul {
		a:hover {
			padding-left:25px;
			background: none;
		}
		> li > ul > li > a {
			margin-right: 0;
			&:hover {
				padding-left:35px;
			}
		}
	}
	ul {
		display: none;
	}
	.is-open ul {
		display: block;
	}
}

.header-dark #aside-nav, #aside-nav.header-dark {
	background: #202020;
}

.header-dark #main-aside-navigation {
	border-right: none;
}

#main-aside-navigation .tools-btn span:not(.tools-btn-icon) {
	margin-left: 0;
	margin-right: 0;
}

#main-aside-menu > ul > li > ul > li > {
	a {
		margin-left: 10px;
	}
	ul > li {
		a {
			margin-left: 20px;
		}
		> ul > li a {
			margin-left: 30px;
		}
	}
}

#aside-nav.img-cover #main-aside-navigation footer {
	background: transparent;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
}

#main-navigation.submenu-dark #main-aside-menu {
	> ul > li > ul a, .mega-menu ul a, .submenu > a::after {
		color: #D9D9D9;
	}
}

/* LEFT NAV */

.aside-menu-open, .aside-menu-closing {
	overflow-x: hidden;
}

.left-nav {
	#aside-nav, #main-aside-navigation footer {
		left: 0;
	}
}

.aside-menu-open #aside-nav {
	left: 0;
	footer {
		left: 0;
	}
}

body.aside-menu-open::after {
	background: rgba(0, 0, 0, 0.3);
	z-index: 400;
	width: 100%;
	height: 100%;
	opacity: 1;
	visibility: visible;
	-webkit-transition: opacity .4s;
	-moz-transition: all .4s ease-in-out;
	transition: opacity .4s;
}

.left-nav #wrapper, .aside-menu-open:not(.aside-right):not(.aside-hover) #wrapper {
	margin: 0 0 0 280px;
}

.aside-left:not(.aside-hover) #wrapper {
	margin: 0 !important;
	left: 0;
	right: auto;
}

.aside-menu-open.aside-left:not(.aside-hover) {
	#wrapper {
		left: 280px;
		right: auto;
	}
	#main-navigation {
		left: 280px;
		right: -280px;
	}
}

.left-nav.aside-menu-open.aside-hover #wrapper {
	margin: 0;
}

.aside-menu-open #nav-toggle {
	left: 280px;
}

@media (max-width: 1200px) {
	.left-nav #aside-nav, #main-aside-navigation footer {
		left: -280px;
	}
	.left-nav {
		#main-aside-navigation footer {
			left: -280px;
		}
		#wrapper {
			margin-left: 0;
		}
		&.aside-menu-open {
			#aside-nav, #main-aside-navigation footer {
				left: 0;
			}
			#wrapper {
				margin-left: 280px;
			}
			&.aside-hover #wrapper {
				margin-left: 0;
			}
			&:not(.aside-hover) {
				#wrapper, #aside-nav {
					left: 0;
					right: auto;
				}
			}
			#aside-nav-toggle {
				left: 310px;
			}
		}
	}
}

/* MOBILE MENU */

li.mobile-menu-btn {
	display: none;
}

.menu-mobile #main-navigation {
	#main-menu, .main-menu, .nav-right .nav-tools li:not(.off-menu-btn) {
		display: none;
	}
}

.mobile-menu-closing .toggle-menu span.menu-label {
	opacity: 0 !important;
}

.mobile-menu-open.menu-mobile {
	#aside-nav, #main-aside-navigation footer {
		left: 0;
	}
}

body.mobile-menu-open::after {
	background: rgba(0, 0, 0, 0.3);
	z-index: 400;
	width: 100%;
	height: 100%;
	opacity: 1;
	visibility: visible;
	-webkit-transition: opacity .4s;
	-moz-transition: all .4s ease-in-out;
	transition: opacity .4s;
}
body.mobile-menu-open {
	overflow-y:hidden !important;
	#aside-nav {
		overflow:scroll !important;
	}
}
.mobile-menu-open {
	&.menu-mobile #aside-nav {
		visibility: visible;
	}
	#nav-toggle {
		left: 300px;
		right: auto;
	}
}

#aside-nav-toggle {
	display: none;
	color: $wh;
	font-size: 1.7rem;
	z-index: 350;
	position: fixed;
	top: 30px;
	left: 30px;
	right: auto;
	width: 38px;
	height: 38px;
	background: rgba(0, 0, 0, 0.2);
	webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	-webkit-transition: all .4s ease-in-out, background .2s;
	-moz-transition: all .4s ease-in-out, background .2s;
	transition: all .4s ease-in-out, background .2s;
	.toggle-menu {
		margin-top: 1px;
		margin-left: 5px;
		top: 2px;
	}
}

.menu-mobile {
	#aside-nav-toggle {
		display: block;
	}
	li.mobile-menu-btn {
		display: block !important;
		font-size: 1.6rem;
		.toggle-menu {
			-webkit-transition: all .4s ease-in-out;
			-moz-transition: all .4s ease-in-out;
			transition: all .4s ease-in-out;
		}
	}
	.topnav-top {
		li.mobile-menu-btn .toggle-menu {
			top: 22px;
		}
		#topbar ~ #main-navigation {
			li.mobile-menu-btn .toggle-menu {
		/*top: 7px;*/
	}
	.nav-right .mobile-menu-btn i {
		padding-top: 0px !important;
	}
}
}
&.topnav-top #topbar ~ #main-navigation .nav-right .mobile-menu-btn .toggle-menu {
	top: 4px;
}
li.mobile-menu-btn span {
	color: $wh;
}
}

.header-transparent.menu-mobile:not(.topnav-top) li.mobile-menu-btn span {
	color: #121212;
}

.menu-mobile {
	.header-light li.mobile-menu-btn span {
		color: #121212;
	}
	.menu-mobile #aside-nav .toggle-menu span.menu-label {
		color: $wh;
	}
	.topnav-top #main-navigation .nav-right .nav-tools li.mobile-menu-btn i {
		padding-top: 30px;
	}
	#main-navigation .nav-right .mobile-menu-btn i {
		padding-top: 17px !important;
	}
	&.topnav-top .mobile-menu-btn .toggle-menu {
		top: 22px;
	}
}

.topnav-top #topbar ~ #main-navigation .main-nav-wrapper .mobile-menu-btn .toggle-menu {
	top: 7px;
}

.menu-mobile {
	#main-navigation.header-2 .nav-right .mobile-menu-btn i {
		padding-top: 12px !important;
	}
	#main-aside-navigation .nav-right .nav-tools li.mobile-menu-btn i, .topnav-top #topbar ~ #main-aside-navigation .nav-right .nav-tools li.mobile-menu-btn i {
		padding-top: 15px;
		-webkit-transition: all .4s ease-in-out;
		-moz-transition: all .4s ease-in-out;
		transition: all .4s ease-in-out;
	}
}

@media (max-width: 1200px) {
	#aside-nav-toggle {
		display: block;
	}
}

@media (max-width: 768px) {
	.topnav-top {
		#main-navigation {
			.main-nav-wrapper {
				height: 51px !important;
			}
			&.nav-logo-center .nav-center {
				height: 51px !important;
				top: 0;
			}
		}
		#header, #main-aside-navigation .main-nav-wrapper {
			height: 51px;
		}
	}
	.header-transparent.topnav-top #header ~ #main-content {
		margin-top: -51px;
	}
	.topnav-top #main-aside-navigation .nav-right .nav-tools li.mobile-menu-btn i {
		padding-top: 1.3rem;
	}
	.menu-mobile {
		&.topnav-top .mobile-menu-btn .toggle-menu {
			top: 4px;
		}
		#topbar ~ #main-navigation {
			top: 0 !important;
		}
	}
}


.iace-tool {
	a {
		&:hover {
			background: rgba(68, 68, 68, 0.16);
		}
	}
	.iace-icon {
		display:block;
		&.ico-women {
			@include retina-sprite($menu_women-group);
		}
		&.ico-search {
			@include retina-sprite($menu_search-group);
		}
		&.ico-cal {
			@include retina-sprite($menu_cal-group);
		}
		&.ico-phone {
			@include retina-sprite($menu_phone-group);
		}
	}
}
body.error404 {
	#header #main-navigation .main-nav-wrapper, .header-light.header-scroll-dark.topnav-top #main-navigation .main-nav-wrapper, .header-top-light.header-scroll-dark.topnav-top #main-navigation .main-nav-wrapper {
		background:$blackGrey !important;
	}
	.half {
	  	&:after {
	  		background:$blackGrey !important;
	  	}
	  }
}