.hasDatepicker {
	width:290px;
	.ui-datepicker-inline {
		border-bottom:solid 1px #dbe1e6;
	}
	.ui-datepicker-header {
		border-bottom:solid 1px #dbe1e6;
		border-top:solid 1px #dbe1e6;
		margin-bottom:14px;
		line-height:28px;
		font-size:14px;
		font-weight:700;
		color:#888b8d;
		text-transform: uppercase;
		position:relative;
		text-align:center;
		a {
			position: absolute;
			display:block;
			line-height:28px;
			width:28px;
			height:28px;
			cursor:pointer;
			color:#888b8d;
			&:hover {
				color:$gold;
			}
			span {
				display:none;
			}
			&:before {
				position: absolute;
				display:block;
				width:28px;
				height:28px;
			}
			&.ui-datepicker-next {
				right:0;
				&:before {
					content:">";
				}
			}
			&.ui-datepicker-prev {
				left:0;
				&:before {
					content:"<";
				}
			}	
		}
	}
	table {
		width:auto;
		background:transparent;
		margin-bottom:14px;
	}
	thead {
		display:none;
	}
	tbody {
		td {
			padding:0;
			height:40px;
			line-height:40px;
			width:40px;
			text-align:center;
			border:none;
			font-size:14px;
			font-weight:600;
			color:#888b8d;
			span {
				opacity:(0.3);
			}
			a {
				color:$midGrey;
				height:40px;
				line-height:40px;
				width:40px;
				display:block;
				&:hover, &.ui-state-active {
					background:$gold;
					color:$wh;
				}
				&.ui-state-highlight {
					background:lighten($gold, 20%);
					cursor:not-allowed;
					color:$midGrey;
					&.ui-state-active {
						background:$gold;
						color:$wh;
					}
				}
			}
		}
	}
}

.rdv-container {
	text-align:left;
	margin-top:70px;
	.steps {
		margin-bottom:30px;
		overflow:hidden;
		.desc {
			margin-bottom:30px;
			margin-top:15px;
		}
		.step-title {
			display:block;
			color:$buttonGrey;
			font-style:italic;
			font-weight:700;
			font-size:20px;
			margin-bottom:15px;
			text-transform: uppercase;
		}
		.group {
			float:left;
			label {
				text-transform: uppercase;
				color:$buttonGrey;
				font-weight:600;
			}
			&.gr-radio {
				width:50%;
			}
			&.gr-sel {
				margin-right:20px;
				label {
					margin-right:20px;
				}
			}
		}
		textarea.form-control {
			height:150px;
		}
		.submit-container {
			button {
				width:100%;
				background:transparent;
				margin-bottom:12px;
				&:hover {
					background:$gold;
					color:$wh;
				}
			}
			.notice {
				width:100%;
				display:block;
				text-align:right;
			}
		}
	}
}

.select2-container--open .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
	border:none;
}
.select2-dropdown {
	border:none;	
}


@media (max-width: 768px) {
	.hasDatepicker {
		width:100%;
		table {
			width:auto;
			tbody {
				tr {
					border:none;
					margin-bottom:0;
				}
				td {
					width:35px;
					height:35px;
					line-height:35px;
					display:inline-block;
				}
			}
		}
	}
}
@media (max-width: 335px) {
	.hasDatepicker {
		table {
			tbody {
				td {
					width:35px;
					height:35px;
					line-height:35px;
				}
			}
		}
	}
}