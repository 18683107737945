ul.pagination {
	list-style:none;
	margin:0 auto;
	padding:0;
	display:block;
	text-align: center;
	li {
		display:inline-block;
		margin:0 5px;
		a {
			border-radius:100%;
			border:solid 2px $gold;
			color:$gold;
			text-align: center;
			line-height:40px;
			height:40px;
			width:40px;
			display:block;
			font-size: 14px;
			font-weight: 700;
			&:hover {
				background:$gold;
				color:$wh;
			}
		}
		&.active {
			a {
				background:$gold;
				color:$wh;
			}
		}
	}
}
.pagination-state {
	text-align:center;
	margin-top:60px;
	margin-bottom:20px;
	p {
		color:#8a8a8a;
		font-size:12px;
		font-weight:300;
	}
}
.news-section {
	.clear {
		clear:both;
	}
	.news-toolbar {
		padding:24px 32px;
		background:$wh;
		margin-bottom:64px;
		overflow:hidden;
		.input-group {
			float:left;
			display:inline-block;
			width:calc(25% - 10px);
			&:last-child {
				width:35px;
			}
			.checkbox-custom-label {
				font-size:15px;
				color:#8a8a8a;
				font-weight:700;
				text-transform: uppercase;
				line-height:26px;
			}
		}
		button {
			width:35px;
			height:35px;
			line-height:35px;
			text-align:center;
			background:$midGrey;
			border:none;
			border-radius:5px;
			&:hover {
				background:lighten($midGrey, 5%);
			}
			&:disabled {
				opacity:(0.8);
				cursor:not-allowed;
			}
			i {
				color:$wh;
				font-size:16px;
			}
		}
	}
	.posts {
		.post {
			padding:24px;
			background:$wh;
			margin-bottom:40px;
			.post-info {
				.post-title {
					color:$midGrey;
					margin-bottom:18px;
					min-height:70px;
					a {
						color:$midGrey;
						&:hover {
							color:$gold;
						}
					}
					text-transform: uppercase;
					font-size:30px;
					line-height:34px;
					font-weight:600;
				}
			}
			.img-cover {
				height:255px;
				width:100%;
			}
			.post-excerpt {
				margin-bottom:24px;
				p {
					font-size:13px;
					font-weight:300;
				}
			}
			figure.he-2 {
				.img-cover {
					-webkit-transition: all .4s;
					-moz-transition: all .4s;
					-o-transition: all .4s;
					transition: all .4s;
				}
				&:hover {
					.img-cover {
						opacity:(0.8);
					}
				}
			}
			.post-meta {
				list-style: none;
				padding:0;
				li {
					padding:0;
					font-size:14px;
					text-transform:uppercase;
					color:$gold;
					font-weight:600;
				}
			}
			.post-button {
				float:left;
				width:50%;
				i {
					font-size:20px;
				}
			}
			.post-date {
				width:50%;
				float:right;
				text-align:right;
				small {
					font-size:14px;
					text-align:right;
					color:#c7c7c7;
					font-weight:600;
					text-transform: uppercase;
				}
			}
		}
	}
}