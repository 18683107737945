#men_interv_area, #men_text_area {
	@include opacity(0);
}

#intervention {
	position: relative; @include noselect();

	.wrap_inte {
		padding: 20px 0;
		.title {
			margin-bottom:45px;
			h3 {
				padding-right:45px;
			}
		}
		.choose_sex {
			padding: 0;
			margin-bottom:40px;
			clear:both;
			overflow:hidden;
			position:absolute;
			top:145px;
			width:100%;
			#men, #women { cursor: pointer; }
			& > span {
				@include font(15px, uppercase, 400);
				width:50%;
				text-align:left;
				&.fl {
					float:left;
				}
				&.fr {
					float:right;
				}
				& > label { cursor: pointer; }
			}
		}
		.text_zone {
			margin-top:90px;
			p {
				@include font(15px, normal, 300);
				line-height:24px;
				line-height: 21px;
				clear: both;
				padding: 0;
				color: $lightTxt;
				margin-bottom:24px;
			}
		}

		/* ROTATOR */
		.flip-container {
			display:inline;
			overflow: hidden;
			-webkit-perspective: 1000;
			-moz-perspective: 1000;
			-ms-perspective: 1000;
			perspective: 1000;
			-ms-transform: perspective(1000px);
			-moz-transform: perspective(1000px);
			-moz-transform-style: preserve-3d; 
			-ms-transform-style: preserve-3d; 
		}
		.flip-container.hover .back-model {
		    -webkit-transform: rotateY(0deg);
		    -moz-transform: rotateY(0deg);
		    -o-transform: rotateY(0deg);
		    -ms-transform: rotateY(0deg);
		    transform: rotateY(0deg);
		}
		.flip-container.hover .front-model {
		    -webkit-transform: rotateY(180deg);
		    -moz-transform: rotateY(180deg);
		    -o-transform: rotateY(180deg);
		    transform: rotateY(180deg);
		}
		.flip-container, .front-model, .back-model {
			height: 470px;
		}
		.flipper {
			
			-webkit-transform-style: preserve-3d;
			-moz-transform: perspective(1000px);
			-moz-transform-style: preserve-3d;
			-ms-transform-style: preserve-3d;
			transform-style: preserve-3d;
			
			-webkit-transition: all 750ms cubic-bezier(0.860, 0.000, 0.000, 1); /* older webkit */
			-webkit-transition: all 750ms cubic-bezier(0.860, 0.000, 0.000, 1.650);
	   		-moz-transition: all 750ms cubic-bezier(0.860, 0.000, 0.000, 1.650);
	     	-o-transition: all 750ms cubic-bezier(0.860, 0.000, 0.000, 1.650);
	        transition: all 750ms cubic-bezier(0.860, 0.000, 0.000, 1.650); /* custom */

			-webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.000, 1); /* older webkit */
			-webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.000, 1.650);
	   		-moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.000, 1.650);
	     	-o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.000, 1.650);
	        transition-timing-function: cubic-bezier(0.860, 0.000, 0.000, 1.650); /* custom */

			position: relative;
		}
		.front-model, .back-model {
			-webkit-backface-visibility: hidden;
			-moz-backface-visibility: hidden;
			-ms-backface-visibility: hidden;
			backface-visibility: hidden;

		    -webkit-transform-style: preserve-3d;
		    -webkit-transform: rotateY(0deg);
		    -moz-transform-style: preserve-3d;
		    -moz-transform: rotateY(0deg);
		    -o-transform-style: preserve-3d;
		    -o-transform: rotateY(0deg);
		    -ms-transform-style: preserve-3d;
		    -ms-transform: rotateY(0deg);
		    transform-style: preserve-3d;
		    transform: rotateY(0deg);
			
			-webkit-transition: all 750ms cubic-bezier(0.860, 0.000, 0.000, 1); /* older webkit */
			-webkit-transition: all 750ms cubic-bezier(0.860, 0.000, 0.000, 1.650);
	   		-moz-transition: all 750ms cubic-bezier(0.860, 0.000, 0.000, 1.650);
	     	-o-transition: all 750ms cubic-bezier(0.860, 0.000, 0.000, 1.650);
	        transition: all 750ms cubic-bezier(0.860, 0.000, 0.000, 1.650); /* custom */

			-webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.000, 1); /* older webkit */
			-webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.000, 1.650);
	   		-moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.000, 1.650);
	     	-o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.000, 1.650);
	        transition-timing-function: cubic-bezier(0.860, 0.000, 0.000, 1.650); /* custom */

			position: absolute;
			top: 0;
		}
		
		.front-model {
			-webkit-transform: rotateY(0deg);
			-ms-transform: rotateY(0deg);
		}
		.back-model {
			-webkit-transform: rotateY(-180deg);
		    -moz-transform: rotateY(-180deg);
		    -o-transform: rotateY(-180deg);
		    -ms-transform: rotateY(-180deg);
		    transform: rotateY(-180deg);
		}
		.back-model img {
			left: 0;
			right: 0;
		}

		.wrap_view_model {
			display: inline-block;
			height: 475px;
			position: relative;
			left: 40px;
			width:100%;
			margin-bottom:50px;
			.front-model, .back-model {
				margin-top:5px;
				text-align:center;
			}
			.legend {
				.front, .back {
					@include opacity(.3);
					-webkit-transition: all .4s ease-in-out;
					-moz-transition: all .4s ease-in-out;
					transition: all .4s ease-in-out;
					position: absolute;
					text-align: center;
					text-transform: uppercase;
					border: 2px solid;
					border-radius: 50em;
					padding:0 8px;
					line-height:25px;
					cursor: default;
					font-size:13px;
					font-weight:700;
					display:block;
					&.active {
						@include opacity(1);
						cursor: pointer;
						&:hover { 
							color: $blackGrey;
							&:after {
								border-color: $blackGrey;
							}
						}
					}
					&.on {
						color: $gold;
						@include box-shadow(rgba($bl, 0.4) 0px 3px 5px);
						&:before, &:after {
							border-color:$gold !important;
						}
						&:hover {
							color: $gold;
							@include box-shadow(rgba($bl, 0.4) 0px 3px 5px);
							&:before, &:after {
								border-color:$gold !important;
							}
						}
					}
				}
				&#model_view_women {
					.front {
						&:after {
							-webkit-transition: all .4s ease-in-out;
							-moz-transition: all .4s ease-in-out;
							transition: all .4s ease-in-out;
							position: absolute;
							content:"";
							height:1px;
							border-bottom:solid 1px;
							border-color: $grey;
							display:block;
							top:50%;
						}
						&.face {
							left: -45px; top: 38px;
							&:after {
								left:100%;
								width:75px;
							}
						}
						&.chest {
							left: -50px; top: 120px;
							&:after {
								left:100%;
								width:87px;
							}
						}
						&.stomach {
							left: -45px; top: 180px;
							&:after {
								left:100%;
								width:83px;
							}
						}
						&.hand {
							right:50px; top: 218px;
							&:after {
								right:100%;
								width:95px;
							}
						}
						&.sex {
							right:50px; top: 255px;
							z-index:0;
							&:after {
								right:100%;
								width:72px;
							}
							&:before {
								-webkit-transition: all .4s ease-in-out;
								-moz-transition: all .4s ease-in-out;
								transition: all .4s ease-in-out;
								position: absolute;
								content:"";
								height:1px;
								border-bottom:solid 1px;
								border-color: $grey;
								display:block;
								top:-25%;
								@include transform(rotate(40deg));
								left:-125px;
								width:59px;
							}
						}
					}
					.back {
						&:after {
							-webkit-transition: all .4s ease-in-out;
							-moz-transition: all .4s ease-in-out;
							transition: all .4s ease-in-out;
							position: absolute;
							content:"";
							height:1px;
							border-bottom:dashed 1px;
							border-color: $grey;
							display:block;
							top:50%;
						}
						&.leg {
							left: -30px; top: 235px;
							&:after {
								left:100%;
								width:70px;
							}
						}
						&.under_arm {
							right:30px; top: 110px;
							&:after {
								right:100%;
								width:90px;
							}
						}
						&.arm {
							right:60px; top: 155px;
							&:after {
								right:100%;
								width:88px;
							}
						}
						&.buttock {
							right:50px; top: 295px;
							z-index:0;
							&:after {
								right:100%;
								width:82px;
							}
							&:before {
								-webkit-transition: all .4s ease-in-out;
								-moz-transition: all .4s ease-in-out;
								transition: all .4s ease-in-out;
								position: absolute;
								content:"";
								height:1px;
								border-bottom:dashed 1px;
								border-color: $grey;
								display:block;
								top:-55%;
								@include transform(rotate(80deg));
								left:-114px;
								width:55px;
							}
						}
					}
				}
				&#model_view_men {
					.front {
						&:after {
							-webkit-transition: all .4s ease-in-out;
							-moz-transition: all .4s ease-in-out;
							transition: all .4s ease-in-out;
							position: absolute;
							content:"";
							height:1px;
							border-bottom:solid 1px;
							border-color: $grey;
							display:block;
							top:50%;
						}
						&.face {
							left: -45px; top: 24px;
							&:after {
								left:100%;
								width:75px;
							}
						}
						&.chest {
							left: -50px; top: 128px;
							z-index:0;
							&:after {
								left:100%;
								width:55px;
							}
						}
						&.stomach {
							right:60px; top: 155px;
							z-index:0;
							&:after {
								right:100%;
								width:65px;
							}
						}
						&.hand {
							left: -55px; top: 242px;
							&:after {
								left:100%;
								width:35px;
							}
						}
						&.sex {
							right:50px; top: 260px;
							z-index:0;
							&:after {
								right:100%;
								width:72px;
							}
							&:before {
								-webkit-transition: all .4s ease-in-out;
								-moz-transition: all .4s ease-in-out;
								transition: all .4s ease-in-out;
								position: absolute;
								content:"";
								height:1px;
								border-bottom:solid 1px;
								border-color: $grey;
								display:block;
								top:-25%;
								@include transform(rotate(40deg));
								left:-125px;
								width:59px;
							}
						}
					}
					.back {
						&:after {
							-webkit-transition: all .4s ease-in-out;
							-moz-transition: all .4s ease-in-out;
							transition: all .4s ease-in-out;
							position: absolute;
							content:"";
							height:1px;
							border-bottom:dashed 1px;
							border-color: $grey;
							display:block;
							top:50%;
						}
						&.leg {
							left: -30px; top: 285px;
							&:after {
								left:100%;
								width:70px;
							}
						}
						&.under_arm {
							right:30px; top: 115px;
							&:after {
								right:100%;
								width:90px;
							}
						}
						&.arm {
							left: -45px; top: 182px;
							&:after {
								left:100%;
								width:48px;
							}
						}
						&.buttock {
							right:50px; top: 205px;
							z-index:0;
							&:after {
								right:100%;
								width:82px;
							}
						}
					}
				}
			}
			&.type-face {
				.legend {
					&#model_view_women {
						.sex {
							z-index:101;
						}
					}
					&#model_view_men {
						.chest, .stomach, .sex {
							z-index:101;
						}
					}
				}
			}
			&.type-back {
				.legend {
					&#model_view_women {
						.buttock {
							z-index:101;
						}
					}
					&#model_view_men {
						.buttock {
							z-index:101;
						}
					}
				}
			}
		}
		.wrap_preview {
			width: 212px;
			height:212px;
			border-radius:100%;
			background: $bgGrey;
			overflow:hidden;
			margin:-10px 0 50px 0;
			position:relative;
			img {
				position:absolute;
				border-radius:50%;
				-webkit-transition: all .4s ease-in-out;
				-moz-transition: all .4s ease-in-out;
				transition: all .4s ease-in-out;
			}
		}
		.wrap_operations {
			margin-bottom: 20px;
			.arrow {
				position: relative;
				width: 30px; height: 30px;
				line-height: 32px;
				margin: 0 auto;
				border-radius: 50em;
				background-color: #f5f5f5;
				color: $gold;
				cursor: pointer;
				text-align: center;
				&.up {
					top: 65px;
				}
				&.down {
					top: 270px;
				}
			}
			.list_ope {
				overflow: scroll; height: 238px;
				padding: 0; margin-top: 18px;
				.inte-item {
					@include font(14px, initial, 400);
					background-color: rgba(248, 248, 248, .7);
					list-style: none; padding: 10px 5px;
					text-transform: uppercase; color: gray;
					margin: 5px 0;
					overflow: hidden;
					text-overflow: ellipsis;
					a {
						color: black;
						&:hover { color: $gold; }
					}
					span {
						font-size: 1.5em; color: $gold;
					}
				}
			}
		}
		.turn_model {
			position:absolute;
			bottom:0;
			right:50px;
			cursor: pointer;
			text-align: center;
			width: 47px;
			height:70px;
			i.ico {
				display:block;
				@include retina-sprite($turn_ico-group);
			}
			.turn-status {
				-webkit-transition: all .4s ease-in-out;
				-moz-transition: all .4s ease-in-out;
				transition: all .4s ease-in-out;
				font-size:14px;
				text-transform: uppercase;
				font-weight: 700;
				color:$midGrey;
				position:absolute;
				display:inline-block;
				bottom:0;
				left:50%;
				margin-left:-60%;
				width:120%;
				@include opacity(0);
				&.on {
					@include opacity(1);
				}
			}
		}
	}
	.data-intervention {
		height:250px;
		overflow:hidden;
		.interv-list-container {
			display:none;
			&.on {
				display:block;
			}
		}
		ul {
			list-style:none;
			padding:0;
			background:$wh;
			li {
				list-style:none;
				margin-bottom:4px;
				display:block;
				width:100%;
				min-height:38px;
				a {
					-webkit-transition: all .2s ease-in-out;
					-moz-transition: all .2s ease-in-out;
					transition: all .2s ease-in-out;
					display:block;
					width:100%;
					//min-height:38px;
					background:$bgGrey;
					padding:12px 30px 12px 12px;
					position:relative;
					color:$midGrey;
					font-size:12px;
					line-height:14px;
					overflow: hidden;
					&:hover {
						background:darken($bgGrey, 5%);
					}
					strong {
						font-size:17px;
						font-weight: 700;
						top:50%;
						margin-top: -8px;
						position:absolute;
						width: 30%;
						text-align:right;
					}
					span {
						float:left;
						display:block;
						margin-left:7px;
						position:relative;
						&.lb {
							float:right;
							width:55%;
						}
						&.wline {
							&:before {
								content:" - ";
								position:absolute;
								left:-16px;
								top:50%;
								margin-top:-8px;
							}
						}
					}
					.nc-icon-glyph {
						font-size:18px;
						position:absolute;
						right:12px;
						top:50%;
						margin-top:-9px;
						color:$gold;
					}
				}
			}
		}
	}
	#men_interv_area {
		.front-model, .back-model {
			left: 16px;
		}
	}
	#women_interv_area {
		.front-model, .back-model {
			left: 50px;
		}
	}
}
.data-intervention {
	.data-men > div,.data-women > div { display: none; }
}

.tabs-interventions {
	background:$bgGrey;
	.nav-tabs {
		text-align:center;
		li {
			float:none;
			display:inline-block;
			margin-left:-4px !important;
			a {
				border-right:solid 1px #e3e3e3;
				padding:0 40px;
				display:block;
				color:$midGrey;
				line-height:55px;
				text-transform: uppercase;
				font-size:16px;
				font-weight:700;
				&.active {
					color:$gold;
					span {
						&:after {
							background:$gold;
						}
					}
				}
				&:hover {
					background:darken($bgGrey, 5%);
					span {
						&:after {
							background:$gold;
						}
					}
				}
				span {
					display:block;
					position:relative;
					i {
						margin-right:6px;
					}
					&:after {
						-webkit-transition: all .4s ease-in-out;
						-moz-transition: all .4s ease-in-out;
						transition: all .4s ease-in-out;
						content:"";
						position:absolute;
						display:block;
						width:100%;
						height:5px;
						background:transparent;
						bottom:0;
						z-index:10;
					}
				}
			}
			&:last-child {
				a {
					border-right:none;
				}
			}
		}
	}
}

@media (max-width: $screen-sm) {
	#intervention {
		min-height: 1000px;

		.wrap_operations {
			.slideUp {
				@include font(15px, uppercase, 400);
				margin-top: 24px;
				background-color: $gold;
				color: white;
			}
		}
	}
}

//media-queries -- small
@media (max-width: $screen-sm) {
	#intervention {
		.zone-title {margin: 15px auto; }
		.choose_sex { text-align: center; margin: 0!important; }
		.wrap_view_model {
			width: 100%!important;
			left: 0!important;
			height: auto!important;

			.legend {
				width: 80%; margin: 0 auto;
				& > div {
					opacity: 1!important;
					position: static!important;
					margin: 10px 0!important;
				}
			}
		}
		.text_zone { position: relative; top: 0; margin:15px 0!important }
		.wrap_preview {
			position: absolute;
			bottom: 780px!important;
			right: 20px!important;
			background-size: contain;
			img { width: 100px; height: 100px; }
		}
		.turn_model { bottom: 10px; }
		.wrap_operations {
			width: 100%;
			.list_ope {
				padding: 0;
				& > li {
					margin: 12px 0;
				}
			}
		}
	}
}


@media (max-width: $screen-lg) {
	#intervention {
		.title-lg-center {
			text-align: center;
			&.title-line-bottom::after {
				left: 50%;
	    		margin-left: -15px;
	    	}
		}
		.wrap_inte {
			.text_zone {
				margin-top:0;
			}
			.choose_sex {
				position:relative;
				top:auto;
				width:50%;
				margin:0 auto;
				span {
					text-align: center;
				}
				margin-bottom:50px;
			}
			.wrap_view_model .legend {
				&#model_view_women {
					.front {
						&.face {
							left:40px;
						}
						&.chest {
							left:35px;
						}
						&.stomach {
							left:40px;
						}
					}
					.back {
						&.leg {
							left:55px;
						}
					}
				}
				&#model_view_men {
					.front {
						&.stomach {
							right:95px;
						}
						&.sex {
							right:85px;
						}
					}
					.back {
						&.buttock {
							right:95px;
						}
					}
				}
			}
		}
		
		#women_interv_area .front-model, #women_interv_area .back-model {
			left:135px;
		}
		#men_interv_area {
			margin-left:85px;
			.turn_model {
				right:85px;
			}
		}
	}
}

@media (max-width: 990px) {
	#intervention {
		.wrap_inte {
			.wrap_view_model {
				left:-45px;
				.legend {
					&#model_view_women {
						.front {
							&.sex {
								right:-45px;
							}
							&.hand {
								right:-35px;
							}
						}
						.back {
							&.under_arm {
								right:-55px;
							}
							&.buttock {
								right:-45px;
							}
							&.arm {
								right:-35px;
							}
						}
					}
					&#model_view_men {
						.front {
							&.stomach {
								right:95px;
							}
							&.sex {
								right:85px;
							}
						}
						.back {
							&.buttock {
								right:95px;
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	#intervention {
		.hidden-xs {
			display:none !important;
		}
		.wrap_inte {
			.choose_sex {
				margin:0 auto !important;
				width:70%;
				margin-bottom:30px !important;
			}
			.wrap_view_model .legend .type-buttons {
				&:after {
					display:none !important;
				}
			}
		}
		#men_interv_area {
			margin-left:0;
		}
	}
}
