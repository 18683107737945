/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$footer-logo-name: 'footer_logo';
$footer-logo-x: 0px;
$footer-logo-y: 62px;
$footer-logo-offset-x: 0px;
$footer-logo-offset-y: -62px;
$footer-logo-width: 196px;
$footer-logo-height: 61px;
$footer-logo-total-width: 228px;
$footer-logo-total-height: 169px;
$footer-logo-image: '../sprite.png';
$footer-logo: (0px, 62px, 0px, -62px, 196px, 61px, 228px, 169px, '../sprite.png', 'footer_logo', );
$logo-mobile-name: 'logo_mobile';
$logo-mobile-x: 0px;
$logo-mobile-y: 0px;
$logo-mobile-offset-x: 0px;
$logo-mobile-offset-y: 0px;
$logo-mobile-width: 202px;
$logo-mobile-height: 62px;
$logo-mobile-total-width: 228px;
$logo-mobile-total-height: 169px;
$logo-mobile-image: '../sprite.png';
$logo-mobile: (0px, 0px, 0px, 0px, 202px, 62px, 228px, 169px, '../sprite.png', 'logo_mobile', );
$menu-cal-name: 'menu_cal';
$menu-cal-x: 202px;
$menu-cal-y: 0px;
$menu-cal-offset-x: -202px;
$menu-cal-offset-y: 0px;
$menu-cal-width: 26px;
$menu-cal-height: 36px;
$menu-cal-total-width: 228px;
$menu-cal-total-height: 169px;
$menu-cal-image: '../sprite.png';
$menu-cal: (202px, 0px, -202px, 0px, 26px, 36px, 228px, 169px, '../sprite.png', 'menu_cal', );
$menu-phone-name: 'menu_phone';
$menu-phone-x: 202px;
$menu-phone-y: 36px;
$menu-phone-offset-x: -202px;
$menu-phone-offset-y: -36px;
$menu-phone-width: 26px;
$menu-phone-height: 36px;
$menu-phone-total-width: 228px;
$menu-phone-total-height: 169px;
$menu-phone-image: '../sprite.png';
$menu-phone: (202px, 36px, -202px, -36px, 26px, 36px, 228px, 169px, '../sprite.png', 'menu_phone', );
$menu-search-name: 'menu_search';
$menu-search-x: 202px;
$menu-search-y: 72px;
$menu-search-offset-x: -202px;
$menu-search-offset-y: -72px;
$menu-search-width: 26px;
$menu-search-height: 36px;
$menu-search-total-width: 228px;
$menu-search-total-height: 169px;
$menu-search-image: '../sprite.png';
$menu-search: (202px, 72px, -202px, -72px, 26px, 36px, 228px, 169px, '../sprite.png', 'menu_search', );
$menu-women-name: 'menu_women';
$menu-women-x: 202px;
$menu-women-y: 108px;
$menu-women-offset-x: -202px;
$menu-women-offset-y: -108px;
$menu-women-width: 26px;
$menu-women-height: 36px;
$menu-women-total-width: 228px;
$menu-women-total-height: 169px;
$menu-women-image: '../sprite.png';
$menu-women: (202px, 108px, -202px, -108px, 26px, 36px, 228px, 169px, '../sprite.png', 'menu_women', );
$scroll-down-name: 'scroll_down';
$scroll-down-x: 47px;
$scroll-down-y: 123px;
$scroll-down-offset-x: -47px;
$scroll-down-offset-y: -123px;
$scroll-down-width: 33px;
$scroll-down-height: 33px;
$scroll-down-total-width: 228px;
$scroll-down-total-height: 169px;
$scroll-down-image: '../sprite.png';
$scroll-down: (47px, 123px, -47px, -123px, 33px, 33px, 228px, 169px, '../sprite.png', 'scroll_down', );
$scroll-up-name: 'scroll_up';
$scroll-up-x: 80px;
$scroll-up-y: 123px;
$scroll-up-offset-x: -80px;
$scroll-up-offset-y: -123px;
$scroll-up-width: 33px;
$scroll-up-height: 33px;
$scroll-up-total-width: 228px;
$scroll-up-total-height: 169px;
$scroll-up-image: '../sprite.png';
$scroll-up: (80px, 123px, -80px, -123px, 33px, 33px, 228px, 169px, '../sprite.png', 'scroll_up', );
$turn-ico-name: 'turn_ico';
$turn-ico-x: 0px;
$turn-ico-y: 123px;
$turn-ico-offset-x: 0px;
$turn-ico-offset-y: -123px;
$turn-ico-width: 47px;
$turn-ico-height: 46px;
$turn-ico-total-width: 228px;
$turn-ico-total-height: 169px;
$turn-ico-image: '../sprite.png';
$turn-ico: (0px, 123px, 0px, -123px, 47px, 46px, 228px, 169px, '../sprite.png', 'turn_ico', );
$footer-logo-2x-name: 'footer_logo@2x';
$footer-logo-2x-x: 0px;
$footer-logo-2x-y: 124px;
$footer-logo-2x-offset-x: 0px;
$footer-logo-2x-offset-y: -124px;
$footer-logo-2x-width: 392px;
$footer-logo-2x-height: 122px;
$footer-logo-2x-total-width: 456px;
$footer-logo-2x-total-height: 338px;
$footer-logo-2x-image: '../sprite@2x.png';
$footer-logo-2x: (0px, 124px, 0px, -124px, 392px, 122px, 456px, 338px, '../sprite@2x.png', 'footer_logo@2x', );
$logo-mobile-2x-name: 'logo_mobile@2x';
$logo-mobile-2x-x: 0px;
$logo-mobile-2x-y: 0px;
$logo-mobile-2x-offset-x: 0px;
$logo-mobile-2x-offset-y: 0px;
$logo-mobile-2x-width: 404px;
$logo-mobile-2x-height: 124px;
$logo-mobile-2x-total-width: 456px;
$logo-mobile-2x-total-height: 338px;
$logo-mobile-2x-image: '../sprite@2x.png';
$logo-mobile-2x: (0px, 0px, 0px, 0px, 404px, 124px, 456px, 338px, '../sprite@2x.png', 'logo_mobile@2x', );
$menu-cal-2x-name: 'menu_cal@2x';
$menu-cal-2x-x: 404px;
$menu-cal-2x-y: 0px;
$menu-cal-2x-offset-x: -404px;
$menu-cal-2x-offset-y: 0px;
$menu-cal-2x-width: 52px;
$menu-cal-2x-height: 72px;
$menu-cal-2x-total-width: 456px;
$menu-cal-2x-total-height: 338px;
$menu-cal-2x-image: '../sprite@2x.png';
$menu-cal-2x: (404px, 0px, -404px, 0px, 52px, 72px, 456px, 338px, '../sprite@2x.png', 'menu_cal@2x', );
$menu-phone-2x-name: 'menu_phone@2x';
$menu-phone-2x-x: 404px;
$menu-phone-2x-y: 72px;
$menu-phone-2x-offset-x: -404px;
$menu-phone-2x-offset-y: -72px;
$menu-phone-2x-width: 52px;
$menu-phone-2x-height: 72px;
$menu-phone-2x-total-width: 456px;
$menu-phone-2x-total-height: 338px;
$menu-phone-2x-image: '../sprite@2x.png';
$menu-phone-2x: (404px, 72px, -404px, -72px, 52px, 72px, 456px, 338px, '../sprite@2x.png', 'menu_phone@2x', );
$menu-search-2x-name: 'menu_search@2x';
$menu-search-2x-x: 404px;
$menu-search-2x-y: 144px;
$menu-search-2x-offset-x: -404px;
$menu-search-2x-offset-y: -144px;
$menu-search-2x-width: 52px;
$menu-search-2x-height: 72px;
$menu-search-2x-total-width: 456px;
$menu-search-2x-total-height: 338px;
$menu-search-2x-image: '../sprite@2x.png';
$menu-search-2x: (404px, 144px, -404px, -144px, 52px, 72px, 456px, 338px, '../sprite@2x.png', 'menu_search@2x', );
$menu-women-2x-name: 'menu_women@2x';
$menu-women-2x-x: 404px;
$menu-women-2x-y: 216px;
$menu-women-2x-offset-x: -404px;
$menu-women-2x-offset-y: -216px;
$menu-women-2x-width: 52px;
$menu-women-2x-height: 72px;
$menu-women-2x-total-width: 456px;
$menu-women-2x-total-height: 338px;
$menu-women-2x-image: '../sprite@2x.png';
$menu-women-2x: (404px, 216px, -404px, -216px, 52px, 72px, 456px, 338px, '../sprite@2x.png', 'menu_women@2x', );
$scroll-down-2x-name: 'scroll_down@2x';
$scroll-down-2x-x: 94px;
$scroll-down-2x-y: 246px;
$scroll-down-2x-offset-x: -94px;
$scroll-down-2x-offset-y: -246px;
$scroll-down-2x-width: 66px;
$scroll-down-2x-height: 66px;
$scroll-down-2x-total-width: 456px;
$scroll-down-2x-total-height: 338px;
$scroll-down-2x-image: '../sprite@2x.png';
$scroll-down-2x: (94px, 246px, -94px, -246px, 66px, 66px, 456px, 338px, '../sprite@2x.png', 'scroll_down@2x', );
$scroll-up-2x-name: 'scroll_up@2x';
$scroll-up-2x-x: 160px;
$scroll-up-2x-y: 246px;
$scroll-up-2x-offset-x: -160px;
$scroll-up-2x-offset-y: -246px;
$scroll-up-2x-width: 66px;
$scroll-up-2x-height: 66px;
$scroll-up-2x-total-width: 456px;
$scroll-up-2x-total-height: 338px;
$scroll-up-2x-image: '../sprite@2x.png';
$scroll-up-2x: (160px, 246px, -160px, -246px, 66px, 66px, 456px, 338px, '../sprite@2x.png', 'scroll_up@2x', );
$turn-ico-2x-name: 'turn_ico@2x';
$turn-ico-2x-x: 0px;
$turn-ico-2x-y: 246px;
$turn-ico-2x-offset-x: 0px;
$turn-ico-2x-offset-y: -246px;
$turn-ico-2x-width: 94px;
$turn-ico-2x-height: 92px;
$turn-ico-2x-total-width: 456px;
$turn-ico-2x-total-height: 338px;
$turn-ico-2x-image: '../sprite@2x.png';
$turn-ico-2x: (0px, 246px, 0px, -246px, 94px, 92px, 456px, 338px, '../sprite@2x.png', 'turn_ico@2x', );
$spritesheet-width: 228px;
$spritesheet-height: 169px;
$spritesheet-image: '../sprite.png';
$spritesheet-sprites: ($footer-logo, $logo-mobile, $menu-cal, $menu-phone, $menu-search, $menu-women, $scroll-down, $scroll-up, $turn-ico, );
$spritesheet: (228px, 169px, '../sprite.png', $spritesheet-sprites, );
$retina-spritesheet-width: 456px;
$retina-spritesheet-height: 338px;
$retina-spritesheet-image: '../sprite@2x.png';
$retina-spritesheet-sprites: ($footer-logo-2x, $logo-mobile-2x, $menu-cal-2x, $menu-phone-2x, $menu-search-2x, $menu-women-2x, $scroll-down-2x, $scroll-up-2x, $turn-ico-2x, );
$retina-spritesheet: (456px, 338px, '../sprite@2x.png', $retina-spritesheet-sprites, );

/*
These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.

The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
*/
$footer-logo-group-name: 'footer_logo';
$footer-logo-group: ('footer_logo', $footer-logo, $footer-logo-2x, );
$logo-mobile-group-name: 'logo_mobile';
$logo-mobile-group: ('logo_mobile', $logo-mobile, $logo-mobile-2x, );
$menu-cal-group-name: 'menu_cal';
$menu-cal-group: ('menu_cal', $menu-cal, $menu-cal-2x, );
$menu-phone-group-name: 'menu_phone';
$menu-phone-group: ('menu_phone', $menu-phone, $menu-phone-2x, );
$menu-search-group-name: 'menu_search';
$menu-search-group: ('menu_search', $menu-search, $menu-search-2x, );
$menu-women-group-name: 'menu_women';
$menu-women-group: ('menu_women', $menu-women, $menu-women-2x, );
$scroll-down-group-name: 'scroll_down';
$scroll-down-group: ('scroll_down', $scroll-down, $scroll-down-2x, );
$scroll-up-group-name: 'scroll_up';
$scroll-up-group: ('scroll_up', $scroll-up, $scroll-up-2x, );
$turn-ico-group-name: 'turn_ico';
$turn-ico-group: ('turn_ico', $turn-ico, $turn-ico-2x, );
$retina-groups: ($footer-logo-group, $logo-mobile-group, $menu-cal-group, $menu-phone-group, $menu-search-group, $menu-women-group, $scroll-down-group, $scroll-up-group, $turn-ico-group, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
  It should be used with a "retina group" variable.

The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/

$icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );

.icon-home {
  @include retina-sprite($icon-home-group);
}
*/
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

/*
The `retina-sprites` mixin generates a CSS rule and media query for retina groups
  This yields the same output as CSS retina template but can be overridden in SCSS

@include retina-sprites($retina-groups);
*/
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
