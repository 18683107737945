.section-sitemap {
	ul, li {
		list-style: none;
		padding:0;
		margin:0;
	}
	ul {
		li {
			text-align:center;
			padding:0;
			margin-bottom:0 !important;
			&:before {
				display:none;
			}
			&:nth-child(4) {
				clear:both;
			}
			&.main {
				float:left;
				width:33%;
				margin-top:30px;
				display:inline-block;
			}
			&.second {
				&:before {
					display:none;
				}
			}
			&.last {
				display:block;
			}
			a.main-item {
				font-size:25px;
				line-height:32px;
				font-weight: 700;
				text-transform: uppercase;
				padding-bottom:20px;
				display:inline-block;
				position:relative;
				margin-bottom:22px;
				color:$midGrey;
				&:hover {
					text-decoration: none;
				}
				&:after {
					border-bottom: 1px solid $midGrey;
				    bottom:0;
				    content: "";
				    height: 2px;
				    position: absolute;
				    width: 35px;
				    left: 50%;
		    		margin-left: -15px;
				}
			}
			a.sub-item {
				font-size:17px;
				font-style:italic;
				margin-bottom:15px;
				display:inline-block;
			}
			a.last-item {
				color:$lightTxt;
				font-size:14px;
				font-weight:300;
				margin-bottom:15px;
				display:inline-block;
				position:relative;
				&:before {
					color: $lightTxt;
				    content: "•";
				    font-size: 36px;
				    left: -18px;
				    position: absolute;
				    top: 0;
				}
			}
		}
	}
}