.cross-section {
	a {
		height:340px;
		display:block;
	}
	.cross-content-item {
		height:340px;
		background:$gold;
		img {
			min-height:340px;
			width:auto !important;
			margin-left:-100px;
			max-height:100%;
		}
		&:hover {
			img {
				-webkit-transition: none;
				-moz-transition: none;
				transition: none;
				margin-left:-100px;
			}
			figcaption {
				background:rgba($gold, 0.85);
				.title-line-bottom {
					top:30%;
					.subtitle {
						@include opacity(1);
					}
					.link-ico {
						@include opacity(1);
					}
					article {
						@include opacity(1);
					}
				}
			}
		}
		figcaption {
			bottom: 0;
			color:$wh;
			height: 50%;
			padding: 7%;
			text-align: left;
			top: auto;
			-webkit-transition: background .4s ease-in-out;
			-moz-transition: background .4s ease-in-out;
			transition: background .4s ease-in-out;
			background:rgba($bl, 0.3);
			height: 100%;
    		padding: 0;
			.subtitle {
				-webkit-transition: opacity .4s ease-in-out;
				-moz-transition: opacity .4s ease-in-out;
				transition: opacity .4s ease-in-out;
				@include opacity(0);
				color: $wh;
				font-style: italic;
				position:absolute;
				top:-40px;
				width:90%;
				left:50%;
				margin-left:-45%;
			}
			article {
				padding:0 12%;
				overflow: hidden;
  				text-overflow: ellipsis;
  				max-height: 500px;
  				margin-top:20px;
				-webkit-transition: opacity .4s ease-in-out;
				-moz-transition: opacity .4s ease-in-out;
				transition: opacity .4s ease-in-out;
				@include opacity(0);

			}
			article p {
				margin-top:20px;
				font-size:15px;
				line-height:18px;
				font-weight:300;
				color:$wh;
			}
			.title-line-bottom {
				-webkit-transition: top .4s ease-in-out;
				-moz-transition: top .4s ease-in-out;
				transition: top .4s ease-in-out;
				text-align:center;
				position:absolute;
				top:50%;
				height:4rem;
				margin-top:-2rem;
				bottom:auto;
				.subtitle {
					&:after {
						content: "";
						position: absolute;
						left: 50%;
						height: 2px;
						bottom: -14px;
						border-bottom: 1px solid $wh;
						width: 40px;
						margin-left:-20px;
					}
				}
				.link-ico {
					-webkit-transition: opacity .4s ease-in-out;
					-moz-transition: opacity .4s ease-in-out;
					transition: opacity .4s ease-in-out;
					@include opacity(0);
					margin-top:20px;
					font-size: 22px;
				}
			}
		}
		&.title-center {
			h3 {
				-webkit-transition: -webkit-transform .2s ease-in-out;
				-moz-transition: -moz-transform .2s ease-in-out;
				transition: transform .2s ease-in-out;
				font-size:25px;
				text-transform:uppercase;
				font-weight:400;
				padding:0 12%;
				line-height:28px;
				position:relative;
				margin:20px 0 auto;
				top:auto !important;
				span {
					font-weight:400;
				}
			}
			&:hover {
				h3 {
					@include transform(scale(0.8));
				}
			}
		}
	}
}
@media (max-width: 768px) {
	.page .cross-section .cross-content-item, .cross-section .cross-content-item figcaption {
		background:transparent !important;
	}
	.page .cross-section .cross-content-item img {
		min-width:100% !important;
		max-height:200% !important;
	}
}