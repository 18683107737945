.contact-section-map {
	padding:0 !important;
	.map-image {
		background:url(../../images/map.jpg);
		background-size:cover;
		background-position:center;
		width:100%;
		height:270px;
		display:block;
	}
}
.contact-section {
	.cabinets-item {
		h4 {
			font-size:18px;
			text-transform: uppercase;
			margin-bottom: 26px;
		}
		span {
			display:block;
			margin-bottom:20px;
			&.phones {
				font-weight:600;
			}
			i {
				color:$gold;
				margin-right: 15px;
			}
			a {
				color:inherit;
				&:hover {
					text-decoration: underline;
				}
			}
			em {
				margin-left:34px;
				font-style: normal;
			}
		}
	}
}

.contacts-doctors {
	small {
		font-size:17px;
		font-style: italic;
		color:$gold;
		margin:30px 0 20px 0;
		display:block;
	}
	a {
		-webkit-transition: all .4s;
		-moz-transition: all .4s;
		-o-transition: all .4s;
		transition: all .4s;
		width:55px;
		height:55px;
		border:solid 1px $gold;
		border-radius:100%;
		margin-right:16px;
		display:inline-block;
		text-align:center;
		line-height:55px;
		overflow:hidden;
		position:relative;
		i {
			-webkit-transition: all .4s;
			-moz-transition: all .4s;
			-o-transition: all .4s;
			transition: all .4s;
			font-size:18px;
			position:absolute;
			top:50%;
			margin-top:-9px;
			width:100%;
			left:0;
			&.hover {
				color:$wh;
				@include transform(translatey(-55px));
			}
		}
		&:hover {
			background:$gold;
			i.hover {
				@include transform(translatey(0));
			}
			i.main {
				@include transform(translatey(55px));
			}
		}
	}
}
.section-contacts-text {
	.contacts-ico {
		text-decoration:none;
		color:$midGrey;
		margin-bottom:22px;
		display:inline-block;
		font-weight:600;
		&:hover {
			text-decoration:none;
		}
		i {
			display:block;
			font-size:20px;
			margin-bottom:5px;
			color:$gold;
		}	
	}
}
.contacts-items {
	.clear {
		clear:both;
	}
	.posts {
		.post {
			background:$wh;
			margin-bottom:40px;
			position:relative;
			overflow:hidden;
			.post-info {
				position:absolute;
				top:225px;
				background:#f5f5f5;
				width:50%;
				left:50%;
				margin-left:-25%;
				padding:18px;
				.post-title {
					color:$midGrey;
					margin:0;
					a {
						color:$midGrey;
						&:hover {
							text-decoration:none;
						}
					}
					text-transform: uppercase;
					font-size:20px;
					line-height:24px;
					font-weight:800;
					font-style:normal;
				}
			}
			.img-cover {
				height:255px;
				width:100%;
			}
			.post-icons {
				margin-top:60px;
				color:$gold;
				font-size:22px;
				margin-bottom:15px;
			}
			.post-contacts {
				color:$midGrey;
				margin-bottom:15px;
				a {
					color:$midGrey;
					&:hover {
						text-decoration:none;
					}
				}
			}
			.post-excerpt {
				padding:0 10px;
				margin-bottom:20px;
				p {
					font-size:13px;
					font-weight:300;
				}
			}
			figure.he-2 {
				.img-cover {
					-webkit-transition: all .4s;
					-moz-transition: all .4s;
					-o-transition: all .4s;
					transition: all .4s;
				}
				&:hover {
					.img-cover {
						opacity:(0.8);
					}
				}
			}
			
			.post-button {
				width:100%;
				text-align:center;
				margin-bottom:20px;
				display:block;
				i {
					font-size:20px;
				}
			}
			
		}
	}
}

.form-container {
	text-align:left;
	margin-top:50px;
	button.btn-gold {
		background:transparent;
		margin-top: 30px;
		padding:14px 100px;
		&:hover {
			background:$gold;
			color:$wh;
		}
	}
	.form-valid-msg {
		font-size:18px;
		font-weight:600;
		span {
			font-size:22px;
			margin-bottom:12px;
			color:$gold;
			display:block;
			font-weight:700;
		}
		&:before {
			border-bottom: 1px solid $gold;
		    top: -30px;
		    content: "";
		    height: 2px;
		    position: absolute;
		    width: 35px;
		    left: 50%;
    		margin-left: -15px;
		}
	}
}