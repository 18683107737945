/*!
FOOTERS
*/

/* GLOBAL */

#footer {
	a {
		-webkit-transition: color .3s ease-in-out;
		-moz-transition: color .3s ease-in-out;
		transition: color .3s ease-in-out;
	}
	#footer-main {
		padding:74px 0;
		background:url(../../images/footer-iace.png);
		background-color:$darkGrey;
		background-size: auto 100%;
		background-position:right;
		background-repeat:no-repeat;
		&.container-fullwidth {
			padding-left: 130px;
			padding-right: 130px;
		}
		.footer-logo {
			display:inline-block;
			margin:0 auto;
			@include retina-sprite($footer_logo-group);
			text-indent:-9999px;
			text-align:center;
		}
		.footer-links {
			margin-top:50px;
			text-align:center;
			a {
				font-size:14px;
				font-weight:600;
				text-transform: uppercase;
				color:#656565;
				display:inline-block;
				margin-right: 18px;
				position:relative;
				line-height:3px;
				&:hover {
					color:$wh;
				}
				&:after {
					content:"•";
					position:absolute;
					right:-15px;
					bottom:0;
				}
				&:last-child {
					&:after {
						content:"";
					}
				}
			}
		}
	}
	#footer-bottom {
		height:80px;
		background:$blackGrey;
		text-align:center;
		line-height:80px;
		.copyright {
			font-size: 0.8rem;
			color: #888888;
		}
		p {
			font-family:Arial;
			font-size:13px;
			line-height:80px;
			margin: 0;
			color:#7a7a7a;
			a {
				color:#7a7a7a;
				&:hover {
					color:$wh;
					text-decoration: underline;
				}
			}
		}
	}
}
.container-fluid {
	padding-right: 30px;
	padding-left: 30px;
}

/* FOOTER REVEAL ON SCROLL */

.footer-reveal {
	#main-content {
		position: relative;
		-moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
		webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
		-o-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
		box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
	}
	#footer {
		position: fixed;
		bottom: 0;
		top: auto;
		left: 0;
		right: 0;
		z-index: -1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
		webkit-transition: all .4s ease-in-out;
		-moz-transition: all .4s ease-in-out;
		transition: all .4s ease-in-out;
	}
}