/* REVOLUTION SLIDER */

#rev_slider {
	display: none;
}

.rev_slider {
	overflow: hidden;
}

.forcefullwidth_wrapper_tp_banner {
	overflow-x: hidden;
}

.main-slider-bloc {
	height:752px;
	width:100%;
	background:$wh;
	display:block;
	&.hslideauto {
		height:auto !important;
	}
}

.tp-caption-title {
	z-index: 5;
	text-align: left;
	white-space: normal !important;
	font-size: 52px;
	line-height: 60px;
	font-weight: 300;
	color: rgba(255, 255, 255, 1);
	padding: 0 0 0 0;
	border-radius: 0 0 0 0;
	letter-spacing: 1px;
	width:500px;
	padding:0 0 12px 0;
	margin-left:25px;
}

.tp-caption-line {
	margin-left:25px;
}

.rev-captions {
	.tp-parallax-wrap {
		left:auto !important;
	}
}

.caption-button {
	width:214px;
	height:60px;
	display:inline-block;
	margin-right: 25px;
	color:#545454 !important;
	letter-spacing: 0 !important;
	line-height:16px;
	font-size:14px;
	padding-top:0 !important;
	padding-bottom:0 !important;
	&:last-child {
		margin-right:0;
	}
	span {
		display: table;
		height:  60px;
		width:100%;
		> span {
			white-space:normal !important;
			display: table-cell;
			vertical-align: middle;
		}
	}

	&.icon-left-effect.btn-lg {
		i {
			top:50%;
			margin-top:-7px;
		}
		&:hover {
			i {
				@include transform(translate3d(10px, 0, 0));
			}
		}
	}
}

.tp-caption-subtitle {
	font-weight: 400;
	min-width: auto;
	min-height: auto;
	white-space: nowrap;
	text-transform: uppercase;
	max-width: auto;
	max-height: auto;
	font-size: 14px;
	line-height: 14px;
	color: rgba(255, 255, 255, 0.9);
	background-color: rgba(255, 255, 255, 0);
	padding: 0 0 0 0;
	border-radius: 0 0 0 0;
	letter-spacing:2px;
	margin-left:25px;
}

.topnav-top .rev_slider_wrapper .slotholder {
	transform: matrix(1, 0, 0, 1, 0, 0) !important;
}

@media (max-width: 480px) {
	.tp-caption .btn {
		display: block;
		margin: auto;
		margin-bottom: 0.9rem;
		text-align: center;
	}
}

.tp-leftarrow {
	width:60px !important;
	height:100px !important;

	margin-left:-10px;
	background:rgba($wh, 0.4) !important;
	&.metis.tparrows::before {
		color:$wh !important;
		line-height:85px !important;
		font-size:26px !important;
	}
	.tp-arr-imgholder {
		@include animate(opacity, 0.75s);
		@include opacity(0);
		visibility:hidden;
		left:60px !important;
		width:150px !important;
	}
	&:hover {
		.tp-arr-imgholder {
			visibility:visible;
			@include opacity(1);
		}
	}
}
.tp-rightarrow {
	width:60px !important;
	height:100px !important;

	margin-right:-10px;
	background:rgba($wh, 0.4) !important;
	&.metis.tparrows::before {
		color:$wh !important;
		line-height:85px !important;
		font-size:26px !important;
	}
	.tp-arr-imgholder {
		@include animate(opacity, 0.75s);
		@include opacity(0);
		visibility:hidden;
		right:60px !important;
		left:auto !important;
		width:150px !important;
	}
	&:hover {
		.tp-arr-imgholder {
			visibility:visible;
			@include opacity(1);
		}
	}
}
.persephone .tp-bullet {
	top:auto !important;
	bottom:30px !important;
	width:24px !important;
	height:2px !important;
	border:none !important;
	background:$wh !important;
	&.selected {
		background:#c39d62 !important;
	}
}

@media (max-width: 768px) {
	.main-slider-bloc {
		.tp-mask-wrap {
			a.caption-button {
				display:none !important;
			}
		}
	}
}
@media (max-width: 644px) {
	.main-slider-bloc {
		.tp-mask-wrap {
			overflow:visible !important;
			.tp-caption-subtitle {
				display:none !important;
			}
			.tp-caption {
				display:none !important;	
			}
			.tp-caption-title {
				display:none !important;
				&:after {
					display:none !important;
				}
			}
		}
	}
}