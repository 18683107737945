.faq-page {
	.panel {
		margin-bottom:10px;
		.panel-heading {
			h4.panel-title {
				margin:0;
				padding:0;
				a {
					padding:16px 65px 16px 42px;
					display:block;
					background:$bgGrey;
					min-height:75px;
					text-align:left;
					color:$midGrey;
					font-style: normal;
					font-size:18px;
					line-height:50px;
					font-weight:600;
					text-decoration: none;
					position:relative;
					&:hover {
						text-decoration: none;
						background:darken($bgGrey, 10%);
					}
					span {
						vertical-align:middle;
						display:inline-block;
						line-height:22px;
					}
					i {
						position:absolute;
						right:20px;
						top:50%;
						margin-top:-6px;
						color:$gold;
						-webkit-transition: all .4s;
						-moz-transition: all .4s;
						-o-transition: all .4s;
						transition: all .4s;
						@include transform(rotate(90deg));
					}
					&.collapsed {
						i {
							@include transform(rotate(0));
						}
					}
				}
			}
		}
		.panel-collapse {
			text-align:left;
			.panel-body {
				padding:24px 40px 4px;
			}
		}
	}
}