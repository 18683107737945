/*!
BUTTONS
*/

/* General */

.btn {
	font-size: 1rem;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	padding: 0 1.4rem;
	height: auto;
	line-height: 34px;
	border: none;
	z-index: 2;
	position: relative;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	-webkit-font-smoothing: subpixel-antialiased;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	color: #fff;
	&.focus, &:focus, &:hover {
		color: #fff;
	}
	&.btn-important {
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 1px;
	}
}

.btn-default {
	&.active, &.focus, &:active, &:focus, &:hover {
		background: none;
	}
}

.open > .dropdown-toggle.btn-default {
	background: none;
}

.btn {
	&:hover, &:focus {
		-webkit-box-shadow: none;
		box-shadow: none;
		outline: 0;
		outline: none;
		text-decoration: none;
	}
	&:active, &.active {
		-webkit-box-shadow: none;
		box-shadow: none;
		outline: 0;
		outline: none;
		text-decoration: none;
		&:focus {
			-webkit-box-shadow: none;
			box-shadow: none;
			outline: 0;
			outline: none;
			text-decoration: none;
		}
	}
	&.btn-default, &.btn-link {
		color: #121212;
	}
	i {
		font-size: .9rem;
		padding-right: .6rem;
	}
}

.input-group-btn .btn i {
	padding-right: 0;
}

.btn {
	span {
		z-index: 4;
	}
	&.lighten-3, &.lighten-4, &.lighten-5, &.accent-1, &.lighten-3:hover, &.lighten-4:hover, &.lighten-5:hover, &.accent-1:hover {
		color: #000;
	}
	&[disabled] {
		opacity: 0.35;
	}
	&.btn-rounded {
		-webkit-border-radius: 50px;
		-moz-border-radius: 50px;
		border-radius: 50px;
	}
	&.btn-square {
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
	}
}

/* Button Border Radius */

.btn-square .btn {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

/* Button Embossed */

.btn-embossed {
	-webkit-box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15) !important;
	box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15) !important;
	&:hover {
		-webkit-box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15) !important;
		box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15) !important;
	}
	&:active {
		-webkit-box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.15);
		box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.15);
	}
}

/* Buttton Sizes */

.btn-sm {
	height: auto;
	font-size: .8rem;
	line-height: .9rem;
	padding: 8px 14px;
}

.btn-lg {
	height: auto;
	line-height: 1.3rem;
	padding: 14px 26px;
	&.btn-bordered:not(.icon-left-effect):not(.icon-right-effect) {
		padding: 12px 26px;
	}
}

.btn-hg {
	height: auto;
	font-size:14px;
	font-weight:600;
	line-height:22px;
	padding: 13px 20px;
}

/* Button Colors */

.btn-primary {
	background-color: $gold;
	&.active, &.focus, &:active, &:focus, &:hover {
		background-color: $gold;
	}
}

.open > .dropdown-toggle.btn-primary {
	background-color: $gold;
}

.btn-primary:hover {
	background-color: $gold;
}

.btn-dark {
	background-color: #383737;
	color: #fff !important;
	&:hover {
		background-color: #141414;
	}
}

.btn-white {
	background-color: #fff;
	color: #121212 !important;
	&:hover, &:focus, &:active {
		background-color: #EBEBEB;
		color: #121212 !important;
	}
}

.btn-default {
	background-color: #E1E1E1;
	&:hover {
		background-color: #D2D2D2;
		color: #262626 !important;
	}
}

.btn-red {
	background-color: #d9534f;
}

.btn-link {
	background: none;
	border: none;
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0;
	&:hover, &:focus {
		background: none;
		border: none;
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 0;
	}
	&::after {
		content: "";
		width: 100%;
		display: inline-block;
		position: absolute;
		bottom: 4px;
		left: 0;
		border-bottom: 2px solid rgba(255, 255, 255, 0.5);
	}
	&.btn-white {
		color: rgba(255, 255, 255, 0.5) !important;
		&:hover {
			color: rgba(255, 255, 255, 0.7) !important;
		}
	}
}

.btn-blue-dark {
	background-color: #192F43;
	&:hover {
		background-color: #142534;
	}
}

/* Button Bordered */

.btn.btn-bordered:not(.btn-sm):not(.btn-lg):not(.btn-hg) {
	height: 36px;
	line-height: 34px;
}

.btn-primary.btn-bordered {
	color: $gold !important;
	border: 1px solid $gold;
	background: none;
	&.c-white {
		color: #fff !important;
	}
	&:hover {
		background: $gold;
		color: #fff !important;
	}
}

.btn-dark.btn-bordered {
	color: #383737 !important;
	border: 2px solid #383737;
	background: none;
	&:hover {
		background: #232323;
		color: #fff !important;
	}
}

.btn-white.btn-bordered {
	color: #fff !important;
	border: 2px solid rgba(255, 255, 255, 0.8);
	background: none;
	&:hover {
		background: #fff;
		color: #121212 !important;
	}
}

.btn-default.btn-bordered {
	color: #232323 !important;
	border: 2px solid #E1E1E1;
	background: none;
	&:hover {
		background: #D2D2D2;
		color: #232323 !important;
	}
}

.btn-dark.btn-bordered.btn[disabled] {
	background: #eee;
}

.btn-bordered.btn-lg:not(.icon-left-effect):not(.icon-right-effect) {
	padding: 12px 26px;
}

/* Effects */

.icon-left-effect {
	padding-left: 50px;
	padding-right: 30px;
	overflow: hidden;
	-webkit-transition: background-color 0.3s, border-color 0.3s, color 0.3s;
	transition: background-color 0.3s, border-color 0.3s, color 0.3s;
	> span {
		display: inline-block;
		-webkit-transform: translate3d(-10px, 0, 0);
		transform: translate3d(-10px, 0, 0);
		-webkit-transition: -webkit-transform 0.3s;
		transition: transform 0.3s;
		-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
		transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	}
	i {
		position: absolute;
		left: 20px;
		top: 8px;
		font-size: 18px;
		width: 30px;
		-webkit-transform: translate3d(-40px, 0, 0);
		transform: translate3d(-40px, 0, 0);
		-webkit-transition: -webkit-transform 0.3s;
		transition: transform 0.3s;
		-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
		transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	}
	&:hover {
		> span {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
		i {
			-webkit-transform: translate3d(-5px, 0, 0);
			transform: translate3d(-5px, 0, 0);
		}
	}
	&.btn-lg i {
		top: 13px;
		font-size: 20px;
		width: 32px;
		left: 16px;
	}
}

.icon-right-effect.btn-lg i {
	top: 13px;
	font-size: 20px;
	width: 32px;
	left: 16px;
}

.icon-left-effect.btn-lg.btn-rounded i, .icon-right-effect.btn-lg.btn-rounded i {
	font-size: 16px;
}

.icon-left-effect.btn-hg i, .icon-right-effect.btn-hg i {
	top: 16px;
	font-size: 20px;
	width: 32px;
	left: 16px;
}

.icon-left-effect.btn-hg.btn-rounded i, .icon-right-effect.btn-hg.btn-rounded i {
	font-size: 20px;
}

.icon-left-effect.btn-hg:hover i {
	-webkit-transform: translate3d(-3px, 0, 0);
	transform: translate3d(-3px, 0, 0);
}

.icon-right-effect {
	padding-left: 30px;
	padding-right: 50px;
	overflow: hidden;
	-webkit-transition: background-color 0.3s, border-color 0.3s, color 0.3s;
	transition: background-color 0.3s, border-color 0.3s, color 0.3s;
	> span {
		display: inline-block;
		-webkit-transform: translate3d(10px, 0, 0);
		transform: translate3d(10px, 0, 0);
		-webkit-transition: -webkit-transform 0.3s;
		transition: transform 0.3s;
		-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
		transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	}
	i {
		position: absolute;
		right: 10px;
		top: 8px;
		font-size: 18px;
		-webkit-transform: translate3d(40px, 0, 0);
		transform: translate3d(40px, 0, 0);
		-webkit-transition: -webkit-transform 0.3s;
		transition: transform 0.3s;
		-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
		transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	}
	&:hover {
		> span {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
		i {
			-webkit-transform: translate3d(7px, 0, 0);
			transform: translate3d(7px, 0, 0);
		}
	}
	&.btn-lg i {
		left: 18px;
		right: 10px;
		left: auto;
	}
}

.btn:before {
	font-family: 'Nucleo Outline' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	position: relative;
	-webkit-font-smoothing: antialiased;
}

.btn-icon-remove:before {
	content: "\e6af";
}

.btn-icon-cart:before {
	content: "\e77a";
}

.btn-icon-settings:before {
	content: "\e6a7";
}

.btn-icon-picture:before {
	content: "\e805";
}

.btn-gold {
	-webkit-transition: all .4s;
	-moz-transition: all .4s;
	-o-transition: all .4s;
	transition: all .4s;
	font-size:14px;
	font-weight:600;
	text-transform: uppercase;
	border:solid 1px $gold;
	padding:14px 25px;
	color:$gold;
	display:inline-block;
	border-radius:50vh;
	&:hover {
		text-decoration: none !important;
		background-color:$gold;
		color:$wh;
	}
}

.doctor-team-button {
	margin:0 auto;
	margin-top:30px;
	display:block;
}

.icon-top-effect {
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	span {
		display: inline-block;
		width: 100%;
		height: 100%;
		-webkit-transition: all 0.3s;
		-webkit-backface-visibility: hidden;
		-moz-transition: all 0.3s;
		-moz-backface-visibility: hidden;
		transition: all 0.3s;
		backface-visibility: hidden;
	}
	&:before {
		position: absolute;
		height: 100%;
		width: 100%;
		line-height: 2.5;
		font-size: 1.3rem;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		transition: all 0.3s;
	}
	&:active:before {
		color: #703b87;
	}
	&:hover span {
		-webkit-transform: translateY(300%);
		-moz-transform: translateY(300%);
		-ms-transform: translateY(300%);
		transform: translateY(300%);
	}
	&:before {
		left: 0;
		top: -100%;
	}
	&:hover:before {
		top: 0;
	}
}

@media (max-width: 1200px) {
	.btn-hg {
		height: auto;
		font-size: 1rem;
		line-height: 1.3rem;
		padding: 14px 20px;
	}
	.icon-left-effect.btn-hg i, .icon-right-effect.btn-hg i {
		top: 16px;
		font-size: 20px;
		width: 32px;
		left: 18px;
	}
	.icon-left-effect.btn-hg {
		padding-left: 50px;
		padding-right: 30px;
		&.btn-rounded i {
			font-size: 1rem;
		}
	}
	.icon-right-effect.btn-hg.btn-rounded i {
		font-size: 1rem;
	}
}

@media (max-width: 768px) {
	.btn {
		margin-bottom: 1.4rem;
	}
}

@media (max-width: 500px) {
	.icon-left-effect {
		padding-left: 25px;
		padding-right: 15px;
	}
	.icon-right-effect {
		padding-left: 15px;
		padding-right: 25px;
	}
	.icon-left-effect i, .icon-right-effect i, .icon-left-effect.btn-lg i, .icon-right-effect.btn-lg i {
		top: 9px;
		font-size: 15px;
		width: 20px;
		left: 15px;
	}
}