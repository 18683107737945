.glossary-container {
	.alpha-glossary {
		.letter {
			text-align:center;
			a {
				font-size:20px;
				font-weight:700;
				display:block;
				width:50px;
				height:50px;
				line-height:50px;
				text-align:center;
				border-radius:100%;
				background:$buttonGrey;
				color:lighten($buttonGrey, 10%);
				margin:0 auto;
				cursor:not-allowed;
				&.active {
					cursor:pointer;
					background:$wh;
					color:$darkGrey;
				}
				&.current {
					cursor:pointer;
					background:$gold;
					color:$wh;
				}
			}
		}
		.owl-prev {
			position:absolute;
			left:-40px;
			top:-3px;
			background:none;
			border-radius:0;
			font-size:28px;
			&:hover {
				background:none;
			}
		}
		.owl-next {
			position:absolute;
			right:-40px;
			top:-3px;
			background:none;
			border-radius:0;
			font-size:28px;
			&:hover {
				background:none;
			}
		}
	}
	.glossary-definitions {
		-webkit-transition: all .4s;
		-moz-transition: all .4s;
		-o-transition: all .4s;
		transition: all .4s;
		margin-top:35px;
		position:relative;
		background:#424242;
		padding:5% 0 0 5%;
		&.transparent {
			opacity:0;
		}
		&:after {
			clear: both;
    		content: "";
    		display: block;
		}
		.element-item {
			float: left;
		    height: 110px;
		    margin:0% 5% 4% 0;
		    position: relative;
		    width:calc(20% - 16px);
		    max-width:20%;
		    overflow:hidden;
			a {
				-webkit-transition: all .4s;
				-moz-transition: all .4s;
				-o-transition: all .4s;
				transition: all .4s;
				padding:16px;
				display:block;
				text-align:center;
				font-size:15px;
				line-height:18px;
				text-transform: uppercase;
				color:#545454;
				font-weight:600;
				height: 110px;
				background:#313131;
				position:relative;
				i {
					font-size:24px;
					display:block;
					margin:0 auto;
					margin-top:12px;
				}
				&:hover {
					background:$gold;
					color:$wh;
				}
			}
		}
		.bg-grey {
			.row {
				padding:45px 45px 0 45px;
			}
			
		}
	}
}