%clearfix {
    *zoom: 1;
    &:before, &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin pull($dir) {
    float: $dir !important;
}

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin font-text($size, $case, $weight) {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: $weight;
    text-transform: $case;
    font-size: $size;
    line-height: $size;
}

@mixin border-radius-pie($block, $radius) {
    @include border-radius($radius);
    behavior: url(/js/PIE.htc);
    @if $block == 'inline-block' {
        @include inline-block;
    } @else if $block == 'inline' {
        display: inline;
    } @else if $block == 'block' {
        display: block;
    }
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin vertical-align {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin valign {
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@mixin centerer($horizontal: true, $vertical: true) {
    position: absolute;
    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    } @else if ($horizontal) {
        left: 50%;
        transform: translate(-50%, 0);
    } @else if ($vertical) {
        top: 50%;
        transform: translate(0, -50%);
    }
}

@mixin placeholder($color) {
    ::-webkit-input-placeholder {
        color: $color;
    }
    :-moz-placeholder { /* Firefox 18- */
        color: $color;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: $color;
    }
    :-ms-input-placeholder {
        color: $color;
    }
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin animate($what:all, $time:0.5s, $type:ease) {
    @include transition($what, $time, $type);
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes $animation-name {
        @content;
    }
    @-moz-keyframes $animation-name {
        @content;
    }
    @-ms-keyframes $animation-name {
        @content;
    }
    @-o-keyframes $animation-name {
        @content;
    }
    @keyframes $animation-name {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@mixin noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin container-fixed($gutter: $grid-gutter-width) {
    margin-right: auto;
    margin-left: auto;
    padding-left: ($gutter / 2);
    padding-right: ($gutter / 2);
    @extend %clearfix;
}

/*
** PROJECT MIXINS
*/

@mixin ubun($size, $case:normal, $weight:400) {
    -webkit-font-smoothing: antialiased;
    font-family: 'Ubuntu', sans-serif;
    font-weight: $weight;
    text-transform: $case;
    font-size: $size;
    line-height: $size;
}

@mixin danc($size, $case:normal, $weight:400) {
    -webkit-font-smoothing: antialiased;
    font-family: 'Dancing Script', cursive;
    font-weight: $weight;
    text-transform: $case;
    font-size: $size;
    line-height: $size;
}

@mixin link-more($border-size) {
    border: $border-size solid;
    border-radius: 50em;
    text-align: center;
    display: block;
    width: 30px;
    height: 30px;
    line-height: 25px;
}

@mixin linearGradient($top, $bottom) {
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(right, $top 50%, $bottom 50%); /* FF3.6+ */
    background: -webkit-gradient(linear, left bottom, right bottom, color-stop(0.5, $top), color-stop(1, $bottom)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(right, $top 50%, $bottom 50%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(right, $top 50%, $bottom 50%); /* Opera 11.10+ */
    background: -ms-linear-gradient(right, $top 50%, $bottom 50%); /* IE10+ */
    background: linear-gradient(to right, $top 50%, $bottom 50%); /* W3C */
}

@mixin arrow-down($color) {
    &:after {
        z-index: 9;
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -35px;
        width: 0;
        height: 0;
        border-top: solid 35px $color;
        border-left: solid 35px transparent;
        border-right: solid 35px transparent;
    }
}

@mixin infog-title() {
    text-align: center;
    &:before, &:after {
        content: "";
        width: 52px;
        height: 7px;
        display: inline-block;
        vertical-align: middle;
    }
    &:before {
        background: url(../../images/info-stroke-l.png);
        margin-right: 15px;
    }
    &:after {
        background: url(../../images/info-stroke-r.png);
        margin-left: 15px;
    }
}

@mixin info-reset-pos() {
    left: 0 !important;
    margin: 10px auto !important;
}

// generic transform
@mixin transform($transforms) {
       -moz-transform: $transforms;
         -o-transform: $transforms;
        -ms-transform: $transforms;
    -webkit-transform: $transforms;
          transform: $transforms;
}

@mixin font($font-size, $text-transform, $weight) {
    font-size:$font-size;
    text-transform:$text-transform;
    font-weight:$weight;
}
@mixin box-shadow( $shadow1, $shadow2:false, $shadow3:false, $shadow4:false, $shadow5:false, $shadow6:false, $shadow7:false, $shadow8:false, $shadow9:false ) {
    $params: $shadow1;
    @if $shadow2 { $params: $shadow1, $shadow2; }
    @if $shadow3 != false { $params: $shadow1, $shadow2, $shadow3; }
    @if $shadow4 != false { $params: $shadow1, $shadow2, $shadow3, $shadow4; }
    @if $shadow5 != false { $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5; }
    @if $shadow6 != false { $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5, $shadow6; }
    @if $shadow7 != false { $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5, $shadow6, $shadow7}
    @if $shadow8 != false { $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5, $shadow6, $shadow7, $shadow8 }
    @if $shadow9 != false { $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5, $shadow6, $shadow7, $shadow8, $shadow9 }
     
    -webkit-box-shadow: $params;
       -moz-box-shadow: $params;
            box-shadow: $params;
}