#team_member {
	a {
		display:block;
		span, em, i {
			display:block;
		}
		img {
			-webkit-transition: all 0.3s;
			-moz-transition: all 0.3s;
			transition: all 0.3s;
			border-radius:50%;
			max-width:105px;
			border:solid 2px $bgGrey;
		}
		&:hover {
			img {
				border:solid 8px $bgGrey;		
			}
		}
		.function {
			font-size:17px;
			font-weight:300;
			font-style:italic;
		}
		.name {
			font-size:20px;
			font-weight:700;
			color:$midGrey;
			text-transform:uppercase;
		}
		em {
			font-size:14px;
			font-weight:300;
			font-style:normal;
			text-transform:uppercase;
			color:$midGrey;
		}
		i {
			font-size:24px;
		}
	}
}


@media (max-width: 768px) {
	#team_member {
		a {
			text-align:center;
		}
	}
}
@media (max-width: 540px) {
	#team_member {
		.team-member {
			margin-bottom:40px;
			position:relative;
			&:after {
				content:"";
				width:30%;
				height:1px;
				background:$bgGrey;
				position:absolute;
				left:50%;
				margin-left:-15%;
				bottom:-20px;
				display:block;
			}
			&:last-child {
				&:after {
					display:none;
				}
			}
		}
	}
}

.team-line {
	padding-bottom:60px;
	border-bottom:solid 1px #ededed;
	margin-bottom:60px;
	&:last-child {
		border-bottom:0;
	}
	.img-cover {
		width:90%;
	}
	&.is-odd {
		.img-cover {
			float:right;
		}
	}
}