table {
    background-color: $wh;
    margin-bottom: 2rem;
    max-width: 100%;
    width: 100%;
}
table > thead > tr, table > tbody > tr, table > tfoot > tr {
    transition: all 0.3s ease 0s;
}
table > thead > tr > th, table > tbody > tr > th, table > tfoot > tr > th, table > thead > tr > td, table > tbody > tr > td, table > tfoot > tr > td {
    border-top: 0 none;
    padding: 1.6rem;
    text-align: left;
    transition: all 0.3s ease 0s;
    vertical-align: top;
}
table > thead > tr > th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    color: #757575;
    font-weight: 400;
    vertical-align: bottom;
}
table > caption + thead > tr:first-child > th, table > colgroup + thead > tr:first-child > th, table > thead:first-child > tr:first-child > th, table > caption + thead > tr:first-child > td, table > colgroup + thead > tr:first-child > td, table > thead:first-child > tr:first-child > td {
    border-top: 0 none;
}
table > tbody + tbody {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}
table {
    background-color: #fff;
}
table > thead > tr > th, table > tbody > tr > th, table > tfoot > tr > th, table > thead > tr > td, table > tbody > tr > td, table > tfoot > tr > td {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: -moz-use-text-color -moz-use-text-color #e0e0e0;
    border-image: none;
    border-style: none none solid;
    border-width: 0 0 1px;
}
table > thead > tr > th, table > thead > tr > td {
    border-bottom-width: 2px;
}




@media screen and (max-width: 768px) {
  table {
    margin-bottom: 0;
    background-color: $wh !important;
  }
  table > thead,
  table > tfoot {
    display: none;
  }
  table > tbody {
    display: block;
  }
  table > tbody > tr {
    display: block;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    margin-bottom: 1.6rem;
  }
  table > tbody > tr > td {
    background-color: #fff;
    display: block;
    vertical-align: middle;
    text-align: right;
  }
  table > tbody > tr > td[data-title]:before {
    content: attr(data-title);
    float: left;
    font-size: inherit;
    font-weight: 400;
    color: #757575;
  }
  .table-responsive-vertical.shadow-z-1 {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .table-responsive-vertical.shadow-z-1 > .table > tbody > tr {
    border: none;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  }
  table-bordered {
    border: 0;
  }
  table-bordered > tbody > tr > td {
    border: 0;
    border-bottom: 1px solid #e0e0e0;
  }
  table-bordered > tbody > tr > td:last-child {
    border-bottom: 0;
  }
  table-striped > tbody > tr > td,
  table-striped > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
  table-striped > tbody > tr > td:nth-child(odd) {
    background-color: #f5f5f5;
  }
  table-hover > tbody > tr:hover > td,
  table-hover > tbody > tr:hover {
    background-color: #fff;
  }
  table-hover > tbody > tr > td:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
}