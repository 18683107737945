/*!
HOVER EFFECTS
*/

/* General */

figure {
	img {
		width: 100%;
		max-width: none;
		height: auto;
		-webkit-transition: all .35s;
		transition: all .35s;
		-webkit-backface-visibility: hidden;
	}
	background: #253541;
	display: inline-block;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.mfp-content figure {
	background: none;
}

figure {
	h1, h2, h3, h4, h5 {
		color: #fff;
	}
	h3 {
		font-size: 2.2rem;
	}
	h4 {
		font-size: 1.5rem;
	}
	p {
		font-size: 1.2rem;
	}
	figcaption {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		padding: 0;
		width: 100%;
		margin-top: 0;
		font-weight: 300;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
		-webkit-transition: -webkit-transform .35s;
		-moz-transform: transform .35s;
		-ms-transform: transform .35s;
		transition: transform .35s;
	}
	h3, h4, p {
		margin-top: 0;
		font-weight: 300;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
		-webkit-transition: -webkit-transform .35s;
		-moz-transform: transform .35s;
		-ms-transform: transform .35s;
		transition: transform .35s;
	}
	&:hover {
		figcaption, h3, h4, p {
			text-shadow: none;
		}
	}
	h3 span, h4 span {
		font-weight: 600;
	}
	h3 a, h4 a {
		color: #fff;
		font-weight: 600;
		text-decoration: none;
	}
}

[data-link] {
	cursor: pointer;
}

/* Tags */

.tags span {
	color: #fff;
	font-size: 1rem;
	font-weight: normal;
	&:after {
		content: "/";
		padding-left: 8px;
		padding-right: 4px;
		opacity: 0.2;
	}
	&:last-child:after {
		display: none;
	}
}

.caption-visible .tags span {
	color: #000;
}

/* Hover Icons Links */

figure {
	.hover-icons {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		.hover-icons-wrapper {
			position: absolute;
			top: 50%;
			height: 40px;
			width: 100%;
			text-align: center;
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
			a {
				-webkit-transition-delay: 0s;
				transition-delay: 0s;
				opacity: 0;
				background: #fff;
				display: inline-block;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				margin: 0 .5rem;
				text-align: center;
				color: #232323;
				opacity: 0;
				vertical-align: middle;
				-webkit-transition: all .3s ease-in-out;
				-moz-transition: all .3s ease-in-out;
				transition: all .3s ease-in-out;
				-webkit-transform: translateY(-1rem);
				-moz-transform: translateY(-1rem);
				-ms-transform: translateY(-1rem);
				transform: translateY(-1rem);
			}
			p, h4 {
				margin-bottom: 1.5rem;
				opacity: 0;
				color: #fff;
				-webkit-transition: all .3s ease-in-out;
				-moz-transition: all .3s ease-in-out;
				transition: all .3s ease-in-out;
				-webkit-transform: translateY(-0.5rem);
				-moz-transform: translateY(-0.5rem);
				-ms-transform: translateY(-0.5rem);
				transform: translateY(-0.5rem);
			}
		}
	}
	&:hover .hover-icons .hover-icons-wrapper a {
		opacity: .8;
		-webkit-transform: translateY(0px);
		-moz-transform: translateY(0px);
		-ms-transform: translateY(0px);
		transform: translateY(0px);
	}
	.hover-icons .hover-icons-wrapper a i {
		font-size: 18px;
		padding-top: 11px;
	}
	&:hover .hover-icons .hover-icons-wrapper {
		a:hover {
			opacity: 1;
		}
		p {
			opacity: 1;
			-webkit-transform: translateY(0px);
			-moz-transform: translateY(0px);
			-ms-transform: translateY(0px);
			transform: translateY(0px);
			text-shadow: none;
		}
		h4 {
			opacity: 1;
			-webkit-transform: translateY(0px);
			-moz-transform: translateY(0px);
			-ms-transform: translateY(0px);
			transform: translateY(0px);
			text-shadow: none;
			-webkit-transition-delay: 0s;
			transition-delay: 0s;
		}
		p {
			-webkit-transition-delay: .03s;
			transition-delay: .03s;
		}
		a {
			-webkit-transition-delay: .06s;
			transition-delay: .06s;
			&:hover {
				color: #333;
			}
		}
	}
}

/* Hover Effect 1: image slide to right */

.grid .item figure.he-1 {
	width: 101%;
}

figure.he-1 {
	a:focus {
		color: #fff;
	}
	img {
		width: -webkit-calc(100% + 55px) !important;
		width: calc(100% + 55px) !important;
		margin-left: -50px;
	}
	figcaption {
		top: auto;
		bottom: 0;
		height: 50%;
		text-align: left;
		padding: 7%;
		color: #fff;
	}
	h1, h2, h3, h4, p {
		position: absolute;
		margin-bottom: 0;
		bottom: 4rem;
		left: 3rem;
		-webkit-transform: translate3d(0, 2rem, 0);
		transform: translate3d(0, 2rem, 0);
	}
	&.title-center {
		h1, h2, h3, h4, p {
			left: 0;
		}
	}
	p {
		bottom: 2rem;
		margin-bottom: 0;
		color: #fff;
		opacity: 0;
		-webkit-transition: opacity .2s,-webkit-transform .35s;
		-moz-transform: opacity .2s,transform .35s;
		-ms-transform: opacity .2s,transform .35s;
		transition: opacity .2s,transform .35s;
	}
	&:hover {
		img {
			opacity: 0.5;
		}
		p {
			opacity: 0.8;
			-webkit-transition-delay: .05s;
			transition-delay: .05s;
		}
		img {
			margin-left: 0;
		}
		h3, h4, p {
			-webkit-transform: translate3d(0, 0, 0);
			-moz-transform: translate3d(0, 0, 0);
			-ms-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
	}
	h3 a:hover, h4 a:hover {
		color: #fff;
		text-decoration: none;
	}
	&.title-center {
		figcaption {
			height: 100%;
			padding: 0;
		}
		h1, h2, h3, h4, p {
			-webkit-transform: translate3d(0, 0, 0);
			-moz-transform: translate3d(0, 0, 0);
			-ms-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
		h1, h2, h3, h4 {
			bottom: auto;
			top: 50%;
			margin-top: -1rem;
			height: 2rem;
			margin-bottom: 0;
			text-align: center;
			width: 100%;
		}
	}
}

.owl-carousel figure.he-1 {
	background: #253541;
	margin-bottom: 0;
}

figure {
	&.he-1 {
		figcaption.caption-dark {
			color: #121212;
			text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.6);
			h2, h3, h4 {
				color: #121212;
				text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.6);
			}
		}
		.caption-dark p {
			color: #121212;
			text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.6);
		}
		&:hover {
			figcaption.caption-dark h4, .caption-dark p {
				text-shadow: none;
			}
			figcaption.caption-dark, .caption-dark p {
				color: #121212;
			}
		}
	}
	&.he-2 {
		width: 100.05% !important;
		h3, h4, p, a {
			text-shadow: none;
			color: #333;
		}
		.hover-icons .hover-icons-wrapper {
			margin-top: 0;
		}
		&.caption-visible .hover-icons .hover-icons-wrapper {
			margin-top: -22px;
		}
		a {
			line-height: normal;
			-webkit-transition: all .2s ease-in-out;
			-moz-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;
		}
		img {
	  /*margin-top: -50px;*/
	}
	a:hover {
		color: #E2785D;
	}
	&:hover img {
		opacity: 0.8;
		-webkit-transform: translateY(-2rem);
		-moz-transform: translateY(-2rem);
		-ms-transform: translateY(-2rem);
		transform: translateY(-2rem);
	}
	figcaption {
		top: auto;
		bottom: 0;
		padding: 0.8em;
		height: 4em;
		background: #fff;
		color: #3c4a50;
		-webkit-transition: -webkit-transform .35s;
		transition: transform .35s;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
	h3, h4 {
		float: left;
	}
	p.icon-links a {
		float: right;
		color: #3c4a50;
		font-size: 1.4em;
	}
	&:hover p.icon-links a {
		&:hover, &:focus {
			color: #252d31;
		}
	}
	h3, h4, p.icon-links a {
		-webkit-transition: -webkit-transform .35s;
		transition: transform .35s;
		-webkit-transform: translate3d(0, 200%, 0);
		transform: translate3d(0, 200%, 0);
	}
	h3 {
		display: inline-block;
	}
	h4 {
		display: inline-block;
		line-height: 37px;
	}
	&:hover {
		figcaption, h3, h4, p.icon-links a {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
		h3, h4 {
			margin-bottom: 0;
			-webkit-transition-delay: .05s;
			transition-delay: .05s;
		}
	}
	p.icon-links a {
		margin: 0 .7rem;
		margin-top: 6px;
	}
	&:hover p.icon-links a {
		&:nth-child(3) {
			-webkit-transition-delay: .1s;
			transition-delay: .1s;
		}
		&:nth-child(2) {
			-webkit-transition-delay: .15s;
			transition-delay: .15s;
		}
		&:first-child {
			-webkit-transition-delay: .2s;
			transition-delay: .2s;
		}
	}
	&.no-caption .hover-icons {
		margin-top: 0;
	}
	.hover-icons .hover-icons-wrapper p {
		font-size: 1.4rem;
		margin-bottom: 0;
		font-weight: 300;
		letter-spacing: 1px;
	}
	&.caption-center {
		figcaption {
			height: auto;
			position: absolute;
			p, h3, h4 {
				text-align: center;
				display: block;
				float: none;
			}
			p {
				margin-bottom: 0;
			}
			h3 {
				margin-bottom: 0.8rem;
				text-transform: uppercase;
			}
			h4 {
				margin-bottom: 0.8rem;
				text-transform: uppercase;
				font-size: 1rem;
				line-height: 1.2;
				margin-bottom: 0.3rem;
			}
			p {
				font-size: 0.9rem;
				font-weight: normal;
				color: rgba(0, 0, 0, 0.7);
			}
		}
		.hover-icons .hover-icons-wrapper {
			margin-top: -32px;
		}
	}
	&.caption-visible figcaption {}
	&.no-caption figcaption {
		margin-bottom: 0;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	&.caption-visible {
		figcaption, h3, h4 {
			margin-bottom: 0;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
	}
	&.no-caption:hover img, &.caption-visible:hover img {
		transform: scale(1.05);
	}
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	&:hover {
		box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
	}
}
&.he-3 {
	width: 101%;
	.hover-icons .hover-icons-wrapper {
		height: auto;
	}
	&:hover img {
		opacity: 0.8;
	}
}
&.he-4 {
	background: transparent !important;
}
}

/* Hover Effect 2: image zoom */

/* Hover Effect 3: image opacity effect */

/* Hover Effect 4 */

/* Black & White Effect */

.black-white-effect {
	-webkit-filter: grayscale(100%);
	-moz-filter: grayscale(100%);
	-ms-filter: grayscale(100%);
	-o-filter: grayscale(100%);
	filter: grayscale(100%);
	filter: gray;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	img {
		-webkit-filter: grayscale(100%);
		-moz-filter: grayscale(100%);
		-ms-filter: grayscale(100%);
		-o-filter: grayscale(100%);
		filter: grayscale(100%);
		filter: gray;
		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}
	&:hover {
		-webkit-filter: grayscale(0%);
		-moz-filter: grayscale(0%);
		-ms-filter: grayscale(0%);
		-o-filter: grayscale(0%);
		filter: grayscale(0%);
		filter: none;
		img {
			-webkit-filter: grayscale(0%);
			-moz-filter: grayscale(0%);
			-ms-filter: grayscale(0%);
			-o-filter: grayscale(0%);
			filter: grayscale(0%);
			filter: none;
		}
	}
}

/* Blog Hover Effect */

figure {
	&.he-center {
		a:focus {
			color: #fff;
		}
		img {
			opacity: 0.8;
		}
		.title {
			text-align: center;
			margin-bottom: 0;
			margin-top: -16px;
		}
		figcaption {
			top: auto;
			bottom: 0;
			height: 55%;
			color: #fff;
			z-index: 3;
		}
		h2 {
			margin-top: 0;
			color: #fff;
		}
		h3 {
			font-size: 1.3em;
			line-height: 2.2rem;
			color: #fff;
		}
		p {
			text-align: center;
			padding-left: 0;
		}
		ul {
			text-align: center;
			padding-left: 0;
			li {
				padding-right: 0.5rem;
			}
		}
		figcaption p {
			color: rgba(255, 255, 255, 0.9);
			font-weight: 700;
			text-align: center;
		}
		&:hover {
			img, p, ul {
				opacity: 1;
			}
		}
		.tags, p {
			opacity: 0;
			display: block;
			-webkit-transition: all .2s ease-in-out;
			-moz-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;
			-webkit-transform: translateY(5px);
			-moz-transform: translateY(5px);
			-ms-transform: translateY(5px);
			transform: translateY(5px);
		}
		&:hover {
			.tags, p {
				opacity: 1;
				-webkit-transform: translateY(0);
				-moz-transform: translateY(0);
				-ms-transform: translateY(0);
				transform: translateY(0);
			}
			figcaption, h3, h4, p {
				text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
			}
		}
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #000;
			opacity: 0;
			-webkit-transition: all .2s ease-in-out;
			-moz-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;
		}
		&:hover::after {
			opacity: 0.3;
		}
	}
	&.he-center-2 {
		a:focus {
			color: #fff;
		}
		img {
			opacity: 1;
		}
		figcaption > i {
			display: block;
			text-align: center;
			font-size: 2rem;
		}
		.title {
			text-align: center;
			margin-bottom: 0;
			margin-top: -16px;
		}
		figcaption {
			top: auto;
			bottom: 0;
			height: 55%;
			color: #fff;
		}
		h2 {
			margin-top: 0;
		}
		h3 {
			font-size: 1.3em;
			line-height: 2.2rem;
		}
		p {
			text-align: center;
			padding-left: 0;
		}
		ul {
			text-align: center;
			padding-left: 0;
			li {
				padding-right: 0.5rem;
			}
		}
		figcaption p {
			color: rgba(255, 255, 255, 0.9);
			font-weight: 700;
			text-align: center;
		}
		&:hover {
			img {
				opacity: 0.2;
			}
			p, ul {
				opacity: 1;
			}
		}
		figcaption > i, .tags {
			opacity: 0;
			display: block;
			-webkit-transition: all .2s ease-in-out;
			-moz-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;
			-webkit-transform: translateY(5px);
			-moz-transform: translateY(5px);
			-ms-transform: translateY(5px);
			transform: translateY(5px);
		}
		&:hover .tags {
			opacity: 1;
			-webkit-transform: translateY(0);
			-moz-transform: translateY(0);
			-ms-transform: translateY(0);
			transform: translateY(0);
		}
		.title {
			opacity: 0;
			-webkit-transition: all .2s ease-in-out;
			-moz-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;
			-webkit-transform: translateY(5px);
			-moz-transform: translateY(5px);
			-ms-transform: translateY(5px);
			transform: translateY(-5px);
		}
		&:hover {
			figcaption > i, .title {
				opacity: 1;
				-webkit-transform: translateY(0);
				-moz-transform: translateY(0);
				-ms-transform: translateY(0);
				transform: translateY(0);
			}
		}
	}
}

@media (max-width: 768px) {
	figure.he-center h3 {
		font-size: 1.1em;
		line-height: 1.8rem;
	}
}

/* Blog Hover Effect 2 */